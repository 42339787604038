<script setup lang="ts">
import { ref } from 'vue';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import CountryFlag from '@/Components/CountryFlag.vue';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import { usePage } from '@inertiajs/vue3';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const menu = ref();
const { props } = usePage();
const { user } = props.auth;

const getFlagCode = (code: string) => (code === 'en' ? 'us' : code);
const flagCode = ref(getFlagCode(user.language.code));

const toggleMenu = (event: Event) => menu.value?.toggle(event);

const updateCustomerLocale = async (timezone: string, language: string) => {
  const route = useRoute()('api.customers.update', [user.partner_uuid, user.customer_uuid]);

  useTranslate().updateLanguage(language);
  flagCode.value = getFlagCode(language);

  await axios.put(route, { default_timezone: timezone, default_language: language });
};
</script>

<template>
  <Button
    type="button"
    text
    icon="pi pi-ellipsis-v"
    @click="toggleMenu"
    size="small"
    rounded
    severity="secondary"
    aria-haspopup="true"
    aria-controls="domain_menu"
  >
    <template #icon>
      <CountryFlag :code="flagCode" />
    </template>
  </Button>
  <Menu
    ref="menu"
    id="overlay_menu"
    :model="[
      { command: () => updateCustomerLocale('America/New_York', 'en'), country_code: 'us', label: 'English' },
      { command: () => updateCustomerLocale('Europe/Berlin', 'de'), country_code: 'de', label: 'German' },
      { command: () => updateCustomerLocale('America/Mexico_City', 'es'), country_code: 'es', label: 'Spanish' },
      { command: () => updateCustomerLocale('Europe/Paris', 'fr'), country_code: 'fr', label: 'French' }
    ]"
    :popup="true"
  >
    <template #item="{ item, props }">
      <a class="flex items-center" v-bind="props.action">
        <CountryFlag :code="item.country_code" class="mr-2" />
        <span>{{ item.label }}</span>
      </a>
    </template>
  </Menu>
</template>
