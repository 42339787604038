<script setup lang="ts">
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { copyText = '', showText = true } = defineProps<{
  copyText?: string;
  showText?: boolean;
}>();

const toast = useToast();
const { translate } = useTranslate();

const copy = () => {
  navigator.clipboard.writeText(copyText);

  toast.add({
    severity: 'success',
    life: 3000,
    summary: translate('global.copied'),
  });
};
</script>

<template>
  <div class="flex items-center">
    <div v-if="showText" v-text="copyText" />
    <Button
      link
      icon="pi pi-copy"
      class="!p-0"
      @click="copy"
    />
  </div>
</template>
