<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import Card from 'primevue/card';
import Breadcrumb from 'primevue/breadcrumb';
import { type DomainResource, UiRestriction } from '@/types/types.gen';
import { reactive, ref } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import { Link } from '@inertiajs/vue3';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import Tabs from 'primevue/tabs';
import DomainSettingsHeader from '@/Pages/Domains/Partials/DomainSettingsHeader.vue';
import ScoreCard from '@/Pages/Domains/Score/ScoreCard.vue';
import type { DomainScore } from '@/types/cyclops.types.gen';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';

const { domain, tab } = defineProps<{
  title: string;
  domain: DomainResource;
  tab: DomainSettingsTab;
  cyclopsScore: DomainScore | null;
}>();

const { translate } = useTranslate();

const items = ref([
  { label: translate('domains.listing.title') },
  { label: translate('domains.settings.title') },
]);

const state = reactive({
  selectedTabs: tab,
});

const routeParams = {
  customer: domain.customer_uuid,
  domain: domain.uuid,
};

const isBreadcrumbsHidden = useFeatureRestriction(UiRestriction.NO_BREADCRUMBS);

</script>

<template>
  <AppLayout :title="title">
    <Breadcrumb
      v-if="!isBreadcrumbsHidden"
      :home="{
        icon: 'pi pi-home'
      }"
      :model="items"
    />
    <div class="flex gap-8">
      <Card class="w-full">
        <template #content>
          <DomainSettingsHeader :domain="domain" />
          <Tabs v-model:value="state.selectedTabs">
            <TabList>
              <Link :href="route('ui.dmarc-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.DMARC">DMARC</Tab>
              </Link>
              <Link :href="route('ui.spf-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.SPF">SPF</Tab>
              </Link>
              <Link :href="route('ui.dkim-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.DKIM">DKIM</Tab>
              </Link>
              <Link :href="route('ui.bimi-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.BIMI">BIMI</Tab>
              </Link>
              <Link :href="route('ui.tls-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.TLS">TLS-RPT</Tab>
              </Link>
            </TabList>
            <div>
              <slot />
            </div>
          </Tabs>
        </template>
      </Card>
      <ScoreCard
        v-if="cyclopsScore"
        :score="cyclopsScore"
      >
        <template #note>
          {{ $t('scoring.note') }}
        </template>
      </ScoreCard>
    </div>
    <slot name="footer" />
  </AppLayout>
</template>
