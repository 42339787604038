<script setup lang="ts">
import Button from 'primevue/button';
import IconField from 'primevue/iconfield';
import InputText from 'primevue/inputtext';
import CountryFlag from '@/Components/CountryFlag.vue';
import DataTable from 'primevue/datatable';
import { FilterMatchMode } from '@primevue/core/api';
import InputIcon from 'primevue/inputicon';
import Column from 'primevue/column';
import { computed, reactive } from 'vue';
import TableLoading from '@/Components/TableLoading.vue';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';
import type { SenderIpAddressStatisticsResource } from '@/types/types.gen';
import type { ReportSubTableProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import { SenderIpAddressDataAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';

const { defaultSortField = 'total_incidents', aggregatedTimelineStatistics, senderUuid } = defineProps<ReportSubTableProps>();

const { openModal } = useIpStatisticsModal();

const state = reactive({
  filters: { global: { value: '', matchMode: FilterMatchMode.CONTAINS } },
  loading: true,
})

const groups = computed(() =>
  new SenderIpAddressDataAggregator(aggregatedTimelineStatistics).byIpAddress().filter((group) => {
    if (!senderUuid) {
      // could be undefined/null
      return !group.sender_uuid;
    }

    return group.sender_uuid === senderUuid;
  }),
);
</script>

<template>
    <DataTable
      :value="groups"
      v-model:filters="state.filters"
      :sort-field="defaultSortField"
      :sort-order=-1
      paginator
      :rows="100"
      :rowsPerPageOptions="[10, 50, 100, 350]"
      paginatorPosition="both"
    >
      <template #empty>
        <TableLoading :total="groups.length" :fields="10" />
      </template>
      <template #header>
        <div class="flex justify-between">
          <IconField>
            <InputIcon>
              <i class="pi pi-search" />
            </InputIcon>
            <InputText
              v-model="state.filters.global.value"
              :placeholder="$t('tables.search')"
            />
          </IconField>

          <div class="flex gap-6 text-sm">
            <slot name="legend" />
          </div>
        </div>
      </template>

      <Column :header="$t('senders_reports.senders.table.header.country')" sort-field="country_name" :sortable="true">
        <template #body="{data}: {data: SenderIpAddressStatisticsResource}">
          <CountryFlag class="text-lg" :code="data.country" :name="data.country_name" />
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.ip')" field="ip_address" :sortable="true">
        <template #body="{ data }: {data: SenderIpAddressStatisticsResource}">
          <Button
            text
            :label="data.ip_address"
            @click="openModal(data)"
          />
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.host')" field="host" :sortable="true"></Column>

      <slot name="columns"/>

    </DataTable>
</template>

<style scoped>
.p-datatable {
  --p-datatable-header-cell-background: transparent;
  --p-datatable-row-background: transparent;
}
</style>
