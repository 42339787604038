import { ref } from 'vue';

import TranslatorService from '@/Utils/Translations/TranslatorService';
import { Translations } from './Translations';
import type {
  Replacements,
  TranslationKey,
  TranslationsType,
} from '@/Utils/Translations/types';

const translatorService = ref<TranslatorService<TranslationsType> | null>(null);

export function useTranslate(lang?: string) {
  if (!translatorService.value && !lang) {
    if (import.meta.env.DEV) {
      throw new Error('Language is not set, ensure service is initialized in app.ts');
    }
    console.error('Language is not set, ensure service is initialized in app.ts');
  }

  if (!translatorService.value) {
    translatorService.value = new TranslatorService(Translations, lang as keyof TranslationsType);
  }

  const updateLanguage = (newLang: string) => {
    translatorService.value = new TranslatorService(Translations, newLang as keyof TranslationsType);
  };

  const translate = <S extends TranslationKey>(
    key: S,
    replacements: Replacements<S> = undefined as Replacements<S>,
  ): string => {
    return translatorService.value?.translate(key, replacements) ?? '';
  };

  return { translate, updateLanguage };
}
