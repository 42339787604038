<script lang="ts" setup>
import type { AggregateReportResource, DmarcSettingsResource, DomainResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import Divider from 'primevue/divider';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from '@primevue/core/api';
import { useFormatters } from '@/Utils/Formatting';
import ResultsLegend from '@/Pages/Statistics/IpAddress/Partials/ResultsLegend.vue';
import { inject, reactive } from 'vue';
import {
  getDmarcResult,
  getResults,
  groupAggregateReports,
} from '@/Pages/Statistics/Sender/Helpers/AggregateReportHelpers';
import { each, orderBy } from 'lodash';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';
import ActionAppliedTag from '@/Pages/Statistics/IpAddress/Partials/ActionAppliedTag.vue';
import DetailedColumn from '@/Pages/Statistics/IpAddress/Partials/DetailedColumn.vue';
import type { ResultSummary } from '@/Pages/Statistics/IpAddress/types';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { aggregateReports } = defineProps<{
  aggregateReports: AggregateReportResource[];
}>();

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const { translate } = useTranslate();
const { formatNumber } = useFormatters();
const domain: DomainResource | undefined = inject('domain');
const dmarcSettings: DmarcSettingsResource | undefined = inject('dmarcSettings');

const groupReports = Object.values(groupAggregateReports(aggregateReports));
const resultSummary: Record<string, ResultSummary> = groupReports.reduce((acc, item) => {
  if (domain && dmarcSettings) {
    each(item.records, (record) => {
      let results = getResults(domain.domain_name, record, dmarcSettings.spf_alignment, dmarcSettings.dkim_alignment);

      const index = [
        item.published_policy_p,
        record.policy_evaluated_disposition,
        record.policy_evaluated_dkim,
        record.policy_evaluated_spf,
        results.dkim_domain,
        results.dkim_selector,
        results.dkim_result,
        results.spf_domain,
        results.spf_result,
      ].join('-');

      acc[index] ||= {
        count: 0,
        dmarc_result: getDmarcResult(record),
        published_policy_p: item.published_policy_p,
        policy_evaluated_disposition: record.policy_evaluated_disposition,
        policy_evaluated_dkim: record.policy_evaluated_dkim,
        policy_evaluated_spf: record.policy_evaluated_spf,
        spf_domain: results.spf_domain,
        spf_result: results.spf_result,
        is_dkim_aligned: results.is_dkim_aligned,
        is_spf_aligned: results.is_spf_aligned,
        dkim_selector: results.dkim_selector,
        dkim_domain: results.dkim_domain,
        dkim_result: results.dkim_result,
      };

      acc[index].count += record.count;
    });
  }

  return acc;
}, {} as Record<string, ResultSummary>);

const summary = orderBy(resultSummary, 'count', 'desc');

/** @ts-expect-error There is no way to typehint this using a dynamic key */
const actionApplied = (policyEvaluatedDisposition: string): string => translate(`ip_address_modal.table.action_applied.tags.${policyEvaluatedDisposition}.value`);
/** @ts-expect-error There is no way to typehint this using a dynamic key */
const publishedPolicy = (publishedPolicyP: string): string => translate(`domains.tags.policy.${publishedPolicyP}.value`);
</script>
<template>
  <DataTable
    v-model:filters="state.filters"
    :value="Object.values(summary)"
    data-key="uuid"
    paginator
    :expanded-rows="summary"
    @row-expand="(summary_row) => summary_row.expanded = true"
    @row-collapse="(summary_row) => summary_row.expanded = false"
    :global-filter-fields="['spf_domain', 'dkim_domain', 'dmarc_result']"
    :sort-order="-1"
    sort-field="count"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
  >
    <template #empty>
      {{ $t('tables.not_found') }}
    </template>
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex flex-grow">
          <IconField>
            <InputIcon>
              <i class="pi pi-search" />
            </InputIcon>
            <InputText
              :placeholder="$t('tables.search')"
              v-model="state.filters.global.value"
            />
          </IconField>
        </div>
        <div class="flex gap-6 text-sm">
          <ResultsLegend />
        </div>
      </div>

    </template>
    <Column expander class="w-14" />
    <Column
      class="w-3/12"
      field="spf_domain"
      :header="$t('ip_address_modal.table.spf.results.header')"
    >
      <template #body="{data}">
        <i v-if="data.spf_result === 'pass'"
           class="pi pi-check-circle text-success-default pr-2"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.pass_tooltips.yes')"></i>
        <i v-else-if="data.spf_result === 'fail'"
           class="pi pi-times-circle text-danger-default pr-2"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.pass_tooltips.no')"></i>
        <i v-else-if="data.spf_result === 'none'"
           class="pi pi-times-circle text-info-default pr-2"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.pass_tooltips.none')"></i>
        <i v-else
           class="pi pi-exclamation-circle pr-2"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.pass_tooltips.error')"></i>
        <i v-if="data.is_spf_aligned === true"
           class="pi pi-arrow-right-arrow-left text-success-default pr-1"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.alignment_tooltips.yes')"></i>
        <i v-else-if="data.is_spf_aligned === false"
           class="pi pi-arrow-right-arrow-left text-danger-default pr-1"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.alignment_tooltips.no')"></i>
        <i v-else
           class="pi pi-arrow-right-arrow-left text-warn-default pr-1"
           v-tooltip.top="$t('ip_address_modal.table.spf.results.alignment_tooltips.no_info')"></i>
        <span v-if="data.is_spf_aligned === true"
              class="text-success-default">{{ data.spf_domain || $t('ip_address_modal.table.spf.results.no_details')
          }}</span>
        <span v-else-if="data.is_spf_aligned === false"
              class="text-danger-default">{{ data.spf_domain || $t('ip_address_modal.table.spf.results.no_details')
          }}</span>
        <span v-else
              class="text-warn-default">{{ data.spf_domain || $t('ip_address_modal.table.spf.results.no_details')
          }}</span>
      </template>
    </Column>
    <Column
      class="w-3/12"
      field="dkim_domain"
      :header="$t('ip_address_modal.table.dkim.results.header')"
    >
      <template #body="{data}">
        <i v-if="data.dkim_result === 'pass'"
           class="pi pi-check-circle text-success-default pr-2"
           v-tooltip.top="$t('ip_address_modal.table.dkim.results.pass_tooltips.yes')"></i>
        <i v-else-if="data.dkim_result === 'fail'"
           class="pi pi-times-circle text-danger-default pr-2"
           v-tooltip.top="$t('ip_address_modal.table.dkim.results.pass_tooltips.no')"></i>
        <i v-else
           class="pi pi-times-circle text-info-default pr-2"
           v-tooltip.top="$t('ip_address_modal.table.dkim.results.pass_tooltips.none')"></i>
        <i v-if="data.is_dkim_aligned === true"
           class="pi pi-arrow-right-arrow-left text-success-default pr-1"
           v-tooltip.top="$t('ip_address_modal.table.dkim.results.alignment_tooltips.yes')"></i>
        <i v-else-if="data.is_dkim_aligned === false"
           class="pi pi-arrow-right-arrow-left text-danger-default pr-1"
           v-tooltip.top="$t('ip_address_modal.table.dkim.results.alignment_tooltips.no')"></i>
        <i v-else
           class="pi pi-arrow-right-arrow-left text-warn-default pr-1"
           v-tooltip.top="$t('ip_address_modal.table.dkim.results.alignment_tooltips.no_info')"></i>
        <span v-if="data.is_dkim_aligned === true"
              class="text-success-default">{{ data.dkim_domain || $t('ip_address_modal.table.dkim.results.no_details')
          }}</span>
        <span v-else-if="data.is_dkim_aligned === false"
              class="text-danger-default">{{ data.dkim_domain || $t('ip_address_modal.table.dkim.results.no_details')
          }}</span>
        <span v-else
              class="text-warn-default">{{ data.dkim_domain || $t('ip_address_modal.table.dkim.results.no_details')
          }}</span>
      </template>
    </Column>

    <Column
      class="w-48"
      field="dmarc_result"
      :header="$t('ip_address_modal.table.dmarc.results.header')"
    >
      <template #body="{data}">
        <i v-if="data.dmarc_result === 'pass'"
           class="pi pi-check-circle text-success-default"
           v-tooltip.top="$t('ip_address_modal.table.dmarc.results.pass_tooltips.yes')"></i>
        <i v-else
           class="pi pi-times-circle text-danger-default"
           v-tooltip.top="$t('ip_address_modal.table.dmarc.results.pass_tooltips.no')"></i>
      </template>
    </Column>

    <Column
      class="w-52"
      field="published_policy_p"
    >
      <template #header>
        <span class="font-bold">{{ $t('ip_address_modal.table.published_policy.header') }}</span>
        <i class="pi pi-info-circle pr-2"
           v-tooltip.top="$t('ip_address_modal.table.published_policy.info.tooltip')"></i>
      </template>
      <template #body="{data}">
        <DmarcPolicyTag :policy="data.published_policy_p" :show-tooltip="true" />
      </template>
    </Column>

    <Column
      class="w-48"
      field="policy_evaluated_disposition"
    >
      <template #header>
        <span class="font-bold">{{ $t('ip_address_modal.table.action_applied.header') }}</span>
        <i class="pi pi-info-circle pr-2"
           v-tooltip.top="$t('ip_address_modal.table.action_applied.info.tooltip')"></i>
      </template>
      <template #body="{data}">
        <ActionAppliedTag :policy="data.policy_evaluated_disposition" />
      </template>
    </Column>

    <Column
      :sortable="true"
      field="count"
      :header="$t('ip_address_modal.table.volume.header')"
    >
      <template #body="{ data }">
        {{ formatNumber(data.count) }}
      </template>
    </Column>

    <template #expansion="{data}">
      <div class="flex gap-16 mx-16 my-6 justify-between bg-section-default">
        <div class="flex flex-col gap-3 w-1/4">
          <div class="flex flex-col">
            <div class="flex shrink-0 font-semibold" v-text="$t('ip_address_modal.table.spf.details.heading')" />
            <Divider class="!my-2" />
          </div>

          <DetailedColumn :title="$t('ip_address_modal.table.spf.details.header.return_path_domain')">
            {{ data.spf_domain }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.spf.details.header.alignment')">
            {{ data.is_spf_aligned === true ? $t('global.buttons.yes') : $t('global.buttons.no') }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.spf.details.header.result')">
            {{ data.spf_result === 'pass' ? $t('global.buttons.yes') : $t('global.buttons.no') }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.spf.details.header.dmarc_via_spf')">
            {{ data.policy_evaluated_spf === 'pass' ? $t('global.pass') : $t('global.fail') }}
          </DetailedColumn>
        </div>

        <div class="flex flex-col gap-3 w-1/4">
          <div class="flex flex-col">
            <div class="font-semibold" v-text="$t('ip_address_modal.table.dkim.details.heading')" />
            <Divider class="!my-2" />
          </div>

          <DetailedColumn :title="$t('ip_address_modal.table.dkim.details.header.signing_domain')">
            {{ data.spf_domain }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.dkim.details.header.selector')">
            {{ data.dkim_selector }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.dkim.details.header.alignment')">
            {{ data.is_dkim_aligned === true ? $t('global.buttons.yes') : $t('global.buttons.no') }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.dkim.details.header.results')">
            {{ data.spf_result === 'pass' ? $t('global.buttons.yes') : $t('global.buttons.no') }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.dkim.details.header.dmarc_via_dkim')">
            {{ data.policy_evaluated_dkim === 'pass' ? $t('global.pass') : $t('global.fail') }}
          </DetailedColumn>
        </div>

        <div class="flex flex-col gap-3 w-2/4">
          <div class="flex flex-col">
            <div class="font-semibold" v-text="$t('ip_address_modal.table.dmarc.details.heading')" />
            <Divider class="!my-2" />
          </div>

          <DetailedColumn :title="$t('ip_address_modal.table.dmarc.details.header.from_domain')">
            {{ domain?.domain_name }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.dmarc.results.header')">
            {{ data.dmarc_result === 'pass' ? $t('global.buttons.yes') : $t('global.buttons.no') }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.published_policy.header')">
            {{ publishedPolicy(data.published_policy_p) + ' - ' + $t('ip_address_modal.table.published_policy.info.tooltip')
            }}
          </DetailedColumn>
          <DetailedColumn :title="$t('ip_address_modal.table.action_applied.header')">
            <template v-if="data.policy_evaluated_disposition === 'none'">
              {{ $t('ip_address_modal.table.action_applied.tags.delivered.value')
              }}
            </template>
            <template v-else>
              {{ actionApplied(data.policy_evaluated_disposition) }}
            </template>
            -
            {{ $t('ip_address_modal.table.dmarc.details.description.action_applied', { policy: data.policy_evaluated_disposition }) }}
          </DetailedColumn>
        </div>
      </div>
    </template>

  </DataTable>
</template>
