<script setup lang="ts">
import Card from 'primevue/card';
import FormGroup from '@/Components/FormGroup.vue';
import EmailsListBox from '@/Components/EmailsListBox.vue';
import { inject, reactive } from 'vue';
import type { MtaTlsForm } from '@/Pages/Domains/TlsRptSettings/types';
import type {
  DnsDelegationVerificationResource,
  DomainResource,
  TlsRptSetupInstructionsResource,
} from '@/types/types.gen';
import Divider from 'primevue/divider';
import TlsRecordSetupInstructions from '@/Pages/Domains/TlsRptSettings/Partials/Setup/TlsRecordSetupInstructions.vue';
import Button from 'primevue/button';
import VerificationStatus from '@/Components/VerificationStatus.vue';

const form = <MtaTlsForm>inject('formState');

defineProps<{
  domain: DomainResource;
  tlsRptSetupInstruction: TlsRptSetupInstructionsResource;
  verification: DnsDelegationVerificationResource;
}>();

const state = reactive({
  showInstructions: window.location.hash === '#tls-record',
});

const toggleInstructions = () => {
  state.showInstructions = !state.showInstructions;
  window.location.hash = state.showInstructions ? '#tls-record' : '';
}
</script>

<template>
    <div
      class="text-xl font-bold mt-8"
      v-text="$t('tls_settings.heading')"
    />
    <Card class="border !border-surface">
      <template #content>
        <TlsRecordSetupInstructions
          v-if="state.showInstructions"
          @close="toggleInstructions"
          :tls-rpt-setup-instruction="tlsRptSetupInstruction"
          :domain="domain"
          :verification="verification"
        />
        <div v-else>
          <div  class="mb-5 flex items-center justify-between">
            <VerificationStatus :verification="verification"/>
            <Button
              @click="toggleInstructions"
              text
              :label="$t('configurations.actions.view')"
              icon="pi pi-angle-right"
              iconPos="right"
              severity="secondary"
            />
          </div>
          <Divider />
          <FormGroup :label="$t('tls_settings.fields.email.input_label')">
            <template v-slot:element="slotProps">
              <EmailsListBox
                :emails="form.tlsRptSettings.rua_emails"
                v-bind="slotProps"
                class="w-1/3"
              />
            </template>
          </FormGroup>
        </div>
      </template>
    </Card>
</template>
