export const Translations = {
    "de": {
        "alerts": {
            "actions": {
                "create_activate": "Erstellen und aktivieren",
                "edit_alert": {
                    "tooltip": "Warnung bearbeiten"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Konfiguration",
                    "mail_senders": "E-Mail-Absender",
                    "none": "Keiner",
                    "reports": "Berichte",
                    "status": "Status"
                },
                "description": "Wählen Sie die Aktivität aus, die Sie überwachen möchten.",
                "heading": "Aktivität",
                "options": {
                    "customer-created": {
                        "description": "Benachrichtigen Sie mich, wenn ein Konto erstellt wird.",
                        "heading": "Vom Kunden erstellt"
                    },
                    "customer-deleted": {
                        "description": "Benachrichtigen Sie mich, wenn ein Konto erstellt wird.",
                        "heading": "Kunde gelöscht"
                    },
                    "customer-exposure-report": {
                        "description": "Planen Sie, wann Sie den Expositionsbericht erhalten möchten.",
                        "heading": "Expositionsbericht"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Planen Sie das DMARC-Konformitätszertifikat, wenn die DMARC-Richtlinie auf „Ablehnen“ eingestellt ist.",
                        "heading": "DMARC-Konformitätszertifikat bei Ablehnung"
                    },
                    "dns-delegation-unverified": {
                        "description": "Benachrichtigen Sie mich, wenn die erneute Überprüfung der DNS-Delegierung fehlschlägt.",
                        "heading": "Die erneute Überprüfung des Setups ist fehlgeschlagen"
                    },
                    "dns-delegation-verified": {
                        "description": "Benachrichtigen Sie, wenn sich der Verifizierungsstatus geändert hat",
                        "heading": "Verifizierungsstatus geändert"
                    },
                    "domain-compliance-drop": {
                        "description": "Benachrichtigen Sie mich, wenn die Compliance unter einen Schwellenwert fällt.",
                        "heading": "Compliance wurde verworfen"
                    },
                    "domain-created": {
                        "description": "Benachrichtigen Sie mich, wenn eine neue Domain hinzugefügt wird.",
                        "heading": "Domäne erstellt"
                    },
                    "domain-deleted": {
                        "description": "Benachrichtigen Sie mich, wenn eine Domain gelöscht wird.",
                        "heading": "Domain gelöscht"
                    },
                    "domain-score-change": {
                        "description": "Benachrichtigen Sie mich, wenn sich der DMARC Compliance Score geändert hat.",
                        "heading": "DMARC-Compliance-Score geändert"
                    },
                    "domain-settings-change": {
                        "description": "Benachrichtigen Sie mich, wenn Einstellungen geändert werden.",
                        "heading": "Änderungen an den Einstellungen"
                    },
                    "sender-threat-level-change": {
                        "description": "Benachrichtigen Sie mich, wenn die Bedrohungsstufe für neue oder bestehende Absender steigt.",
                        "heading": "Bedrohungsstufe erhöht"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Benachrichtigen Sie mich, wenn ein neuer, allgemein genehmigter Absender erkannt wird.",
                        "heading": "Normalerweise wurde ein autorisierter Absender gefunden"
                    }
                }
            },
            "create": {
                "title": "Benachrichtigung erstellen"
            },
            "edit": {
                "title": "Warnung bearbeiten"
            },
            "email": {
                "description": "Geben Sie den E-Mail-Empfänger an, den Sie benachrichtigen möchten, und passen Sie die E-Mail an.",
                "heading": "E-Mail"
            },
            "email_settings": {
                "heading": "E-Mail anpassen"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Kunde",
                        "domain": "Domain"
                    }
                },
                "frequency": {
                    "input_label": "Frequenz"
                },
                "recipients": {
                    "input_label": "Empfänger"
                },
                "scope": {
                    "input_label": "Überwachungsebene",
                    "listing-title": ":scope Aktivität",
                    "options": {
                        "customer": "Kunde",
                        "partner": "Partner",
                        "system": "System"
                    }
                },
                "select_below": {
                    "description": "Setup-Überprüfung",
                    "input_label": "Wählen Sie unten aus"
                },
                "start_date": {
                    "input_label": "Startdatum"
                },
                "subject_tags": {
                    "example": "z.B. [Alert-Schweregrad][Konto][Paket][Domäne]",
                    "input_label": "Aktivieren Sie Präfixe für die Kategorisierung der Betreffzeile"
                }
            },
            "frequency": {
                "description": "Wählen Sie aus, wie oft Sie diese Benachrichtigung erhalten möchten.",
                "heading": "Frequenz"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alarm",
                        "frequency": "Frequenz",
                        "recipients": "Empfänger",
                        "status": "Status"
                    },
                    "record": {
                        "alert_activity": ":scope Aktivität"
                    }
                },
                "title": "Warnungen"
            },
            "monitor": {
                "description": "Wählen Sie die Aktivität aus, die Sie überwachen möchten.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Täglich",
                        "fortnightly": "Alle zwei Wochen",
                        "hourly": "Stündlich",
                        "immediate": "Sofort",
                        "monthly": "Monatlich",
                        "quarterly": "Vierteljährlich",
                        "six_monthly": "Sechs monatlich",
                        "two_monthly": "Zwei monatlich",
                        "weekly": "Wöchentlich",
                        "yearly": "Jährlich"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "Warnung ist inaktiv",
                        "value": "aus"
                    },
                    "on": {
                        "tooltip": "Warnung ist aktiv",
                        "value": "An"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Deaktivieren Sie das BIMI-Datensatz-Hosting",
                "heading": "BIMI Record Hosting ist derzeit aktiviert",
                "message": "Es wird empfohlen, BIMI Hosting zu aktivieren, um Ihr Logo und Zertifikat einfach verwalten zu können.",
                "modal": {
                    "header": "Deaktivieren Sie BIMI Record Hosting für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie BIMI Record Hosting",
                "heading": "BIMI-Hosting ist deaktiviert",
                "message": "Es wird empfohlen, BIMI Hosting zu aktivieren, um Ihr Logo und Zertifikat zu verwalten.",
                "modal": {
                    "header": "Aktivieren Sie BIMI Record Hosting :domain",
                    "message": "Durch die Aktivierung von BIMI Hosting können Sie Ihr Logo und Zertifikat einfach verwalten."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Zertifikat CN",
                    "expiry": "Ablauf des Zertifikats",
                    "input_label": "BIMI Verified Mark-Zertifikat (Dateityp: pem)",
                    "url": "Autoritäts-\/Zertifikats-URL"
                },
                "image": {
                    "input_label": "BIMI-Logo (Dateityp: SVG)",
                    "preview": "Logo-Vorschau",
                    "size": "Logogröße",
                    "url": "Logo-URL"
                }
            },
            "info": {
                "heading": "Was ist BIMI?",
                "message": "BIMI (Brand Indicators for Message Identification) ist ein Standard, der es einfacher macht, Ihr Logo neben Ihren Nachrichten im Posteingang anzuzeigen, betrügerische E-Mails zu verhindern und die Zustellbarkeit zu verbessern. BIMI arbeitet mit DMARC, DKIM und SPF zusammen, um die Authentizität einer E-Mail zu überprüfen und die Markensichtbarkeit zu verbessern."
            },
            "settings": {
                "heading": "Einstellungen"
            },
            "title": "Einstellungen bearbeiten – BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Zurück zu den Einstellungen",
                "share": "Teilen Sie die Einrichtungsanweisungen",
                "verify": "Verifizieren",
                "view": "Setup-Anweisungen anzeigen"
            },
            "bimi": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der BIMI-Einträge Ihrer Domain zu ermöglichen."
            },
            "description": "Um die Überwachung und Verwaltung über den DMARC Manager zu ermöglichen, nehmen Sie bitte die folgenden Änderungen in den DNS-Einstellungen Ihrer Domain vor.",
            "dkim": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der öffentlichen DKIM-Schlüssel Ihrer Domain zu ermöglichen."
            },
            "dmarc": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der DMARC-Richtlinie Ihrer Domain zu ermöglichen und Berichte über E-Mail-Authentifizierungsaktivitäten zu erfassen.",
                "warning": "Beachten Sie die erforderlichen Unterstriche (_) im Host und die Punkte im Datensatzwert."
            },
            "existing_dns_record_found": "Vorhandener :record gefunden",
            "heading": ":title Setup-Anweisungen",
            "instructions": {
                "add": " :rdType hinzufügen",
                "heading": ":rdType Setup-Anweisungen",
                "new": "Schritt 2: Neuen :rdType hinzufügen",
                "remove": "Schritt 1: Vorhandenen :rdType entfernen"
            },
            "mta_policy": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration der MTA-Richtlinie Ihrer Domain zu ermöglichen.",
                "heading": "MTA-STS-Richtlinie"
            },
            "mta_record": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration des MTA-Eintrags Ihrer Domain zu ermöglichen.",
                "heading": "MTA-STS-Datensatz"
            },
            "record": {
                "host": "Gastgeber",
                "type": "Datensatztyp",
                "value": "Wert"
            },
            "spf": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration des SPF-Eintrags Ihrer Domain zu ermöglichen."
            },
            "status": {
                "last_date": "Zuletzt überprüft am",
                "pending": "Ausstehende Überprüfung",
                "verified": "Verifiziert"
            },
            "tls_rpt": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration des TLS-RPT-Eintrags Ihrer Domain zu ermöglichen und Berichte zu erfassen.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Kunden verwalten"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "E-Mail-Volumen",
                    "legends": {
                        "approved": "Genehmigt",
                        "unapproved": "Nicht genehmigt und Sonstiges"
                    }
                },
                "domain_totals": {
                    "action": "Domains anzeigen",
                    "enforced": "Durchgesetzt",
                    "heading": "Domänen",
                    "reporting": "Berichterstattung",
                    "total": "Gesamt"
                },
                "sender_totals": {
                    "action": "Absender anzeigen",
                    "heading": "Absender",
                    "legends": {
                        "approved": "Genehmigt",
                        "unapproved": "Nicht genehmigt"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Alle Domains anzeigen"
                },
                "legends": {
                    "failing": "DMARC scheitert",
                    "forwards": "Vorwärts",
                    "passing": "DMARC bestanden"
                },
                "table": {
                    "action": {
                        "view_report": "Bericht anzeigen"
                    },
                    "header": {
                        "approved": "Genehmigt",
                        "domain": "Domänen",
                        "senders": "Absender",
                        "unapproved": "Nicht genehmigt",
                        "volume": "Volumen"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Domainbericht anzeigen"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Einhaltung",
                        "domain": "Domain",
                        "sender": "Zugelassene Absender nach Volumen",
                        "volume": "Volumen"
                    }
                }
            },
            "title": "Armaturenbrett"
        },
        "delegation-verifications": {
            "verify": {
                "error": "Überprüfung fehlgeschlagen",
                "success": "Verifizierung erfolgreich abgeschlossen"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Neuen öffentlichen Schlüssel hinzufügen",
                "edit_public_key": {
                    "tooltip": "Öffentlichen Schlüssel bearbeiten"
                }
            },
            "create_drawer": {
                "header": "Neuen öffentlichen Schlüssel hinzufügen"
            },
            "disable": {
                "action": "DKIM-Verwaltung deaktiviert",
                "heading": "Das Hosting öffentlicher DKIM-Schlüssel ist derzeit aktiviert",
                "message": "Es wird empfohlen, die DKIM-Verwaltung zu aktivieren, um Sie bei der Verwaltung Ihrer öffentlichen Schlüssel zu unterstützen.",
                "modal": {
                    "header": "Deaktivieren Sie die DKIM-Verwaltung für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "edit_drawer": {
                "header": "Öffentlichen Schlüssel bearbeiten"
            },
            "enable": {
                "action": "Aktivieren Sie die DKIM-Verwaltung",
                "heading": "Das Hosting öffentlicher DKIM-Schlüssel ist deaktiviert",
                "message": "Es wird empfohlen, die DKIM-Verwaltung zu aktivieren, um Sie bei der Verwaltung Ihrer öffentlichen Schlüssel zu unterstützen.",
                "modal": {
                    "header": "Aktivieren Sie die DKIM-Verwaltung für :domain",
                    "message": "Durch die Aktivierung der DKIM-Verwaltung können Sie Ihre öffentlichen Schlüssel ganz einfach verwalten."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Beschreibung"
                },
                "record_type": {
                    "input_label": "Datensatztyp"
                },
                "record_value": {
                    "example": "z.B. dkim.example.com",
                    "input_label": "Rekordwert"
                },
                "selector": {
                    "input_label": "Wähler"
                }
            },
            "import_modal": {
                "action": "Entfernen und importieren",
                "complete": {
                    "message": "Import des öffentlichen DKIM-Schlüssels abgeschlossen"
                },
                "disabled_tooltip": "Öffentliche DKIM-Schlüssel können nur für Domänen importiert werden, die über Berichte verfügen.",
                "header": "Importieren Sie die öffentlichen DKIM-Schlüssel für :domain erneut",
                "message": "Alle vorhandenen öffentlichen Schlüssel werden entfernt und durch importierte Anweisungen ersetzt."
            },
            "info": {
                "heading": "Was ist DKIM?",
                "message": "Domain Keys Identified Mail ist eine E-Mail-Authentifizierungsmethode, die es einem Absender ermöglicht, eine E-Mail mit einem privaten Schlüssel, der mit seinem Domainnamen verknüpft ist und durch die E-Mail des Empfängers überprüft werden kann, digital zu signieren Server mithilfe eines öffentlichen Schlüssels, der in den DNS-Einträgen des Absenders veröffentlicht ist. Dies hilft, die Authentizität und Integrität der E-Mail zu überprüfen und reduziert so das Risiko von Spam- und Phishing-Angriffen."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Richtlinie für ausgehende Signaturen",
                        "options": {
                            "all": "Alle E-Mails",
                            "some": "Einige E-Mails"
                        }
                    },
                    "testing": {
                        "input_label": "Testmodus"
                    }
                },
                "heading": "Richtlinieneinstellungen"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Datensatztyp",
                        "selector": "Wähler",
                        "value": "Wert"
                    }
                }
            },
            "title": "Einstellungen bearbeiten – DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM-Ausrichtungsmodus"
                    },
                    "options": {
                        "r": "Entspannt",
                        "s": "Strikt"
                    },
                    "spf_mode": {
                        "input_label": "SPF-Ausrichtungsmodus"
                    }
                },
                "heading": "Ausrichtungseinstellungen"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Wenden Sie eine andere Subdomain-Richtlinie an"
                    },
                    "percentage": {
                        "input_label": "Prozentsatz"
                    },
                    "policy": {
                        "input_label": "DMARC-Richtlinie"
                    },
                    "subdomain_policy": {
                        "input_label": "Subdomain-Richtlinie"
                    }
                },
                "heading": "Richtlinieneinstellungen"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Berichte werden an gesendet"
                    },
                    "interval": {
                        "input_label": "Berichtsintervall",
                        "options": {
                            "1_day": "Einmal am Tag",
                            "1_hour": "Stündlich",
                            "2_hours": "Alle 2 Stunden",
                            "4_hours": "Alle 4 Stunden",
                            "6_hours": "Alle 6 Stunden"
                        }
                    }
                },
                "heading": "Aggregierte Berichte"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Aktivieren",
                    "header": "Aktivieren Sie die Fehlerberichterstattung",
                    "message": "Durch die Aktivierung der Fehlerberichterstattung können einige persönliche Informationen, wie E-Mail-Adressen oder Nachrichteninhalte, zu Diagnosezwecken offengelegt werden",
                    "reject": "Deaktivieren"
                },
                "fields": {
                    "options": {
                        "input_label": "Berichtsoptionen",
                        "options": {
                            "d": "DKIM schlägt unabhängig von der Ausrichtung fehl",
                            "s": "SPF schlägt unabhängig von der Ausrichtung fehl",
                            "0": "SPF oder DKIM schlagen fehl und passen nicht zusammen",
                            "1": "SPF und DKIM schlagen fehl und passen nicht zusammen"
                        }
                    },
                    "status": {
                        "input_label": "Berichtsverarbeitung",
                        "options": {
                            "disabled": "Deaktiviert",
                            "enabled": "Ermöglicht",
                            "specified": "Spezifische Empfänger"
                        }
                    }
                },
                "heading": "Fehlerberichte"
            },
            "title": "Einstellungen bearbeiten – DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC-Konformitätszertifikat",
                "domain_report": "Domain-Bericht",
                "edit_settings": {
                    "tooltip": "Einstellungen bearbeiten"
                },
                "remove_primary": "Aus Favorit entfernen",
                "set_as_active": "Als Aktiv festlegen",
                "set_as_parked": "Als „Geparkt“ festlegen",
                "set_primary": "Als Favorit markieren",
                "view_logs": "Protokolle anzeigen"
            },
            "create_drawer": {
                "header": "Neue Domäne hinzufügen"
            },
            "fields": {
                "name": {
                    "example": "z.B. „example.com“ oder „subdomain.example.com“",
                    "input_label": "Domainname"
                },
                "type": {
                    "input_label": "Domänentyp",
                    "options": {
                        "active": "Aktiv",
                        "parked": "Geparkt"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domain",
                        "policy": "Politik",
                        "score": "Punktzahl",
                        "status": "Status",
                        "type": "Typ"
                    }
                },
                "title": "Domänen"
            },
            "logs_drawer": {
                "header": "Domain"
            },
            "report_modal": {
                "action": "Link generieren",
                "fields": {
                    "date_range": {
                        "input_label": "Datumsbereich"
                    },
                    "expire_link": {
                        "input_label": "Link ablaufen lassen in",
                        "options": {
                            "1_day": "Einmal",
                            "1_month": "Ein Monat",
                            "1_week": "Eine Woche",
                            "6_hours": "6 Stunden",
                            "12_hours": "12 Stunden",
                            "never": "Niemals"
                        }
                    }
                },
                "header": "Domain-Bericht"
            },
            "settings": {
                "title": "Einstellungen bearbeiten"
            },
            "setup_modal": {
                "action": "Link generieren",
                "fields": {
                    "copy_link": {
                        "input_label": "Link zu den Einrichtungsanweisungen"
                    },
                    "expire_link": {
                        "input_label": "Link ablaufen lassen in",
                        "options": {
                            "1_day": "Einmal",
                            "1_month": "Ein Monat",
                            "1_week": "Eine Woche",
                            "6_hours": "6 Stunden",
                            "12_hours": "12 Stunden",
                            "never": "Niemals"
                        }
                    }
                },
                "header": "Teilen Sie die Einrichtungsanweisungen"
            },
            "tags": {
                "policy": {
                    "tooltip": "Angewendete Richtlinie"
                },
                "primary": {
                    "tooltip": "Favorit",
                    "value": "Favorit"
                },
                "score": {
                    "high": {
                        "tooltip": "Hohes Risiko"
                    },
                    "low": {
                        "tooltip": "Geringes Risiko"
                    },
                    "moderate": {
                        "tooltip": "Mäßiges Risiko"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verifiziert, keine Meldung",
                        "tooltip_partial": "Teilweise verifiziert, keine Meldung",
                        "value": "Keine Meldung"
                    },
                    "not_verified": {
                        "tooltip": "Nicht verifiziert, keine Meldung",
                        "value": "Nicht verifiziert"
                    },
                    "reporting": {
                        "tooltip": "Verifiziert, Berichterstattung",
                        "tooltip_partial": "Teilweise verifiziert, Meldung",
                        "value": "Berichterstattung"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Aktive Domäne",
                        "value": "Aktiv"
                    },
                    "parked": {
                        "tooltip": "Geparkte Domain",
                        "value": "Geparkt"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "z.B. empfänger@example.com",
                    "input_label": "E-Mail bearbeiten",
                    "placeholder": "Geben Sie die E-Mail-Adresse ein",
                    "subscribed_label": "Gezeichnet",
                    "unsubscribed_label": "Abgemeldet",
                    "validation_message": "Geben Sie eine gültige E-Mail-Adresse ein"
                }
            }
        },
        "errors": {
            "menu": "Fehler",
            "401": {
                "description": "Leider sind Sie nicht berechtigt, auf diese Seite zuzugreifen.",
                "title": "401: Nicht autorisiert"
            },
            "403": {
                "description": "Leider ist Ihnen der Zugriff auf diese Seite untersagt.",
                "title": "403: Verboten"
            },
            "404": {
                "description": "Leider konnte die von Ihnen gesuchte Seite nicht gefunden werden.",
                "title": "404: Seite nicht gefunden"
            },
            "500": {
                "description": "Hoppla, auf unseren Servern ist ein Fehler aufgetreten.",
                "title": "500: Serverfehler"
            },
            "502": {
                "description": "Leider sind unsere Server ausgefallen.",
                "title": "502: Schlechtes Gateway"
            },
            "503": {
                "description": "Es tut uns leid, wir führen Wartungsarbeiten durch. Bitte schauen Sie bald wieder vorbei.",
                "title": "503: Dienst nicht verfügbar"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "E-Mail-Volumen",
                "legends": {
                    "failing": "DMARC scheitert",
                    "forward": "Vorwärts",
                    "passing": "DMARC bestanden"
                }
            },
            "heading": "Domain-Bericht",
            "recommendations": {
                "heading": "Empfehlungen"
            },
            "risk_analysis": {
                "heading": "Risikoanalyse"
            },
            "scoring": {
                "note": "Hinweis: Der Domain-Score wird zum Zeitpunkt der Erstellung des Berichts berechnet und ist zum Zeitpunkt der Anzeige möglicherweise nicht aktuell."
            },
            "sender_readiness": {
                "heading": "Absender DMARC-Bereitschaft",
                "legends": {
                    "configured": {
                        "description": "Die DMARC-Bereitschaft ist ein Maß für die Konfiguration, die zur Implementierung vollständiger Compliance erforderlich ist.",
                        "heading": "Konfiguriert"
                    },
                    "partially": {
                        "description": "Bei teilweise konfigurierten Quellen ist mindestens ein Autorisierungsmechanismus aktiv. Möglicherweise ist jedoch eine zusätzliche Einrichtung erforderlich.",
                        "heading": "Teilweise konfiguriert"
                    },
                    "unconfigured": {
                        "description": "Unzulässige oder nicht konfigurierte Quellen sind Quellen, die E-Mails von Ihrer Domain senden und möglicherweise Ihre Marke imitieren.",
                        "heading": "Unzulässig oder nicht konfiguriert"
                    }
                }
            },
            "title": "Domainbericht für :domain",
            "top_countries": {
                "heading": "Top-Absenderländer – E-Mail-Volumen"
            },
            "top_senders": {
                "heading": "Top-Absender – Compliance"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Bericht herunterladen",
                "header": "Fehlerbericht",
                "menu": {
                    "key_properties": "Schlüsseleigenschaften",
                    "more_info": "Weitere Informationen"
                }
            },
            "properties": {
                "authentication_failure": "Authentifizierungsfehler",
                "date": "Datum",
                "delivery_results": "Lieferergebnisse",
                "dkim_domain": "DKIM-Domäne",
                "dkim_header": "DKIM-kanonisierter Header",
                "dkim_identity": "DKIM-Identität",
                "dkim_selector": "DKIM-Auswahl",
                "dmarc_version": "DMARC-Version",
                "feedback_type": "Feedback-Typ",
                "incidents": "Anzahl der Vorfälle",
                "original_recipients": "Ursprüngliche Empfänger",
                "reporting_domains": "Gemeldete Domänen",
                "reporting_mta": "MTA melden",
                "reporting_user_agent": "Meldender Benutzeragent",
                "return_path": "Rückweg",
                "source_ip": "Quell-IP-Adresse",
                "spf_dns": "SPF-DNS"
            },
            "table": {
                "header": {
                    "authentication": "Authentifizierung",
                    "date": "Datum",
                    "ip_address": "IP-Adresse",
                    "result": "Ergebnis",
                    "return_path": "Rückweg"
                }
            },
            "title": "Fehlerberichte für :domain"
        },
        "global": {
            "buttons": {
                "add": "Hinzufügen",
                "add_new": "Neu hinzufügen",
                "cancel": "Stornieren",
                "delete": "Löschen",
                "import": "Import",
                "next": "Nächste",
                "no": "NEIN",
                "remove": "Entfernen",
                "save": "Speichern",
                "save_exit": "Speichern und zurück",
                "select": "Wählen",
                "yes": "Ja"
            },
            "confirm_delete": "Möchten Sie fortfahren?",
            "copied": "Kopiert",
            "created": "Erstellt",
            "deleted": "Gelöscht",
            "fail": "Scheitern",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Deaktiviert",
                        "enabled": "Ermöglicht"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Aus",
                        "on": "An"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "NEIN",
                        "yes": "Ja"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Fehler: Bitte füllen Sie alle erforderlichen Felder korrekt aus.",
                    "title": "Es sind Fehler in Ihrem Formular aufgetreten."
                },
                "success": {
                    "title": "Ihre Änderungen wurden gespeichert."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volumen"
                }
            },
            "logout": "Abmelden",
            "not_found": "Nicht gefunden",
            "pass": "Passieren",
            "remove": "Entfernen",
            "show_less": "Weniger anzeigen",
            "show_more": "Mehr anzeigen",
            "system": "System",
            "theme": {
                "dark": "Dunkel",
                "light": "Licht"
            },
            "to": "Zu"
        },
        "ip-address-modal": {
            "menu": {
                "overrides": "Überschreibt",
                "overview": "Überblick",
                "reporters": "Reporter",
                "results": "Ergebnisse",
                "spf_result": "SPF-Ergebnis"
            },
            "overrides": {
                "breakdown": {
                    "policy_applied_none": "Angewendete Richtlinie „Keine“",
                    "policy_applied_quarantine": "Angewendete Richtlinie „Quarantäne“",
                    "policy_applied_reject": "Angewendete Richtlinie „Ablehnen“"
                },
                "overridden_policy_breakdown": "Aufschlüsselung der überschriebenen Richtlinie",
                "table": {
                    "override_comment": "Kommentar",
                    "override_type": "Überschreibungstyp",
                    "reporter": "Reporter"
                },
                "volume_overridden_policy": "Volume mit überschriebenen Richtlinien"
            },
            "overview": {
                "compliance": "Einhaltung",
                "deliverability": "Lieferfähigkeit",
                "detail": {
                    "country": "Land",
                    "host": "GASTGEBER",
                    "isp": "ISP",
                    "organisation": "Organisation",
                    "sender": "Absender"
                },
                "volume": "Volumen"
            },
            "reporters": {
                "table": {
                    "compliance": "Einhaltung",
                    "envelope_from": "Umschlag von",
                    "envelope_to": "Umschlag an",
                    "header_from": "Kopfzeile von",
                    "percent_of_total": "% der Gesamtmenge",
                    "reporter": "Reporter",
                    "volume": "Volumen"
                }
            },
            "results": {
                "legend": {
                    "align": "Ausrichtung",
                    "error": "Fehler",
                    "failed": "Fehlgeschlagen\/Nicht ausgerichtet",
                    "none": "Keiner",
                    "passed": "Bestanden\/ausgerichtet",
                    "pass_fail": "Bestanden\/Nicht bestanden"
                },
                "table": {
                    "action_applied": "Aktion angewendet",
                    "action_taken": "Vom Empfänger ergriffene Maßnahmen",
                    "alignment": "Ausrichtung",
                    "compliance": "Einhaltung",
                    "delivered": "Geliefert",
                    "dkim_aligned": "DKIM-ausgerichtet",
                    "dkim_details": "DKIM-Details",
                    "dkim_failed": "DKIM fehlgeschlagen",
                    "dkim_none": "DKIM Keine",
                    "dkim_not_aligned": "DKIM nicht ausgerichtet",
                    "dkim_no_info": "Keine DKIM-Informationen",
                    "dkim_passed": "DKIM bestanden",
                    "dkim_results": "DKIM-Ergebnisse",
                    "dmarc_failed": "DMARC fehlgeschlagen",
                    "dmarc_passed": "DMARC bestanden",
                    "dmarc_results": "DMARC-Ergebnisse",
                    "dmarc_via_dkim": "DMARC über DKIM",
                    "dmarc_via_spf": "DMARC über SPF",
                    "envelope_from": "Umschlag von",
                    "envelope_to": "Umschlag an",
                    "from_domain": "Von der Domäne",
                    "header_from": "Kopfzeile von",
                    "none": "Keiner",
                    "no_dkim_details": "Keine DKIM-Signaturdetails",
                    "no_spf_details": "Keine SPF-Angaben",
                    "other_details": "Weitere Details",
                    "percent_of_total": "% der Gesamtmenge",
                    "policy_applied": "Angewendete Richtlinie",
                    "policy_of_applied": "Die Richtlinie „:policy“ wurde vom Empfänger angewendet",
                    "published_policy": "Veröffentlichte Richtlinie",
                    "quarantine": "Quarantäne",
                    "reject": "Ablehnen",
                    "reporter": "Reporter",
                    "result": "Ergebnis",
                    "return_path_domain": "Return Path-Domäne",
                    "selector": "Wähler",
                    "signing_domain": "Signierende Domäne",
                    "spf_aligned": "SPF-ausgerichtet",
                    "spf_details": "SPF-Details",
                    "spf_fail": "SPF-Fehler",
                    "spf_none": "Lichtschutzfaktor: Keiner",
                    "spf_not_aligned": "SPF nicht abgestimmt",
                    "spf_no_info": "Keine SPF-Informationen",
                    "spf_pass": "SPF-Pass",
                    "spf_results": "SPF-Ergebnisse",
                    "spf_tmp_error": "Temporärer SPF-Fehler",
                    "validated_dmarc_policy": "Dmarc-Richtlinie zum Zeitpunkt der Validierung",
                    "volume": "Volumen"
                }
            }
        },
        "logs": {
            "events": {
                "created": "erstellt",
                "deleted": "gelöscht",
                "restored": "restauriert",
                "updated": "aktualisiert"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Zertifikat",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegierung aktiviert",
                        "nameservers": "Nameserver",
                        "selector": "Wähler"
                    },
                    "title": "BIMI-Einstellungen :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Beschreibung",
                        "record_type": "Datensatztyp",
                        "selector": "Wähler",
                        "status": "Status",
                        "ttl": "TTL",
                        "value": "Wert"
                    },
                    "title": "Öffentlicher DKIM-Schlüssel :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegierung aktiviert",
                        "is_policy_enabled": "Richtlinie aktiviert",
                        "nameservers": "Nameserver",
                        "policy": "Politik"
                    },
                    "title": "DKIM-Einstellungen :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM-Ausrichtung",
                        "policy": "Politik",
                        "policy_percentage": "Versicherungsprozentsatz",
                        "rua_emails": "Aggregierte Berichtsempfänger",
                        "rua_interval": "Aggregiertes Berichtsintervall",
                        "ruf_emails": "Empfänger von Fehlerberichten",
                        "ruf_format": "Fehlerberichtsformat",
                        "ruf_options": "Optionen für Fehlerberichte",
                        "ruf_status": "Status des Fehlerberichts",
                        "spf_alignment": "SPF-Ausrichtung",
                        "subdomain_policy": "Subdomain-Richtlinie"
                    },
                    "title": "DMARC-Einstellungen :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Nachrichten",
                        "result": "Ergebnis",
                        "started_at": "Begonnen um",
                        "status": "Status",
                        "type": "Typ"
                    },
                    "title": "DNS-Delegierungsüberprüfung :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domain",
                        "is_favorite": "Favorit",
                        "logo": "Logo",
                        "score": "Domain-Score",
                        "type": "Typ"
                    },
                    "title": "Domäne :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_policy_hosting_enabled": "Richtlinien-Hosting aktiviert",
                        "is_record_enabled": "Aufnahmehosting aktiviert",
                        "mx_records": "MX-Records",
                        "policy": "Richtlinienmodus"
                    },
                    "title": "MTA-STS-Einstellungen :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Beschreibung",
                        "mechanism": "Datensatztyp",
                        "order": "Befehl",
                        "qualifier": "Rekordqualifikation",
                        "status": "Status",
                        "value": "Hostwert"
                    },
                    "title": "SPF-Anweisungen :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Rekordqualifikation auf höchstem Niveau",
                        "enable_flattening": "Reduzierung aktiviert"
                    },
                    "title": "SPF-Einstellungen :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT aktiviert",
                        "rua_emails": "E-Mails melden"
                    },
                    "title": "TLS-RPT-Einstellungen :event"
                }
            },
            "header": ":type Protokolle"
        },
        "menu": {
            "domains": "Domänen",
            "home": "Armaturenbrett",
            "reports": {
                "email_senders": "E-Mail-Absender",
                "failure_reports": "Fehlerberichte",
                "reports": "Berichte",
                "tls_rpt_reports": "TLS-RPT-Berichte"
            },
            "settings": {
                "alerts": "Warnungen",
                "logout": "Abmelden",
                "settings": "Einstellungen"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "MTA-STS-Hosting deaktiviert",
                "heading": "MTA-STS-Hosting ist aktiviert",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung und das MTA-STS-Richtlinienhosting zu aktivieren.",
                "modal": {
                    "header": "Deaktivieren Sie MTA-STS-Hosting für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie MTA-STS-Hosting",
                "heading": "MTA-STS-Hosting ist deaktiviert",
                "message": "Es wird empfohlen, MTA-STS Record and Policy Hosting zu aktivieren, um Ihre Datensätze effektiv verwalten zu können.",
                "modal": {
                    "header": "Aktivieren Sie MTA-STS-Hosting für :domain",
                    "message": "Durch die Aktivierung von MTA-STS-Hosting können Sie Ihre Aufzeichnungen und das Hosting für Ihre Richtlinie verwalten."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX-Server"
                },
                "policy": {
                    "input_label": "Ebene der Richtliniendurchsetzung",
                    "options": {
                        "enforce": "Durchgesetzt",
                        "none": "Keiner",
                        "testing": "Beim Testen"
                    }
                }
            },
            "heading": "MTA-STS-Datensatz- und Richtlinien-Hosting",
            "info": {
                "heading": "Was für TLS-RPT und MTA-STS?",
                "message": "TLS-RPT oder SMTP TLS Reporting (Transport Layer Security Reporting) ermöglicht es sendenden Systemen, Statistiken und Informationen über Fehler mit Empfängerdomänen auszutauschen. Mit MTA-STS (Mail Transfer Agents – Strict Transport Security) können E-Mail-Dienstanbieter ihre Fähigkeit zum Empfang TLS-sicherer SMTP-Verbindungen deklarieren."
            },
            "status": {
                "policy": {
                    "pending": "Die Überprüfung der Richtlinie steht noch aus",
                    "verified": "Richtlinie überprüft"
                },
                "record": {
                    "pending": "Aufzeichnung ausstehender Überprüfung",
                    "verified": "Datensatz bestätigt"
                }
            }
        },
        "partners": {
            "title": "Partner verwalten"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Konfigurieren Sie die DMARC-Einstellungen",
                        "manage_domains": "Domänen verwalten"
                    },
                    "description": "Wir haben noch keine Berichtsdaten für Ihre Domain erhalten. Es kann bis zu 72 Stunden dauern, bis Daten angezeigt werden. Berichte können nur für aktive und verifizierte Domänen abgerufen werden. Verwalten Sie den Rest Ihrer Domänen, indem Sie zur Domänenoberfläche gehen.",
                    "header": "Keine Berichtsdaten verfügbar"
                },
                "no_reporting_data": {
                    "description": "Für den ausgewählten Zeitraum sind keine Berichtsdaten verfügbar. Bitte passen Sie Ihre Auswahl an und versuchen Sie es erneut.",
                    "header": "Keine Berichtsdaten verfügbar"
                }
            }
        },
        "scoring": {
            "blurb": {
                "high": "Sie verfügen nicht über wirksame Kontrollen zum Schutz Ihrer Domain\n              Identitätsdiebstahl und Abfangen der E-Mail-Kommunikation. Das bringt dich\n              Marken- und E-Mail-Empfänger einem Risiko von Angriffen ausgesetzt, das Vertrauen sinkt und kann\n              beeinträchtigt die Zustellbarkeit von E-Mails.",
                "low": "E-Mails von Ihrer Domain sind äußerst sicher vor Identitätsdiebstahl und\n              Abfangen und Empfänger werden Ihr Branding in der Kommunikation sehen. Diese\n              Maßnahmen bauen Vertrauen in Ihre Marke auf, schützen Empfänger vor Angriffen und\n              tragen dazu bei, dass E-Mails effektiv zugestellt werden.",
                "moderate": "Sie haben einige Maßnahmen ergriffen, um Empfänger vor bösartigen E-Mails zu schützen\n              von Ihrer Domain. Es sollte auf Bereiche geachtet werden, in denen Kontrollen stattfinden können\n              verbessert. Diese Maßnahmen schaffen Vertrauen in Ihre Marke und schützen die Empfänger\n              vor Angriffen und tragen dazu bei, dass E-Mails effektiv zugestellt werden."
            },
            "header": "Domain-Score",
            "impersonation": "Identitätswechsel",
            "marketing": "Marketing",
            "note": "HINWEIS: Dies ist Ihr aktueller Domain-Score und spiegelt möglicherweise nicht die Änderungen wider, die Sie heute vorgenommen haben.",
            "privacy": "Privatsphäre",
            "rating": {
                "high": "Hohes Risiko",
                "low": "Geringes Risiko",
                "moderate": "Mäßiges Risiko"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Blockiertes Volumen",
                "compliance": "Einhaltung",
                "deliverability": "Lieferfähigkeit",
                "deliverable_volume": "Lieferbares Volumen",
                "ip_addresses": "IP-Adressen",
                "senders": "Absender",
                "volume": "E-Mail-Volumen"
            },
            "categories": {
                "all": "Alle Absender",
                "authorised_compliant": "Genehmigt",
                "forwarder": "Spediteur",
                "suspicious": "Verdächtig",
                "unauthorised": "Nicht genehmigt",
                "unknown": "Keiner"
            },
            "menu": {
                "compliance": "Einhaltung",
                "deliverability": "Lieferfähigkeit",
                "reputation": "Ruf",
                "threats": "Bedrohungen"
            },
            "messages": {
                "category_updated": {
                    "error": "Die Absenderkategorie konnte nicht aktualisiert werden",
                    "success": "Absenderkategorie aktualisiert"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Auf der schwarzen Liste",
                    "clean": "Sauber",
                    "compliant": "Konform",
                    "delivered": "Geliefert",
                    "failing_dmarc": "DMARC scheitert",
                    "forward": "Vorwärts",
                    "not_delivered": "Nicht geliefert",
                    "overridden": "Überschrieben",
                    "overridden_as_delivered": "Bei Auslieferung außer Kraft gesetzt",
                    "overridden_as_not_delivered": "Überschrieben, da nicht zugestellt",
                    "passing": "Vorbeigehen",
                    "passing_dkim": "Nur DKIM bestehen",
                    "passing_dkim_spf": "Bestehen von DKIM und SPF",
                    "passing_dmarc": "DMARC bestanden",
                    "passing_spf": "Nur SPF bestanden",
                    "senders": "Absender"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Zugelassene Absender-IPs",
                        "approved_sender_volume": "Genehmigtes Absendervolumen",
                        "blacklisted": "Auf der schwarzen Liste",
                        "blacklists": "Schwarze Listen",
                        "blocked_volume": "Blockiertes Volumen",
                        "category": "Kategorie",
                        "clean": "Sauber",
                        "compliance": "Einhaltung",
                        "country": "Land",
                        "deliverability": "Lieferfähigkeit",
                        "delivered": "Geliefert",
                        "failing": "Fehlgeschlagen",
                        "forwards": "Vorwärts",
                        "host": "Gastgeber",
                        "ip": "IP",
                        "ip_addresses": "IP-Adressen",
                        "not_delivered": "Nicht geliefert",
                        "passing": "Vorbeigehen",
                        "sender": "Absender",
                        "top_blacklisted_senders": "Top-Absender auf der schwarzen Liste",
                        "volume": "Volumen"
                    },
                    "info": {
                        "blacklists": "Anzahl der Blacklists, in denen die IP-Adresse aufgeführt ist"
                    }
                }
            },
            "title": "E-Mail-Absender für :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Neue SPF-Richtlinie hinzufügen",
                "edit_record": {
                    "tooltip": "Datensatz bearbeiten"
                }
            },
            "create_drawer": {
                "header": "Neue SPF-Richtlinie hinzufügen"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Richtlinien",
                        "order": "Befehl"
                    }
                }
            },
            "edit_drawer": {
                "header": "Bearbeiten Sie die SPF-Richtlinie"
            },
            "fields": {
                "description": {
                    "input_label": "Beschreibung"
                },
                "host": {
                    "example": "z.B. _spf.example.com",
                    "input_label": ":type"
                },
                "mechanism": {
                    "input_label": "Datensatztyp"
                },
                "qualifier": {
                    "input_label": "Rekordqualifikation",
                    "options": {
                        "allow": "„+“-Pass (nicht empfohlen)",
                        "fail": "\"-\" Scheitern",
                        "neutral": "„?“ Neutral (nicht empfohlen)",
                        "soft_fail": "„~“ Soft Fail"
                    }
                },
                "update": "Aktualisieren Sie eine SPF-Richtlinie",
                "use_own_domain": {
                    "input_label": "Mechanismusdomäne"
                }
            },
            "import_modal": {
                "action": "Entfernen und importieren",
                "complete": {
                    "message": "SPF-Import abgeschlossen"
                },
                "header": "Vorhandene SPF-Datensätze und -Anweisungen für :domain importieren",
                "message": "Alle vorhandenen Direktiven werden entfernt und durch importierte Direktiven ersetzt."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Rekordqualifikation auf höchstem Niveau",
                        "options": {
                            "+": "„+“-Pass (nicht empfohlen)",
                            "-": "\"-\" Scheitern",
                            "?": "„?“ Neutral (nicht empfohlen)",
                            "~": "„~“ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "„+all“ Wird so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern durchgelassen wird.",
                            "-": "Legen Sie fest, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern fehlschlägt.",
                            "?": "Auf „Neutral“ eingestellt, wodurch die Authentifizierung von E-Mails von nicht autorisierten Absendern weder bestanden noch fehlgeschlagen wird.",
                            "~": "Stellen Sie die Soft-Fail-Authentifizierung von E-Mails von nicht autorisierten Absendern ein und behandeln Sie sie als verdächtig."
                        }
                    },
                    "flattening": {
                        "input_label": "Aktivieren Sie die Reduzierung"
                    }
                },
                "heading": "Einstellungen"
            },
            "title": "Einstellungen bearbeiten – SPF"
        },
        "tables": {
            "clear": "Klar",
            "not_found": "Keine passenden Datensätze gefunden",
            "search": "Suchen"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Konfigurieren Sie die TLS-RPT-Einstellungen",
                    "description": "Wir haben noch keine Berichtsdaten für Ihre Domain erhalten. Es kann bis zu 72 Stunden dauern, bis Daten angezeigt werden. TLS-RPT-Berichte können nur für Domänen abgerufen werden, für die die TLS-RPT-Berichterstellung aktiviert ist.",
                    "header": "Keine Berichtsdaten verfügbar"
                }
            },
            "heading": "TLS-RPT-Berichte für :domain",
            "reporters": {
                "heading": "Berichte",
                "legend": {
                    "reports": "Berichte"
                },
                "table": {
                    "header": {
                        "policy_mode": "Richtlinienmodus",
                        "policy_type": "Richtlinientyp",
                        "reporter": "Reporter",
                        "total": "Gesamt"
                    }
                }
            },
            "timeline": {
                "heading": "Zeitleiste",
                "legend": {
                    "failure": "Fehlgeschlagen",
                    "success": "Erfolgreich"
                }
            },
            "title": "TLS-RPT-Berichte"
        },
        "tls_settings": {
            "disable": {
                "action": "Deaktivieren Sie die TLS-RPT-Berichterstellung",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung zu aktivieren, um die Berichterstellung zu überwachen.",
                "modal": {
                    "header": "TLS-RPT-Berichterstellung für :domain deaktiviert",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie die TLS-RPT-Berichterstellung",
                "heading": "Die TLS-RPT-Berichterstellung ist deaktiviert",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung zu aktivieren, um die Berichterstellung zu überwachen.",
                "modal": {
                    "header": "Aktivieren Sie die TLS-RPT-Berichterstellung für :domain",
                    "message": "Durch die Aktivierung der TLS-RPT-Berichterstellung können Sie Statistiken und Informationen zu Fehlern bei Empfängerdomänen überwachen."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Berichte werden an gesendet"
                }
            },
            "heading": "TLS-RPT-Berichterstellung",
            "title": "Einstellungen bearbeiten – TLS-RPT"
        },
        "upload": {
            "add": "Dateien hinzufügen",
            "drop_zone": "Ziehen Sie die Datei hierher, um sie hochzuladen.",
            "title": "Hochladen"
        },
        "users": {
            "email": {
                "edit": "E-Mail bearbeiten",
                "placeholder": "Geben Sie die E-Mail-Adresse ein"
            },
            "menu": "Benutzer",
            "name": {
                "title": "Name"
            },
            "title": "Benutzer verwalten"
        },
        "validation": {
            "email": "Keine gültige E-Mail-Adresse",
            "invalid_attr": "Das :attribute ist ungültig."
        }
    },
    "en": {
        "alerts": {
            "actions": {
                "create_activate": "Create & Activate",
                "edit_alert": {
                    "tooltip": "Edit Alert"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuration",
                    "mail_senders": "Mail Senders",
                    "none": "None",
                    "reports": "Reports",
                    "status": "Status"
                },
                "description": "Select the activity you would like to monitor.",
                "heading": "Activity",
                "options": {
                    "customer-created": {
                        "description": "Notify me when an account is created.",
                        "heading": "Customer Created"
                    },
                    "customer-deleted": {
                        "description": "Notify me when an account is created.",
                        "heading": "Customer Deleted"
                    },
                    "customer-exposure-report": {
                        "description": "Schedule when you would like to receive the Exposure Report.",
                        "heading": "Exposure Report"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Schedule DMARC Compliance Certificate when DMARC Policy is set to Reject.",
                        "heading": "DMARC Compliance Certificate On Reject"
                    },
                    "dns-delegation-unverified": {
                        "description": "Notify me when DNS delegation re-verification fails.",
                        "heading": "Setup Re-Verification Failed"
                    },
                    "dns-delegation-verified": {
                        "description": "Notify where the verification status has changed",
                        "heading": "Verification Status Changed"
                    },
                    "domain-compliance-drop": {
                        "description": "Notify me when compliance drops below a threshold.",
                        "heading": "Compliance Dropped"
                    },
                    "domain-created": {
                        "description": "Notify me when a new domain is added.",
                        "heading": "Domain Created"
                    },
                    "domain-deleted": {
                        "description": "Notify me when a domain is deleted.",
                        "heading": "Domain Deleted"
                    },
                    "domain-score-change": {
                        "description": "Notify me when the DMARC Compliance Score has changed.",
                        "heading": "DMARC Compliance Score Changed"
                    },
                    "domain-settings-change": {
                        "description": "Notify me when settings are changed.",
                        "heading": "Changes to Settings"
                    },
                    "sender-threat-level-change": {
                        "description": "Notify me when Threat Level increase for new or existing senders.",
                        "heading": "Threat Level Increased"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Notify me when a new, commonly Approved Sender is detected.",
                        "heading": "Typically Authorized Sender Found"
                    }
                }
            },
            "create": {
                "title": "Create Alert"
            },
            "edit": {
                "title": "Edit Alert"
            },
            "email": {
                "description": "Specify email recipient you would like to notify and customize the email.",
                "heading": "Email"
            },
            "email_settings": {
                "heading": "Customize Email"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Customer",
                        "domain": "Domain"
                    }
                },
                "frequency": {
                    "input_label": "Frequency"
                },
                "recipients": {
                    "input_label": "Recipients"
                },
                "scope": {
                    "input_label": "Level of Monitoring",
                    "listing-title": ":scope Activity",
                    "options": {
                        "customer": "Customer",
                        "partner": "Partner",
                        "system": "System"
                    }
                },
                "select_below": {
                    "description": "Setup Verification",
                    "input_label": "Select Below"
                },
                "start_date": {
                    "input_label": "Start Date"
                },
                "subject_tags": {
                    "example": "e.g. [alert-severity][account][package][domain]",
                    "input_label": "Enable subject line categorization prefixes"
                }
            },
            "frequency": {
                "description": "Choose how often you would like to receive this notification.",
                "heading": "Frequency"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alert",
                        "frequency": "Frequency",
                        "recipients": "Recipients",
                        "status": "Status"
                    },
                    "record": {
                        "alert_activity": ":scope Activity"
                    }
                },
                "title": "Alerts"
            },
            "monitor": {
                "description": "Select the activity you would like to monitor.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Daily",
                        "fortnightly": "Fortnightly",
                        "hourly": "Hourly",
                        "immediate": "Immediate",
                        "monthly": "Monthly",
                        "quarterly": "Quarterly",
                        "six_monthly": "Six monthly",
                        "two_monthly": "Two monthly",
                        "weekly": "Weekly",
                        "yearly": "Yearly"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "Alert is Inactive",
                        "value": "off"
                    },
                    "on": {
                        "tooltip": "Alert is Active",
                        "value": "on"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Disable BIMI Record Hosting",
                "heading": "BIMI Record Hosting is Currently Enabled",
                "message": "It is recommended that BIMI Hosting is enabled to easily manage your logo and certificate.",
                "modal": {
                    "header": "Disable BIMI Record Hosting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable BIMI Record Hosting",
                "heading": "BIMI Hosting is Disabled",
                "message": "It is recommended that BIMI Hosting is enabled to manage your Logo and Certificate.",
                "modal": {
                    "header": "Enable BIMI Record Hosting :domain",
                    "message": "By enabling BIMI Hosting you can easily manage your logo and certificate."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificate CN",
                    "expiry": "Certificate Expiry",
                    "input_label": "BIMI Verified Mark Certificate (File type: pem)",
                    "url": "Authority \/ Certificate URL"
                },
                "image": {
                    "input_label": "BIMI Logo (File type: svg)",
                    "preview": "Logo Preview",
                    "size": "Logo size",
                    "url": "Logo Url"
                }
            },
            "info": {
                "heading": "What is BIMI?",
                "message": "BIMI (Brand Indicators for Message Identification) is a standard that makes it easier to get your logo displayed next to your messages in the inbox, prevent fraudulent emails, and aide deliverability. BIMI works alongside DMARC, DKIM, and SPF to verify an email's authenticity and help with brand visibility."
            },
            "settings": {
                "heading": "Settings"
            },
            "title": "Edit Settings - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Back to Settings",
                "share": "Share Setup Instructions",
                "verify": "Verify",
                "view": "View Setup Instructions"
            },
            "bimi": {
                "description": "Update your DNS to allow the configuration of your domain's BIMI records."
            },
            "description": "To enable monitoring and management through DMARC Manager,please implement the following changes in your domain’s DNS settings.",
            "dkim": {
                "description": "Update your DNS to allow the configuration of your domain’s DKIM Public Keys."
            },
            "dmarc": {
                "description": "Update your DNS to allow the configuration of your domain’s DMARC policy and to capture reports on email authentication activity.",
                "warning": "Take note of the required underscores (_) in the host and full-stops in the record value."
            },
            "existing_dns_record_found": "Existing :record Record Found",
            "heading": ":title Setup Instructions",
            "instructions": {
                "add": "Add :rdType Record",
                "heading": ":rdType Setup Instructions",
                "new": "Step 2: Add New :rdType Record",
                "remove": "Step 1: Remove Existing :rdType Record"
            },
            "mta_policy": {
                "description": "Update your DNS to allow the configuration of your domain’s MTA Policy.",
                "heading": "MTA-STS Policy"
            },
            "mta_record": {
                "description": "Update your DNS to allow the configuration of your domain’s MTA Record.",
                "heading": "MTA-STS Record"
            },
            "record": {
                "host": "Host",
                "type": "Record Type",
                "value": "Value"
            },
            "spf": {
                "description": "Update your DNS to allow the configuration of your domain’s SPF record."
            },
            "status": {
                "last_date": "Last Verified on",
                "pending": "Pending Verification",
                "verified": "Verified"
            },
            "tls_rpt": {
                "description": "Update your DNS to allow the configuration of your domain’s TLS-RPT record and to capture reports.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Manage Customers"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Email Volume",
                    "legends": {
                        "approved": "Approved",
                        "unapproved": "Unapproved & Other"
                    }
                },
                "domain_totals": {
                    "action": "View Domains",
                    "enforced": "Enforced",
                    "heading": "Domains",
                    "reporting": "Reporting",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "View Senders",
                    "heading": "Senders",
                    "legends": {
                        "approved": "Approved",
                        "unapproved": "Unapproved"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "View All Domains"
                },
                "legends": {
                    "failing": "Failing DMARC",
                    "forwards": "Forwards",
                    "passing": "Passing DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "View Report"
                    },
                    "header": {
                        "approved": "Approved",
                        "domain": "Domains",
                        "senders": "Senders",
                        "unapproved": "Unapproved",
                        "volume": "Volume"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "View Domain Report"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "domain": "Domain",
                        "sender": "Approved Senders by Volume",
                        "volume": "Volume"
                    }
                }
            },
            "title": "Dashboard"
        },
        "delegation-verifications": {
            "verify": {
                "error": "Verification Failed",
                "success": "Verification Completed Successfully"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Add New Public Key",
                "edit_public_key": {
                    "tooltip": "Edit Public Key"
                }
            },
            "create_drawer": {
                "header": "Add New Public Key"
            },
            "disable": {
                "action": "Disabled DKIM Management",
                "heading": "DKIM Public Key Hosting is Currently Enabled",
                "message": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
                "modal": {
                    "header": "Disable DKIM Management for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "edit_drawer": {
                "header": "Edit Public Key"
            },
            "enable": {
                "action": "Enable DKIM Management",
                "heading": "DKIM Public Key Hosting is Disabled",
                "message": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
                "modal": {
                    "header": "Enable DKIM Management for :domain",
                    "message": "By enabling DKIM management you can manage your public keys with ease."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "record_type": {
                    "input_label": "Record Type"
                },
                "record_value": {
                    "example": "e.g. dkim.example.com",
                    "input_label": "Record Value"
                },
                "selector": {
                    "input_label": "Selector"
                }
            },
            "import_modal": {
                "action": "Remove & Import",
                "complete": {
                    "message": "DKIM Public Key Import Completed"
                },
                "disabled_tooltip": "DKIM public keys can only be imported for domains that have reports.",
                "header": "Re-import DKIM Public Keys for :domain",
                "message": "All existing public keys will be removed and replaced with imported directives."
            },
            "info": {
                "heading": "What is DKIM?",
                "message": "Domain Keys Identified Mail is an email authentication method that allows a sender to Domain Keys Identified Mail is an email authentication method that allows a sender to digitally sign an email using a private key associated with their domain name, which can be verified by the recipient's mail server using a public key published in the sender's DNS records. This helps to verify the authenticity and integrity of the email, reducing the risk of spam and phishing attacks."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Outbound Signing Policy",
                        "options": {
                            "all": "All Emails",
                            "some": "Some Emails"
                        }
                    },
                    "testing": {
                        "input_label": "Testing Mode"
                    }
                },
                "heading": "Policy Settings"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Record Type",
                        "selector": "Selector",
                        "value": "Value"
                    }
                }
            },
            "title": "Edit Settings - DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM Alignment mode"
                    },
                    "options": {
                        "r": "Relaxed",
                        "s": "Strict"
                    },
                    "spf_mode": {
                        "input_label": "SPF Alignment mode"
                    }
                },
                "heading": "Alignment Settings"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Apply Different Subdomain Policy"
                    },
                    "percentage": {
                        "input_label": "Percentage"
                    },
                    "policy": {
                        "input_label": "DMARC Policy"
                    },
                    "subdomain_policy": {
                        "input_label": "Subdomain Policy"
                    }
                },
                "heading": "Policy Settings"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Reports Will be Sent to"
                    },
                    "interval": {
                        "input_label": "Report Interval",
                        "options": {
                            "1_day": "Once a Day",
                            "1_hour": "Every Hour",
                            "2_hours": "Every 2 Hours",
                            "4_hours": "Every 4 Hours",
                            "6_hours": "Every 6 Hours"
                        }
                    }
                },
                "heading": "Aggregate Reports"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Enable",
                    "header": "Enable failure reporting",
                    "message": "By enabling failure reporting, some personal information, such as email addresses or message content, may be exposed for diagnostic purposes",
                    "reject": "Disable"
                },
                "fields": {
                    "options": {
                        "input_label": "Report Options",
                        "options": {
                            "0": "SPF or DKIM fail and don't align",
                            "1": "SPF and DKIM fail and don't align",
                            "d": "DKIM fails regardless of alignment",
                            "s": "SPF fails regardless of alignment"
                        }
                    },
                    "status": {
                        "input_label": "Report Processing",
                        "options": {
                            "disabled": "Disabled",
                            "enabled": "Enabled",
                            "specified": "Specific Recipients"
                        }
                    }
                },
                "heading": "Failure Reports"
            },
            "title": "Edit Settings - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC Compliance Certificate",
                "domain_report": "Domain Report",
                "edit_settings": {
                    "tooltip": "Edit Settings"
                },
                "remove_primary": "Remove from Favourite",
                "set_as_active": "Set as Active",
                "set_as_parked": "Set as Parked",
                "set_primary": "Mark as Favourite",
                "view_logs": "View Logs"
            },
            "create_drawer": {
                "header": "Add New Domain"
            },
            "fields": {
                "name": {
                    "example": "e.g. 'example.com' or 'subdomain.example.com'",
                    "input_label": "Domain Name"
                },
                "type": {
                    "input_label": "Domain Type",
                    "options": {
                        "active": "Active",
                        "parked": "Parked"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domain",
                        "policy": "Policy",
                        "score": "Score",
                        "status": "Status",
                        "type": "Type"
                    }
                },
                "title": "Domains"
            },
            "logs_drawer": {
                "header": "Domain"
            },
            "report_modal": {
                "action": "Generate Link",
                "fields": {
                    "date_range": {
                        "input_label": "Date Range"
                    },
                    "expire_link": {
                        "input_label": "Expire Link in",
                        "options": {
                            "1_day": "One Day",
                            "1_month": "One Month",
                            "1_week": "One Week",
                            "6_hours": "6 Hours",
                            "12_hours": "12 Hours",
                            "never": "Never"
                        }
                    }
                },
                "header": "Domain Report"
            },
            "settings": {
                "title": "Edit Settings"
            },
            "setup_modal": {
                "action": "Generate Link",
                "fields": {
                    "copy_link": {
                        "input_label": "Link to Setup Instructions"
                    },
                    "expire_link": {
                        "input_label": "Expire Link in",
                        "options": {
                            "1_day": "One Day",
                            "1_month": "One Month",
                            "1_week": "One Week",
                            "6_hours": "6 Hours",
                            "12_hours": "12 Hours",
                            "never": "Never"
                        }
                    }
                },
                "header": "Share Setup Instructions"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "None"
                    },
                    "quarantine": {
                        "value": "Quarantine"
                    },
                    "reject": {
                        "value": "Reject"
                    },
                    "tooltip": "Policy Applied"
                },
                "primary": {
                    "tooltip": "Favourite",
                    "value": "Favourite"
                },
                "score": {
                    "high": {
                        "tooltip": "High Risk"
                    },
                    "low": {
                        "tooltip": "Low Risk"
                    },
                    "moderate": {
                        "tooltip": "Moderate Risk"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verified, Not Reporting",
                        "tooltip_partial": "Partially Verified, Not Reporting",
                        "value": "Not Reporting"
                    },
                    "not_verified": {
                        "tooltip": "Not Verified, Not Reporting",
                        "value": "Not Verified"
                    },
                    "reporting": {
                        "tooltip": "Verified, Reporting",
                        "tooltip_partial": "Partially Verified, Reporting",
                        "value": "Reporting"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Active Domain",
                        "value": "Active"
                    },
                    "parked": {
                        "tooltip": "Parked Domain",
                        "value": "Parked"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "e.g. recipient@example.com",
                    "input_label": "Edit Email",
                    "placeholder": "Enter Email Address",
                    "subscribed_label": "Subscribed",
                    "unsubscribed_label": "Unsubscribed",
                    "validation_message": "Enter Valid Email Address"
                }
            }
        },
        "errors": {
            "menu": "Error",
            "401": {
                "description": "Sorry, you are unauthorized to access this page.",
                "title": "401: Unauthorized"
            },
            "403": {
                "description": "Sorry, you are forbidden from accessing this page.",
                "title": "403: Forbidden"
            },
            "404": {
                "description": "Sorry, the page you are looking for could not be found.",
                "title": "404: Page Not Found"
            },
            "500": {
                "description": "Whoops, something went wrong on our servers.",
                "title": "500: Server Error"
            },
            "502": {
                "description": "Sorry, our servers are down.",
                "title": "502: Bad Gateway"
            },
            "503": {
                "description": "Sorry, we are doing some maintenance. Please check back soon.",
                "title": "503: Service Unavailable"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Email Volume",
                "legends": {
                    "failing": "Failing DMARC",
                    "forward": "Forwards",
                    "passing": "Passing DMARC"
                }
            },
            "heading": "Domain Report",
            "recommendations": {
                "heading": "Recommendations"
            },
            "risk_analysis": {
                "heading": "Risk Analysis"
            },
            "scoring": {
                "note": "Note: The domain score is calculated at the time of generating the report and may not be up to date at the time of viewing."
            },
            "sender_readiness": {
                "heading": "Sender DMARC Readiness",
                "legends": {
                    "configured": {
                        "description": "DMARC Readiness is a measure of the configuration require to implement full compliance.",
                        "heading": "Configured"
                    },
                    "partially": {
                        "description": "Partially Configured Sources have at least one authorisation mechanism active however, additional setup may be necessary.",
                        "heading": "Partially Configured"
                    },
                    "unconfigured": {
                        "description": "Illegitimate or Unconfigured Sources, are sources sending email from your domain that may be impersonating your brand.",
                        "heading": "Illegitimate or Unconfigured"
                    }
                }
            },
            "title": "Domain report for :domain",
            "top_countries": {
                "heading": "Top Origination Countries - Email Volume"
            },
            "top_senders": {
                "heading": "Top Senders - Compliance"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Download Report",
                "header": "Failure Report",
                "menu": {
                    "key_properties": "Key Properties",
                    "more_info": "More Info"
                }
            },
            "properties": {
                "authentication_failure": "Authentication Failure",
                "date": "Date",
                "delivery_results": "Delivery Results",
                "dkim_domain": "DKIM Domain",
                "dkim_header": "DKIM Canonicalised Header",
                "dkim_identity": "DKIM Identity",
                "dkim_selector": "DKIM Selector",
                "dmarc_version": "DMARC Version",
                "feedback_type": "Feedback Type",
                "incidents": "Number of Incidents",
                "original_recipients": "Original Recipients",
                "reporting_domains": "Reported Domains",
                "reporting_mta": "Reporting MTA",
                "reporting_user_agent": "Reporting User Agent",
                "return_path": "Return Path",
                "source_ip": "Source IP Address",
                "spf_dns": "SPF DNS"
            },
            "table": {
                "header": {
                    "authentication": "Authentication",
                    "date": "Date",
                    "ip_address": "IP Address",
                    "result": "Result",
                    "return_path": "Return Path"
                }
            },
            "title": "Failure Reports for :domain"
        },
        "global": {
            "buttons": {
                "add": "Add",
                "add_new": "Add New",
                "cancel": "Cancel",
                "delete": "Delete",
                "import": "Import",
                "next": "Next",
                "no": "No",
                "remove": "Remove",
                "save": "Save",
                "save_exit": "Save and Back",
                "select": "Select",
                "yes": "Yes"
            },
            "confirm_delete": "Do you want to continue?",
            "copied": "Copied",
            "created": "Created",
            "deleted": "Deleted",
            "fail": "Fail",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Disabled",
                        "enabled": "Enabled"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Off",
                        "on": "On"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "No",
                        "yes": "Yes"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Error: Please fill out all required fields correctly.",
                    "title": "There were errors with your form."
                },
                "success": {
                    "title": "Your changes were saved."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volume"
                }
            },
            "logout": "Logout",
            "not_found": "Not found",
            "pass": "Pass",
            "remove": "Remove",
            "show_less": "Show less",
            "show_more": "Show more",
            "system": "System",
            "theme": {
                "dark": "Dark",
                "light": "Light"
            },
            "to": "To"
        },
        "ip_address_modal": {
            "menu": {
                "overrides": "Overrides",
                "overview": "Overview",
                "reporters": "Reporters",
                "results": "Results"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Policy applied 'None'",
                    "policy_applied_quarantine": "Policy applied 'Quarantine'",
                    "policy_applied_reject": "Policy applied 'Reject'"
                },
                "overridden_policy_breakdown": "Overridden policy Breakdown",
                "volume_overridden_policy": "Volume with overridden policies"
            },
            "overview": {
                "cards": {
                    "compliance": "Compliance",
                    "deliverability": "Deliverability",
                    "volume": "Volume"
                },
                "properties": {
                    "country": "Country",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organisation",
                    "sender": "Sender"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "envelope_from": "Envelope From",
                        "envelope_to": "Envelope To",
                        "header_from": "Header From",
                        "percent_of_total": "% of Total",
                        "reporter": "Reporter",
                        "volume": "Volume"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alignment",
                    "error": "Error",
                    "fail": "Fail",
                    "failed": "Failed\/Not Aligned",
                    "none": "None",
                    "pass": "Pass",
                    "passed": "Passed\/Aligned"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Action Applied",
                    "info": {
                        "tooltip": "Action Taken by Receiver"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Delivered",
                            "value": "Delivered"
                        },
                        "quarantine": {
                            "tooltip": "Quarantined",
                            "value": "Quarantined"
                        },
                        "reject": {
                            "tooltip": "Rejected",
                            "value": "Rejected"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alignment",
                            "dmarc_via_dkim": "DMARC via DKIM",
                            "results": "Results",
                            "selector": "Selector",
                            "signing_domain": "Signing Domain"
                        },
                        "heading": "DKIM Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM Not Aligned",
                            "no_info": "No DKIM Info",
                            "yes": "DKIM Aligned"
                        },
                        "header": "DKIM Results",
                        "no_details": "No DKIM Signature Details",
                        "pass_tooltips": {
                            "no": "DKIM Failed",
                            "none": "DKIM None",
                            "yes": "DKIM Pass"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "Policy of ':policy' was applied by receiver"
                        },
                        "header": {
                            "from_domain": "From Domain"
                        },
                        "heading": "Other Details"
                    },
                    "results": {
                        "header": "DMARC Results",
                        "pass_tooltips": {
                            "no": "DMARC Failed",
                            "yes": "DMARC Passed"
                        }
                    }
                },
                "override_comment": {
                    "header": "Comment"
                },
                "override_type": {
                    "header": "Override Type"
                },
                "published_policy": {
                    "header": "Published Policy",
                    "info": {
                        "tooltip": "DMARC policy at time of validation"
                    }
                },
                "reporter": {
                    "header": "Reporter"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alignment",
                            "dmarc_via_spf": "DMARC via SPF",
                            "result": "Result",
                            "return_path_domain": "Return Path Domain"
                        },
                        "heading": "SPF Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF Not Aligned",
                            "no_info": "No SPF Info",
                            "yes": "SPF Aligned"
                        },
                        "header": "SPF Results",
                        "no_details": "No SPF Details",
                        "pass_tooltips": {
                            "error": "SPF Temporary Error",
                            "no": "SPF Failed",
                            "none": "SPF None",
                            "yes": "SPF Pass"
                        }
                    }
                },
                "volume": {
                    "header": "Volume"
                }
            }
        },
        "logs": {
            "events": {
                "created": "created",
                "deleted": "deleted",
                "restored": "restored",
                "updated": "updated"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificate",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegation Enabled",
                        "nameservers": "Nameservers",
                        "selector": "Selector"
                    },
                    "title": "BIMI settings :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Description",
                        "record_type": "Record Type",
                        "selector": "Selector",
                        "status": "Status",
                        "ttl": "TTL",
                        "value": "Value"
                    },
                    "title": "DKIM public key :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegation Enabled",
                        "is_policy_enabled": "Policy Enabled",
                        "nameservers": "Nameservers",
                        "policy": "Policy"
                    },
                    "title": "DKIM settings :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM Alignment",
                        "policy": "Policy",
                        "policy_percentage": "Policy Percentage",
                        "rua_emails": "Aggregate Report Recipients",
                        "rua_interval": "Aggregate Report Interval",
                        "ruf_emails": "Failure Report Recipients",
                        "ruf_format": "Failure Report Format",
                        "ruf_options": "Failure Report Options",
                        "ruf_status": "Failure Report Status",
                        "spf_alignment": "SPF Alignment",
                        "subdomain_policy": "Subdomain Policy"
                    },
                    "title": "DMARC settings :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Messages",
                        "result": "Result",
                        "started_at": "Started at",
                        "status": "Status",
                        "type": "Type"
                    },
                    "title": "DNS Delegation Verification :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domain",
                        "is_favorite": "Favourite",
                        "logo": "Logo",
                        "score": "Domain Score",
                        "type": "Type"
                    },
                    "title": "Domain :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_policy_hosting_enabled": "Policy Hosting Enabled",
                        "is_record_enabled": "Record Hosting Enabled",
                        "mx_records": "MX Records",
                        "policy": "Policy Mode"
                    },
                    "title": "MTA-STS settings :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Description",
                        "mechanism": "Record Type",
                        "order": "Order",
                        "qualifier": "Record Qualifier",
                        "status": "Status",
                        "value": "Host Value"
                    },
                    "title": "SPF directives :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Top Level Record Qualifier",
                        "enable_flattening": "Flattening Enabled"
                    },
                    "title": "SPF settings :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT Enabled",
                        "rua_emails": "Report Emails"
                    },
                    "title": "TLS-RPT settings :event"
                }
            },
            "header": ":type Logs"
        },
        "menu": {
            "domains": "Domains",
            "home": "Dashboard",
            "reports": {
                "email_senders": "Email Senders",
                "failure_reports": "Failure Reports",
                "reports": "Reports",
                "tls_rpt_reports": "TLS-RPT Reports"
            },
            "settings": {
                "alerts": "Alerts",
                "logout": "Logout",
                "settings": "Settings"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Disabled MTA-STS Hosting",
                "heading": "MTA-STS Hosting is Enabled",
                "message": "It is recommended that TLS-RPT Reporting and MTA-STS Policy Hosting is enabled.",
                "modal": {
                    "header": "Disable MTA-STS Hosting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable MTA-STS Hosting",
                "heading": "MTA-STS Hosting is Disabled",
                "message": "It is recommended that MTA-STS Record and Policy Hosting is enabled to affective manage your records.",
                "modal": {
                    "header": "Enable MTA-STS Hosting for :domain",
                    "message": "By enabling MTA-STS Hosting you can manage your record and hosting for your policy."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX Servers"
                },
                "policy": {
                    "input_label": "Policy Enforcement Level",
                    "options": {
                        "enforce": "Enforced",
                        "none": "None",
                        "testing": "In Testing"
                    }
                }
            },
            "heading": "MTA-STS Record & Policy Hosting",
            "info": {
                "heading": "What TLS-RPT and MTA-STS?",
                "message": "TLS-RPT or SMTP TLS Reporting (Transport Layer Security Reporting) enables sending systems to share statistics and information about failures with recipient domains. MTA-STS (Mail Transfer Agents - Strict Transport Security) enables mail service providers to declare their ability to receive TLS secure SMTP connections."
            },
            "status": {
                "policy": {
                    "pending": "Policy Pending Verification",
                    "verified": "Policy Verified"
                },
                "record": {
                    "pending": "Record Pending Verification",
                    "verified": "Record Verified"
                }
            }
        },
        "partners": {
            "title": "Manage partners"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configure DMARC Settings",
                        "manage_domains": "Manage domains"
                    },
                    "description": "We've not yet received reporting data for your domain, it can take up to 72 hours for data to appear. Reports can only be retrieved for active and verified domains. Manage the rest of your domains by going to the Domains interface.",
                    "header": "No Reporting Data Available"
                },
                "no_reporting_data": {
                    "description": "No reporting data available for the selected date range, please adjust your selection and try again.",
                    "header": "No Reporting Data Available"
                }
            }
        },
        "scoring": {
            "blurb": {
                "high": "\n              You don't have effective controls in place to protect your domain from\n              impersonation and interception of email communication. This puts your\n              brand and email recipients at risk of attacks, reduces trust, and can\n              damage email deliverability.",
                "low": "\n              Email from your domain is highly secure from impersonation and\n              interception and recipients will see your branding in communication. These\n              measures build trust in your brand, protect recipients from attacks and\n              help ensure email is delivered effectively.",
                "moderate": "\n              You have some measures in place to protect recipients from malicious email\n              from your domain. Attention should be paid to areas where controls can be\n              improved. These measures build trust in your brand, protect recipients\n              from attacks and help ensure email is delivered effectively.\n            "
            },
            "header": "Domain Score",
            "impersonation": "Impersonation",
            "marketing": "Marketing",
            "note": "NOTE: This is your current domain score and may not reflect changes you've made today.",
            "privacy": "Privacy",
            "rating": {
                "high": "High Risk",
                "low": "Low Risk",
                "moderate": "Moderate Risk"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Blocked Volume",
                "compliance": "Compliance",
                "deliverability": "Deliverability",
                "deliverable": "Deliverable",
                "deliverable_volume": "Deliverable Volume",
                "ip_addresses": "IP Addresses",
                "senders": "Senders",
                "volume": "Email Volume"
            },
            "categories": {
                "all": "All Senders",
                "authorised_compliant": "Approved",
                "forwarder": "Forwarder",
                "suspicious": "Suspicious",
                "unauthorised": "Unapproved",
                "unknown": "None"
            },
            "menu": {
                "compliance": "Compliance",
                "deliverability": "Deliverability",
                "reputation": "Reputation",
                "threats": "Threats"
            },
            "messages": {
                "category_updated": {
                    "error": "Failed to Update Sender Category",
                    "success": "Sender Category Updated"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Blacklisted",
                    "clean": "Clean",
                    "compliant": "Compliant",
                    "delivered": "Delivered",
                    "failing_dmarc": "Failing DMARC",
                    "forward": "Forwards",
                    "not_delivered": "Not Delivered",
                    "overridden": "Overridden",
                    "overridden_as_delivered": "Overridden As Delivered",
                    "overridden_as_not_delivered": "Overridden As Not Delivered",
                    "passing": "Passing",
                    "passing_dkim": "Passing DKIM Only",
                    "passing_dkim_spf": "Passing DKIM & SPF",
                    "passing_dmarc": "Passing DMARC",
                    "passing_spf": "Passing SPF Only",
                    "senders": "Senders"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Approved Sender IPs",
                        "approved_sender_volume": "Approved Sender Volume",
                        "blacklisted": "Blacklisted",
                        "blacklists": "Blacklists",
                        "blocked_volume": "Blocked Volume",
                        "category": "Category",
                        "clean": "Clean",
                        "compliance": "Compliance",
                        "country": "Country",
                        "deliverability": "Deliverability",
                        "delivered": "Delivered",
                        "failing": "Failing",
                        "forwards": "Forwards",
                        "host": "Host",
                        "ip": "IP",
                        "ip_addresses": "IP Addresses",
                        "not_delivered": "Not Delivered",
                        "passing": "Passing",
                        "sender": "Sender",
                        "top_blacklisted_senders": "Top Blacklisted Senders",
                        "volume": "Volume"
                    },
                    "info": {
                        "blacklists": "Amount of Blacklists the IP Address has been listed in"
                    }
                }
            },
            "title": "Email Senders for :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Add New SPF Directive",
                "edit_record": {
                    "tooltip": "Edit Record"
                }
            },
            "create_drawer": {
                "header": "Add New SPF Directive"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directives",
                        "order": "Order"
                    }
                }
            },
            "edit_drawer": {
                "header": "Edit SPF Directive"
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "host": {
                    "example": "e.g. _spf.example.com",
                    "input_label": ":type Value"
                },
                "mechanism": {
                    "input_label": "Record Type"
                },
                "qualifier": {
                    "input_label": "Record Qualifier",
                    "options": {
                        "allow": "\"+\" Pass (Not Recommended)",
                        "fail": "\"-\" Fail",
                        "neutral": "\"?\" Neutral (Not Recommended)",
                        "soft_fail": "\"~\" Soft Fail"
                    }
                },
                "update": "Update an Spf Directive",
                "use_own_domain": {
                    "input_label": "Mechanism Domain"
                }
            },
            "import_modal": {
                "action": "Remove & Import",
                "complete": {
                    "message": "SPF Import Completed"
                },
                "header": "Import Existing SPF Record & Directives for :domain",
                "message": "All existing directives will be removed and replaced with imported directives."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Top Level Record Qualifier",
                        "options": {
                            "+": "\"+\" Pass (Not Recommended)",
                            "-": "\"-\" Fail",
                            "?": "\"?\" Neutral (Not Recommended)",
                            "~": "\"~\" Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "\"+all\" Set to pass authentication of emails from senders that are not authorized.",
                            "-": "Set to fail authentication of emails from senders that are not authorized.",
                            "?": "Set to neutral, which neither passes nor fails authentication of emails from senders that are not authorized.",
                            "~": "Set to soft fail authentication of emails from senders that are not authorized and treat them as suspicious."
                        }
                    },
                    "flattening": {
                        "input_label": "Enable Flattening"
                    }
                },
                "heading": "Settings"
            },
            "title": "Edit Settings - SPF"
        },
        "tables": {
            "clear": "Clear",
            "not_found": "No Matching Records Found",
            "search": "Search"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configure TLS-RPT Settings",
                    "description": "We’ve not yet received reporting data for your domain, it can take up to 72hours for data to appear. TLS-RPT Reports can only be retrieved for domains that have TLS-RPT Reporting enabled.",
                    "header": "No Reporting Data Available"
                }
            },
            "heading": "TLS-RPT reports for :domain",
            "reporters": {
                "heading": "Reports",
                "legend": {
                    "reports": "Reports"
                },
                "table": {
                    "header": {
                        "policy_mode": "Policy Mode",
                        "policy_type": "Policy Type",
                        "reporter": "Reporter",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Timeline",
                "legend": {
                    "failure": "Failed",
                    "success": "Successful"
                }
            },
            "title": "TLS-RPT reports"
        },
        "tls_settings": {
            "disable": {
                "action": "Disable TLS-RPT Reporting",
                "message": "It is recommended that TLS-RPT Reporting is enabled to monitor reporting.",
                "modal": {
                    "header": "Disabled TLS-RPT Reporting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable TLS-RPT Reporting",
                "heading": "TLS-RPT Reporting is Disabled",
                "message": "It is recommended that TLS-RPT Reporting is enabled to monitor reporting.",
                "modal": {
                    "header": "Enable TLS-RPT Reporting for :domain",
                    "message": "By enabling TLS-RPT reporting you can monitor statistics and information about failures with recipient domains."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Reports Will be Sent to"
                }
            },
            "heading": "TLS-RPT Reporting",
            "title": "Edit Settings - TLS-RPT"
        },
        "upload": {
            "add": "Add Files",
            "drop_zone": "Drag and drop file here to upload.",
            "title": "Upload"
        },
        "users": {
            "email": {
                "edit": "Edit Email",
                "placeholder": "Enter email address"
            },
            "menu": "Users",
            "name": {
                "title": "Name"
            },
            "title": "Manage Users"
        },
        "validation": {
            "email": "Not a valid email address",
            "invalid_attr": "The :attribute is not valid."
        }
    },
    "es": {
        "alerts": {
            "actions": {
                "create_activate": "Crear y activar",
                "edit_alert": {
                    "tooltip": "Editar alerta"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuración",
                    "mail_senders": "Remitentes de correo",
                    "none": "Ninguno",
                    "reports": "Informes",
                    "status": "Estado"
                },
                "description": "Seleccione la actividad que desea monitorear.",
                "heading": "Actividad",
                "options": {
                    "customer-created": {
                        "description": "Notificarme cuando se crea una cuenta.",
                        "heading": "Cliente creado"
                    },
                    "customer-deleted": {
                        "description": "Notificarme cuando se crea una cuenta.",
                        "heading": "Cliente eliminado"
                    },
                    "customer-exposure-report": {
                        "description": "Programe cuándo le gustaría recibir el Informe de exposición.",
                        "heading": "Informe de exposición"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Programe el certificado de cumplimiento de DMARC cuando la política DMARC esté configurada en Rechazar.",
                        "heading": "Certificado de cumplimiento DMARC en caso de rechazo"
                    },
                    "dns-delegation-unverified": {
                        "description": "Notificarme cuando falle la nueva verificación de la delegación de DNS.",
                        "heading": "Error en la nueva verificación de configuración"
                    },
                    "dns-delegation-verified": {
                        "description": "Notificar dónde ha cambiado el estado de verificación",
                        "heading": "Estado de verificación cambiado"
                    },
                    "domain-compliance-drop": {
                        "description": "Notificarme cuando el cumplimiento caiga por debajo de un umbral.",
                        "heading": "Cumplimiento abandonado"
                    },
                    "domain-created": {
                        "description": "Notificarme cuando se agregue un nuevo dominio.",
                        "heading": "Dominio creado"
                    },
                    "domain-deleted": {
                        "description": "Notificarme cuando se elimine un dominio.",
                        "heading": "Dominio eliminado"
                    },
                    "domain-score-change": {
                        "description": "Notificarme cuando la puntuación de cumplimiento DMARC haya cambiado.",
                        "heading": "Se modificó la puntuación de cumplimiento de DMARC"
                    },
                    "domain-settings-change": {
                        "description": "Notificarme cuando se cambie la configuración.",
                        "heading": "Cambios en la configuración"
                    },
                    "sender-threat-level-change": {
                        "description": "Notificarme cuando aumente el nivel de amenaza para remitentes nuevos o existentes.",
                        "heading": "Nivel de amenaza aumentado"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Notificarme cuando se detecte un remitente nuevo y comúnmente aprobado.",
                        "heading": "Remitente normalmente autorizado encontrado"
                    }
                }
            },
            "create": {
                "title": "Crear alerta"
            },
            "edit": {
                "title": "Editar alerta"
            },
            "email": {
                "description": "Especifique el destinatario del correo electrónico al que desea notificar y personalice el correo electrónico.",
                "heading": "Correo electrónico"
            },
            "email_settings": {
                "heading": "Personalizar correo electrónico"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Cliente",
                        "domain": "Dominio"
                    }
                },
                "frequency": {
                    "input_label": "Frecuencia"
                },
                "recipients": {
                    "input_label": "Destinatarios"
                },
                "scope": {
                    "input_label": "Nivel de seguimiento",
                    "listing-title": ":scope Actividad",
                    "options": {
                        "customer": "Cliente",
                        "partner": "Pareja",
                        "system": "Sistema"
                    }
                },
                "select_below": {
                    "description": "Verificación de configuración",
                    "input_label": "Seleccione a continuación"
                },
                "start_date": {
                    "input_label": "Fecha de inicio"
                },
                "subject_tags": {
                    "example": "p.ej. [gravedad-alerta][cuenta][paquete][dominio]",
                    "input_label": "Habilitar prefijos de categorización de líneas de asunto"
                }
            },
            "frequency": {
                "description": "Elija con qué frecuencia desea recibir esta notificación.",
                "heading": "Frecuencia"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alerta",
                        "frequency": "Frecuencia",
                        "recipients": "Destinatarios",
                        "status": "Estado"
                    },
                    "record": {
                        "alert_activity": ":scope Actividad"
                    }
                },
                "title": "Alertas"
            },
            "monitor": {
                "description": "Seleccione la actividad que desea monitorear.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "A diario",
                        "fortnightly": "Quincenal",
                        "hourly": "Cada hora",
                        "immediate": "Inmediato",
                        "monthly": "Mensual",
                        "quarterly": "Trimestral",
                        "six_monthly": "Semestral",
                        "two_monthly": "Dos meses",
                        "weekly": "Semanalmente",
                        "yearly": "Anual"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "La alerta está inactiva",
                        "value": "apagado"
                    },
                    "on": {
                        "tooltip": "La alerta está activa",
                        "value": "en"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Deshabilitar el alojamiento de registros BIMI",
                "heading": "El alojamiento de registros BIMI está actualmente habilitado",
                "message": "Se recomienda que BIMI Hosting esté habilitado para administrar fácilmente su logotipo y certificado.",
                "modal": {
                    "header": "Deshabilitar el alojamiento de registros BIMI para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio; esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar alojamiento de registros BIMI",
                "heading": "El alojamiento BIMI está deshabilitado",
                "message": "Se recomienda que BIMI Hosting esté habilitado para administrar su Logotipo y Certificado.",
                "modal": {
                    "header": "Habilitar alojamiento de registros BIMI :domain",
                    "message": "Al habilitar BIMI Hosting, puede administrar fácilmente su logotipo y certificado."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificado CN",
                    "expiry": "Caducidad del certificado",
                    "input_label": "Certificado de marca verificada BIMI (Tipo de archivo: pem)",
                    "url": "URL de autoridad\/certificado"
                },
                "image": {
                    "input_label": "Logotipo BIMI (Tipo de archivo: svg)",
                    "preview": "Vista previa del logotipo",
                    "size": "Tamaño del logotipo",
                    "url": "URL del logotipo"
                }
            },
            "info": {
                "heading": "¿Qué es BIMI?",
                "message": "BIMI (Indicadores de marca para la identificación de mensajes) es un estándar que facilita que su logotipo se muestre junto a sus mensajes en la bandeja de entrada, previene correos electrónicos fraudulentos y facilita la entrega. BIMI trabaja junto con DMARC, DKIM y SPF para verificar la autenticidad de un correo electrónico y ayudar con la visibilidad de la marca."
            },
            "settings": {
                "heading": "Ajustes"
            },
            "title": "Editar configuración - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Volver a configuración",
                "share": "Compartir instrucciones de configuración",
                "verify": "Verificar",
                "view": "Ver instrucciones de configuración"
            },
            "bimi": {
                "description": "Actualice su DNS para permitir la configuración de los registros BIMI de su dominio."
            },
            "description": "Para habilitar el monitoreo y la administración a través de DMARC Manager, implemente los siguientes cambios en la configuración de DNS de su dominio.",
            "dkim": {
                "description": "Actualice su DNS para permitir la configuración de las claves públicas DKIM de su dominio."
            },
            "dmarc": {
                "description": "Actualice su DNS para permitir la configuración de la política DMARC de su dominio y capturar informes sobre la actividad de autenticación de correo electrónico.",
                "warning": "Tome nota de los guiones bajos requeridos (_) en el host y los puntos en el valor del registro."
            },
            "existing_dns_record_found": "Registro :record existente encontrado",
            "heading": ":title Instrucciones de configuración",
            "instructions": {
                "add": "Agregar registro :rdType",
                "heading": ":rdType Instrucciones de configuración",
                "new": "Paso 2: Agregar nuevo registro :rdType",
                "remove": "Paso 1: eliminar el registro :rdType existente"
            },
            "mta_policy": {
                "description": "Actualice su DNS para permitir la configuración de la Política MTA de su dominio.",
                "heading": "Política MTA-STS"
            },
            "mta_record": {
                "description": "Actualice su DNS para permitir la configuración del Registro MTA de su dominio.",
                "heading": "Registro MTA-STS"
            },
            "record": {
                "host": "Anfitrión",
                "type": "Tipo de registro",
                "value": "Valor"
            },
            "spf": {
                "description": "Actualice su DNS para permitir la configuración del registro SPF de su dominio."
            },
            "status": {
                "last_date": "Última verificación el",
                "pending": "Pendiente de verificación",
                "verified": "Verificado"
            },
            "tls_rpt": {
                "description": "Actualice su DNS para permitir la configuración del registro TLS-RPT de su dominio y capturar informes.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Administrar clientes"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Volumen de correo electrónico",
                    "legends": {
                        "approved": "Aprobado",
                        "unapproved": "No aprobados y otros"
                    }
                },
                "domain_totals": {
                    "action": "Ver Dominios",
                    "enforced": "aplicado",
                    "heading": "Dominios",
                    "reporting": "Informes",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "Ver remitentes",
                    "heading": "Remitentes",
                    "legends": {
                        "approved": "Aprobado",
                        "unapproved": "No aprobado"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Ver todos los dominios"
                },
                "legends": {
                    "failing": "DMARC fallido",
                    "forwards": "Hacia adelante",
                    "passing": "Pasando DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "Ver informe"
                    },
                    "header": {
                        "approved": "Aprobado",
                        "domain": "Dominios",
                        "senders": "Remitentes",
                        "unapproved": "No aprobado",
                        "volume": "Volumen"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Ver informe de dominio"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Cumplimiento",
                        "domain": "Dominio",
                        "sender": "Remitentes aprobados por volumen",
                        "volume": "Volumen"
                    }
                }
            },
            "title": "Panel"
        },
        "delegation-verifications": {
            "verify": {
                "error": "Verificación fallida",
                "success": "Verificación completada con éxito"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Agregar nueva clave pública",
                "edit_public_key": {
                    "tooltip": "Editar clave pública"
                }
            },
            "create_drawer": {
                "header": "Agregar nueva clave pública"
            },
            "disable": {
                "action": "Gestión DKIM deshabilitada",
                "heading": "El alojamiento de clave pública DKIM está actualmente habilitado",
                "message": "Se recomienda que la administración DKIM esté habilitada para ayudarlo a administrar sus claves públicas.",
                "modal": {
                    "header": "Deshabilitar la administración DKIM para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio; esta acción no se puede deshacer."
                }
            },
            "edit_drawer": {
                "header": "Editar clave pública"
            },
            "enable": {
                "action": "Habilitar la gestión DKIM",
                "heading": "El alojamiento de clave pública DKIM está deshabilitado",
                "message": "Se recomienda que la administración DKIM esté habilitada para ayudarlo a administrar sus claves públicas.",
                "modal": {
                    "header": "Habilitar la gestión DKIM para :domain",
                    "message": "Al habilitar la administración DKIM, puede administrar sus claves públicas con facilidad."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Descripción"
                },
                "record_type": {
                    "input_label": "Tipo de registro"
                },
                "record_value": {
                    "example": "p.ej. dkim.ejemplo.com",
                    "input_label": "Valor de registro"
                },
                "selector": {
                    "input_label": "Selector"
                }
            },
            "import_modal": {
                "action": "Eliminar e importar",
                "complete": {
                    "message": "Importación de clave pública DKIM completada"
                },
                "disabled_tooltip": "Las claves públicas DKIM solo se pueden importar para dominios que tengan informes.",
                "header": "Volver a importar claves públicas DKIM para :domain",
                "message": "Todas las claves públicas existentes se eliminarán y reemplazarán con directivas importadas."
            },
            "info": {
                "heading": "¿Qué es DKIM?",
                "message": "El correo identificado con claves de dominio es un método de autenticación de correo electrónico que permite a un remitente el correo identificado con claves de dominio es un método de autenticación de correo electrónico que permite a un remitente firmar digitalmente un correo electrónico utilizando una clave privada asociada con su nombre de dominio, que puede ser verificada por el correo del destinatario. servidor utilizando una clave pública publicada en los registros DNS del remitente. Esto ayuda a verificar la autenticidad e integridad del correo electrónico, reduciendo el riesgo de spam y ataques de phishing."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Política de firma saliente",
                        "options": {
                            "all": "Todos los correos electrónicos",
                            "some": "Algunos correos electrónicos"
                        }
                    },
                    "testing": {
                        "input_label": "Modo de prueba"
                    }
                },
                "heading": "Configuración de políticas"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Tipo de registro",
                        "selector": "Selector",
                        "value": "Valor"
                    }
                }
            },
            "title": "Editar configuración - DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "Modo de alineación DKIM"
                    },
                    "options": {
                        "r": "Relajado",
                        "s": "Estricto"
                    },
                    "spf_mode": {
                        "input_label": "Modo de alineación SPF"
                    }
                },
                "heading": "Configuración de alineación"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Aplicar una política de subdominio diferente"
                    },
                    "percentage": {
                        "input_label": "Porcentaje"
                    },
                    "policy": {
                        "input_label": "Política DMARC"
                    },
                    "subdomain_policy": {
                        "input_label": "Política de subdominio"
                    }
                },
                "heading": "Configuración de políticas"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Los informes se enviarán a"
                    },
                    "interval": {
                        "input_label": "Intervalo de informe",
                        "options": {
                            "1_day": "Una vez al día",
                            "1_hour": "Cada hora",
                            "2_hours": "Cada 2 horas",
                            "4_hours": "Cada 4 Horas",
                            "6_hours": "Cada 6 horas"
                        }
                    }
                },
                "heading": "Informes agregados"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Permitir",
                    "header": "Habilitar informes de errores",
                    "message": "Al habilitar el informe de errores, cierta información personal, como direcciones de correo electrónico o contenido de mensajes, puede quedar expuesta con fines de diagnóstico.",
                    "reject": "Desactivar"
                },
                "fields": {
                    "options": {
                        "input_label": "Opciones de informe",
                        "options": {
                            "d": "DKIM falla independientemente de la alineación",
                            "s": "El SPF falla independientemente de la alineación",
                            "0": "SPF o DKIM fallan y no se alinean",
                            "1": "SPF y DKIM fallan y no se alinean"
                        }
                    },
                    "status": {
                        "input_label": "Procesamiento de informes",
                        "options": {
                            "disabled": "Desactivado",
                            "enabled": "Activado",
                            "specified": "Destinatarios específicos"
                        }
                    }
                },
                "heading": "Informes de fallas"
            },
            "title": "Editar configuración - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "Certificado de cumplimiento DMARC",
                "domain_report": "Informe de dominio",
                "edit_settings": {
                    "tooltip": "Editar configuración"
                },
                "remove_primary": "Quitar de Favorito",
                "set_as_active": "Establecer como activo",
                "set_as_parked": "Establecer como estacionado",
                "set_primary": "Marcar como favorito",
                "view_logs": "Ver registros"
            },
            "create_drawer": {
                "header": "Agregar nuevo dominio"
            },
            "fields": {
                "name": {
                    "example": "p.ej. 'ejemplo.com' o 'subdominio.ejemplo.com'",
                    "input_label": "Nombre de dominio"
                },
                "type": {
                    "input_label": "Tipo de dominio",
                    "options": {
                        "active": "Activo",
                        "parked": "estacionado"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Dominio",
                        "policy": "Política",
                        "score": "Puntaje",
                        "status": "Estado",
                        "type": "Tipo"
                    }
                },
                "title": "Dominios"
            },
            "logs_drawer": {
                "header": "Dominio"
            },
            "report_modal": {
                "action": "Generar enlace",
                "fields": {
                    "date_range": {
                        "input_label": "Rango de fechas"
                    },
                    "expire_link": {
                        "input_label": "Caducar enlace en",
                        "options": {
                            "1_day": "Un día",
                            "1_month": "un mes",
                            "1_week": "una semana",
                            "6_hours": "6 horas",
                            "12_hours": "12 horas",
                            "never": "Nunca"
                        }
                    }
                },
                "header": "Informe de dominio"
            },
            "settings": {
                "title": "Editar configuración"
            },
            "setup_modal": {
                "action": "Generar enlace",
                "fields": {
                    "copy_link": {
                        "input_label": "Enlace a las instrucciones de configuración"
                    },
                    "expire_link": {
                        "input_label": "Caducar enlace en",
                        "options": {
                            "1_day": "Un día",
                            "1_month": "un mes",
                            "1_week": "una semana",
                            "6_hours": "6 horas",
                            "12_hours": "12 horas",
                            "never": "Nunca"
                        }
                    }
                },
                "header": "Compartir instrucciones de configuración"
            },
            "tags": {
                "policy": {
                    "tooltip": "Política aplicada"
                },
                "primary": {
                    "tooltip": "Favorito",
                    "value": "Favorito"
                },
                "score": {
                    "high": {
                        "tooltip": "Alto riesgo"
                    },
                    "low": {
                        "tooltip": "Bajo riesgo"
                    },
                    "moderate": {
                        "tooltip": "Riesgo moderado"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verificado, sin informar",
                        "tooltip_partial": "Parcialmente verificado, sin informar",
                        "value": "No informar"
                    },
                    "not_verified": {
                        "tooltip": "No verificado, no reportado",
                        "value": "No verificado"
                    },
                    "reporting": {
                        "tooltip": "Verificado, Informes",
                        "tooltip_partial": "Parcialmente verificado, informes",
                        "value": "Informes"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Dominio activo",
                        "value": "Activo"
                    },
                    "parked": {
                        "tooltip": "Dominio estacionado",
                        "value": "estacionado"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "p.ej. destinatario@ejemplo.com",
                    "input_label": "Editar correo electrónico",
                    "placeholder": "Ingrese la dirección de correo electrónico",
                    "subscribed_label": "suscrito",
                    "unsubscribed_label": "Cancelar suscripción",
                    "validation_message": "Ingrese una dirección de correo electrónico válida"
                }
            }
        },
        "errors": {
            "menu": "Error",
            "401": {
                "description": "Lo sentimos, no estás autorizado a acceder a esta página.",
                "title": "401: no autorizado"
            },
            "403": {
                "description": "Lo sentimos, tienes prohibido acceder a esta página.",
                "title": "403: Prohibido"
            },
            "404": {
                "description": "Lo sentimos, no se pudo encontrar la página que estás buscando.",
                "title": "404: Página no encontrada"
            },
            "500": {
                "description": "Vaya, algo salió mal en nuestros servidores.",
                "title": "500: Error del servidor"
            },
            "502": {
                "description": "Lo sentimos, nuestros servidores están caídos.",
                "title": "502 Puerta de enlace no válida"
            },
            "503": {
                "description": "Lo sentimos, estamos haciendo algo de mantenimiento. Vuelva a consultar pronto.",
                "title": "503: Servicio no disponible"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Volumen de correo electrónico",
                "legends": {
                    "failing": "DMARC fallido",
                    "forward": "Hacia adelante",
                    "passing": "Pasando DMARC"
                }
            },
            "heading": "Informe de dominio",
            "recommendations": {
                "heading": "Recomendaciones"
            },
            "risk_analysis": {
                "heading": "Análisis de riesgos"
            },
            "scoring": {
                "note": "Nota: La puntuación del dominio se calcula al momento de generar el informe y es posible que no esté actualizada al momento de verlo."
            },
            "sender_readiness": {
                "heading": "Preparación de DMARC del remitente",
                "legends": {
                    "configured": {
                        "description": "La preparación de DMARC es una medida de la configuración necesaria para implementar el cumplimiento total.",
                        "heading": "Configurado"
                    },
                    "partially": {
                        "description": "Las fuentes parcialmente configuradas tienen al menos un mecanismo de autorización activo; sin embargo, es posible que sea necesaria una configuración adicional.",
                        "heading": "Parcialmente configurado"
                    },
                    "unconfigured": {
                        "description": "Fuentes ilegítimas o no configuradas son fuentes que envían correo electrónico desde su dominio y que pueden estar suplantando su marca.",
                        "heading": "Ilegítimo o no configurado"
                    }
                }
            },
            "title": "Informe de dominio para :domain",
            "top_countries": {
                "heading": "Principales países de origen: volumen de correo electrónico"
            },
            "top_senders": {
                "heading": "Principales remitentes: Cumplimiento"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Descargar Informe",
                "header": "Informe de falla",
                "menu": {
                    "key_properties": "Propiedades clave",
                    "more_info": "Más información"
                }
            },
            "properties": {
                "authentication_failure": "Fallo de autenticación",
                "date": "Fecha",
                "delivery_results": "Resultados de entrega",
                "dkim_domain": "Dominio DKIM",
                "dkim_header": "Encabezado canonicalizado DKIM",
                "dkim_identity": "Identidad DKIM",
                "dkim_selector": "Selector DKIM",
                "dmarc_version": "Versión DMARC",
                "feedback_type": "Tipo de comentario",
                "incidents": "Número de incidentes",
                "original_recipients": "Destinatarios originales",
                "reporting_domains": "Dominios reportados",
                "reporting_mta": "MTA de informes",
                "reporting_user_agent": "Agente de usuario que informa",
                "return_path": "Camino de regreso",
                "source_ip": "Dirección IP de origen",
                "spf_dns": "DNS SPF"
            },
            "table": {
                "header": {
                    "authentication": "Autenticación",
                    "date": "Fecha",
                    "ip_address": "Dirección IP",
                    "result": "Resultado",
                    "return_path": "Camino de regreso"
                }
            },
            "title": "Informes de fallas para :domain"
        },
        "global": {
            "buttons": {
                "add": "Agregar",
                "add_new": "Agregar nuevo",
                "cancel": "Cancelar",
                "delete": "Borrar",
                "import": "Importar",
                "next": "Próximo",
                "no": "No",
                "remove": "Eliminar",
                "save": "Ahorrar",
                "save_exit": "Guardar y regresar",
                "select": "Seleccionar",
                "yes": "Sí"
            },
            "confirm_delete": "¿Quieres continuar?",
            "copied": "Copiado",
            "created": "Creado",
            "deleted": "Eliminado",
            "fail": "Fallar",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Desactivado",
                        "enabled": "Activado"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Apagado",
                        "on": "En"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "No",
                        "yes": "Sí"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Error: complete todos los campos obligatorios correctamente.",
                    "title": "Hubo errores con su formulario."
                },
                "success": {
                    "title": "Tus cambios fueron guardados."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volumen"
                }
            },
            "logout": "Cerrar sesión",
            "not_found": "Extraviado",
            "pass": "Aprobar",
            "remove": "Eliminar",
            "show_less": "Mostrar menos",
            "show_more": "Mostrar más",
            "system": "Sistema",
            "theme": {
                "dark": "Oscuro",
                "light": "Luz"
            },
            "to": "A"
        },
        "ip-address-modal": {
            "menu": {
                "overrides": "Anulaciones",
                "overview": "Descripción general",
                "reporters": "Reporteros",
                "results": "Resultados",
                "spf_result": "Resultado del FPS"
            },
            "overrides": {
                "breakdown": {
                    "policy_applied_none": "Política aplicada 'Ninguna'",
                    "policy_applied_quarantine": "Política aplicada 'Cuarentena'",
                    "policy_applied_reject": "Política aplicada 'Rechazar'"
                },
                "overridden_policy_breakdown": "Desglose de políticas anuladas",
                "table": {
                    "override_comment": "Comentario",
                    "override_type": "Tipo de anulación",
                    "reporter": "Reportero"
                },
                "volume_overridden_policy": "Volumen con políticas anuladas"
            },
            "overview": {
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "detail": {
                    "country": "País",
                    "host": "ANFITRIÓN",
                    "isp": "ISP",
                    "organisation": "Organización",
                    "sender": "Remitente"
                },
                "volume": "Volumen"
            },
            "reporters": {
                "table": {
                    "compliance": "Cumplimiento",
                    "envelope_from": "Sobre de",
                    "envelope_to": "Sobre a",
                    "header_from": "Encabezado desde",
                    "percent_of_total": "% del total",
                    "reporter": "Reportero",
                    "volume": "Volumen"
                }
            },
            "results": {
                "legend": {
                    "align": "Alineación",
                    "error": "Error",
                    "failed": "Fallido\/No alineado",
                    "none": "Ninguno",
                    "passed": "Aprobado\/Alineado",
                    "pass_fail": "Pasa\/falla"
                },
                "table": {
                    "action_applied": "Acción aplicada",
                    "action_taken": "Acción tomada por el receptor",
                    "alignment": "Alineación",
                    "compliance": "Cumplimiento",
                    "delivered": "Entregado",
                    "dkim_aligned": "DKIM alineado",
                    "dkim_details": "Detalles de DKIM",
                    "dkim_failed": "DKIM falló",
                    "dkim_none": "DKIM Ninguno",
                    "dkim_not_aligned": "DKIM no alineado",
                    "dkim_no_info": "Sin información DKIM",
                    "dkim_passed": "DKIM Aprobado",
                    "dkim_results": "Resultados DKIM",
                    "dmarc_failed": "DMARC falló",
                    "dmarc_passed": "DMARC aprobado",
                    "dmarc_results": "Resultados DMARC",
                    "dmarc_via_dkim": "DMARC a través de DKIM",
                    "dmarc_via_spf": "DMARC a través de SPF",
                    "envelope_from": "Sobre de",
                    "envelope_to": "Sobre a",
                    "from_domain": "Desde dominio",
                    "header_from": "Encabezado desde",
                    "none": "Ninguno",
                    "no_dkim_details": "Sin detalles de firma DKIM",
                    "no_spf_details": "Sin detalles de SPF",
                    "other_details": "Otros detalles",
                    "percent_of_total": "% del total",
                    "policy_applied": "Política aplicada",
                    "policy_of_applied": "El receptor aplicó la política de ':policy'",
                    "published_policy": "Política publicada",
                    "quarantine": "Cuarentena",
                    "reject": "Rechazar",
                    "reporter": "Reportero",
                    "result": "Resultado",
                    "return_path_domain": "Dominio de ruta de retorno",
                    "selector": "Selector",
                    "signing_domain": "Dominio de firma",
                    "spf_aligned": "SPF alineado",
                    "spf_details": "Detalles del FPS",
                    "spf_fail": "Fallo del SPF",
                    "spf_none": "SPF Ninguno",
                    "spf_not_aligned": "SPF no alineado",
                    "spf_no_info": "Sin información SPF",
                    "spf_pass": "Pase SPF",
                    "spf_results": "Resultados del FPS",
                    "spf_tmp_error": "Error temporal del SPF",
                    "validated_dmarc_policy": "Política de Dmarc en el momento de la validación.",
                    "volume": "Volumen"
                }
            }
        },
        "logs": {
            "events": {
                "created": "creado",
                "deleted": "eliminado",
                "restored": "restaurado",
                "updated": "actualizado"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificado",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegación habilitada",
                        "nameservers": "Servidores de nombres",
                        "selector": "Selector"
                    },
                    "title": "Configuración BIMI :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Descripción",
                        "record_type": "Tipo de registro",
                        "selector": "Selector",
                        "status": "Estado",
                        "ttl": "TTL",
                        "value": "Valor"
                    },
                    "title": "Clave pública DKIM :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegación habilitada",
                        "is_policy_enabled": "Política habilitada",
                        "nameservers": "Servidores de nombres",
                        "policy": "Política"
                    },
                    "title": "Configuración DKIM :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "Alineación DKIM",
                        "policy": "Política",
                        "policy_percentage": "Porcentaje de póliza",
                        "rua_emails": "Destinatarios del informe agregado",
                        "rua_interval": "Intervalo de informe agregado",
                        "ruf_emails": "Destinatarios del informe de fallas",
                        "ruf_format": "Formato de informe de fallas",
                        "ruf_options": "Opciones de informe de fallas",
                        "ruf_status": "Estado del informe de fallas",
                        "spf_alignment": "Alineación SPF",
                        "subdomain_policy": "Política de subdominio"
                    },
                    "title": "Configuración DMARC :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Mensajes",
                        "result": "Resultado",
                        "started_at": "Comenzó en",
                        "status": "Estado",
                        "type": "Tipo"
                    },
                    "title": "Verificación de delegación DNS :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Dominio",
                        "is_favorite": "Favorito",
                        "logo": "Logo",
                        "score": "Puntuación de dominio",
                        "type": "Tipo"
                    },
                    "title": "Dominio :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_policy_hosting_enabled": "Alojamiento de políticas habilitado",
                        "is_record_enabled": "Alojamiento de registros habilitado",
                        "mx_records": "Registros MX",
                        "policy": "Modo de política"
                    },
                    "title": "Configuración MTA-STS :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Descripción",
                        "mechanism": "Tipo de registro",
                        "order": "Orden",
                        "qualifier": "Clasificatorio récord",
                        "status": "Estado",
                        "value": "Valor del anfitrión"
                    },
                    "title": "Directivas SPF :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Clasificatorio récord de alto nivel",
                        "enable_flattening": "Aplanamiento habilitado"
                    },
                    "title": "Configuración de SPF :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT habilitado",
                        "rua_emails": "Informar correos electrónicos"
                    },
                    "title": "Configuración TLS-RPT :event"
                }
            },
            "header": ":type registros"
        },
        "menu": {
            "domains": "Dominios",
            "home": "Panel",
            "reports": {
                "email_senders": "Remitentes de correo electrónico",
                "failure_reports": "Informes de fallas",
                "reports": "Informes",
                "tls_rpt_reports": "Informes TLS-RPT"
            },
            "settings": {
                "alerts": "Alertas",
                "logout": "Cerrar sesión",
                "settings": "Ajustes"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Alojamiento MTA-STS deshabilitado",
                "heading": "El alojamiento MTA-STS está habilitado",
                "message": "Se recomienda que los informes TLS-RPT y el alojamiento de políticas MTA-STS estén habilitados.",
                "modal": {
                    "header": "Deshabilitar el alojamiento MTA-STS para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio; esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar alojamiento MTA-STS",
                "heading": "El alojamiento MTA-STS está deshabilitado",
                "message": "Se recomienda que el alojamiento de políticas y registros de MTA-STS esté habilitado para administrar sus registros de manera efectiva.",
                "modal": {
                    "header": "Habilite el alojamiento MTA-STS para :domain",
                    "message": "Al habilitar MTA-STS Hosting, puede administrar su registro y el alojamiento de su póliza."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "Servidores MX"
                },
                "policy": {
                    "input_label": "Nivel de aplicación de políticas",
                    "options": {
                        "enforce": "aplicado",
                        "none": "Ninguno",
                        "testing": "En pruebas"
                    }
                }
            },
            "heading": "Alojamiento de políticas y registros MTA-STS",
            "info": {
                "heading": "¿Qué TLS-RPT y MTA-STS?",
                "message": "TLS-RPT o SMTP TLS Reporting (Informes de seguridad de la capa de transporte) permite que los sistemas de envío compartan estadísticas e información sobre fallas con los dominios del destinatario. MTA-STS (Agentes de transferencia de correo: seguridad de transporte estricta) permite a los proveedores de servicios de correo declarar su capacidad para recibir conexiones SMTP seguras TLS."
            },
            "status": {
                "policy": {
                    "pending": "Póliza Pendiente de Verificación",
                    "verified": "Política verificada"
                },
                "record": {
                    "pending": "Registro pendiente de verificación",
                    "verified": "Registro verificado"
                }
            }
        },
        "partners": {
            "title": "Administrar socios"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configurar los ajustes DMARC",
                        "manage_domains": "Administrar dominios"
                    },
                    "description": "Aún no hemos recibido datos de informes para su dominio; los datos pueden tardar hasta 72 horas en aparecer. Los informes solo se pueden recuperar para dominios activos y verificados. Administre el resto de sus dominios yendo a la interfaz de Dominios.",
                    "header": "No hay datos de informes disponibles"
                },
                "no_reporting_data": {
                    "description": "No hay datos de informes disponibles para el intervalo de fechas seleccionado. Ajuste su selección e inténtelo de nuevo.",
                    "header": "No hay datos de informes disponibles"
                }
            }
        },
        "scoring": {
            "blurb": {
                "high": "No cuenta con controles efectivos para proteger su dominio contra\n              suplantación e interceptación de comunicaciones por correo electrónico. Esto pone tu\n              Los destinatarios de la marca y del correo electrónico corren el riesgo de sufrir ataques, reduce la confianza y puede\n              dañar la capacidad de entrega del correo electrónico.",
                "low": "El correo electrónico de su dominio es altamente seguro contra suplantación y\n              La interceptación y los destinatarios verán su marca en la comunicación. Estos\n              Las medidas generan confianza en su marca, protegen a los destinatarios de ataques y\n              ayudar a garantizar que el correo electrónico se entregue de forma eficaz.",
                "moderate": "Tiene algunas medidas implementadas para proteger a los destinatarios del correo electrónico malicioso.\n              desde tu dominio. Se debe prestar atención a las áreas donde se pueden realizar controles.\n              mejorado. Estas medidas generan confianza en su marca y protegen a los destinatarios\n              de ataques y ayudar a garantizar que el correo electrónico se entregue de manera efectiva."
            },
            "header": "Puntuación de dominio",
            "impersonation": "Interpretación",
            "marketing": "Marketing",
            "note": "NOTA: Esta es la puntuación de su dominio actual y es posible que no refleje los cambios que realizó hoy.",
            "privacy": "Privacidad",
            "rating": {
                "high": "Alto riesgo",
                "low": "Bajo riesgo",
                "moderate": "Riesgo moderado"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Volumen bloqueado",
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "deliverable_volume": "Volumen entregable",
                "ip_addresses": "Direcciones IP",
                "senders": "Remitentes",
                "volume": "Volumen de correo electrónico"
            },
            "categories": {
                "all": "Todos los remitentes",
                "authorised_compliant": "Aprobado",
                "forwarder": "Promotor",
                "suspicious": "Sospechoso",
                "unauthorised": "No aprobado",
                "unknown": "Ninguno"
            },
            "menu": {
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "reputation": "Reputación",
                "threats": "Amenazas"
            },
            "messages": {
                "category_updated": {
                    "error": "No se pudo actualizar la categoría del remitente",
                    "success": "Categoría de remitente actualizada"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "En la lista negra",
                    "clean": "Limpio",
                    "compliant": "Obediente",
                    "delivered": "Entregado",
                    "failing_dmarc": "DMARC fallido",
                    "forward": "Hacia adelante",
                    "not_delivered": "No entregado",
                    "overridden": "Anulado",
                    "overridden_as_delivered": "Anulado tal como se entregó",
                    "overridden_as_not_delivered": "Anulado como no entregado",
                    "passing": "Paso",
                    "passing_dkim": "Pasando solo DKIM",
                    "passing_dkim_spf": "Pasando DKIM y SPF",
                    "passing_dmarc": "Pasando DMARC",
                    "passing_spf": "Pasar solo SPF",
                    "senders": "Remitentes"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "IP de remitente aprobadas",
                        "approved_sender_volume": "Volumen de remitente aprobado",
                        "blacklisted": "En la lista negra",
                        "blacklists": "Listas negras",
                        "blocked_volume": "Volumen bloqueado",
                        "category": "Categoría",
                        "clean": "Limpio",
                        "compliance": "Cumplimiento",
                        "country": "País",
                        "deliverability": "Capacidad de entrega",
                        "delivered": "Entregado",
                        "failing": "Defecto",
                        "forwards": "Hacia adelante",
                        "host": "Anfitrión",
                        "ip": "IP",
                        "ip_addresses": "Direcciones IP",
                        "not_delivered": "No entregado",
                        "passing": "Paso",
                        "sender": "Remitente",
                        "top_blacklisted_senders": "Principales remitentes incluidos en la lista negra",
                        "volume": "Volumen"
                    },
                    "info": {
                        "blacklists": "Cantidad de listas negras en las que se ha incluido la dirección IP"
                    }
                }
            },
            "title": "Remitentes de correo electrónico para :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Agregar nueva directiva SPF",
                "edit_record": {
                    "tooltip": "Editar registro"
                }
            },
            "create_drawer": {
                "header": "Agregar nueva directiva SPF"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directivas",
                        "order": "Orden"
                    }
                }
            },
            "edit_drawer": {
                "header": "Editar directiva SPF"
            },
            "fields": {
                "description": {
                    "input_label": "Descripción"
                },
                "host": {
                    "example": "p.ej. _spf.ejemplo.com",
                    "input_label": "Valor :type"
                },
                "mechanism": {
                    "input_label": "Tipo de registro"
                },
                "qualifier": {
                    "input_label": "Clasificatorio récord",
                    "options": {
                        "allow": "Pase \"+\" (No recomendado)",
                        "fail": "\"-\" Fallar",
                        "neutral": "\"?\" Neutro (no recomendado)",
                        "soft_fail": "\"~\" Fallo suave"
                    }
                },
                "update": "Actualizar una directiva SPF",
                "use_own_domain": {
                    "input_label": "Dominio del mecanismo"
                }
            },
            "import_modal": {
                "action": "Eliminar e importar",
                "complete": {
                    "message": "Importación SPF completada"
                },
                "header": "Importar registros y directivas SPF existentes para :domain",
                "message": "Todas las directivas existentes serán eliminadas y reemplazadas por directivas importadas."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Clasificatorio récord de alto nivel",
                        "options": {
                            "+": "Pase \"+\" (No recomendado)",
                            "-": "\"-\" Fallar",
                            "?": "\"?\" Neutro (no recomendado)",
                            "~": "\"~\" Fallo suave"
                        },
                        "options_tooltips": {
                            "+": "\"+todos\" Configurado para pasar la autenticación de correos electrónicos de remitentes que no están autorizados.",
                            "-": "Configurado para fallar en la autenticación de correos electrónicos de remitentes que no están autorizados.",
                            "?": "Establecido en neutral, que no pasa ni falla la autenticación de correos electrónicos de remitentes que no están autorizados.",
                            "~": "Configure la autenticación de fallo suave de los correos electrónicos de remitentes que no están autorizados y trátelos como sospechosos."
                        }
                    },
                    "flattening": {
                        "input_label": "Habilitar aplanamiento"
                    }
                },
                "heading": "Ajustes"
            },
            "title": "Editar configuración - SPF"
        },
        "tables": {
            "clear": "Claro",
            "not_found": "No se encontraron registros coincidentes",
            "search": "Buscar"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configurar los ajustes de TLS-RPT",
                    "description": "Aún no hemos recibido datos de informes para su dominio; los datos pueden tardar hasta 72 horas en aparecer. Los informes TLS-RPT solo se pueden recuperar para dominios que tengan habilitados los informes TLS-RPT.",
                    "header": "No hay datos de informes disponibles"
                }
            },
            "heading": "Informes TLS-RPT para :domain",
            "reporters": {
                "heading": "Informes",
                "legend": {
                    "reports": "Informes"
                },
                "table": {
                    "header": {
                        "policy_mode": "Modo de política",
                        "policy_type": "Tipo de póliza",
                        "reporter": "Reportero",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Línea de tiempo",
                "legend": {
                    "failure": "Fallido",
                    "success": "Exitoso"
                }
            },
            "title": "Informes TLS-RPT"
        },
        "tls_settings": {
            "disable": {
                "action": "Deshabilitar informes TLS-RPT",
                "message": "Se recomienda habilitar los informes TLS-RPT para monitorear los informes.",
                "modal": {
                    "header": "Informes TLS-RPT deshabilitados para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio; esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar informes TLS-RPT",
                "heading": "Los informes TLS-RPT están deshabilitados",
                "message": "Se recomienda habilitar los informes TLS-RPT para monitorear los informes.",
                "modal": {
                    "header": "Habilitar informes TLS-RPT para :domain",
                    "message": "Al habilitar los informes TLS-RPT, puede monitorear estadísticas e información sobre fallas en los dominios de los destinatarios."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Los informes se enviarán a"
                }
            },
            "heading": "Informes TLS-RPT",
            "title": "Editar configuración: TLS-RPT"
        },
        "upload": {
            "add": "Agregar archivos",
            "drop_zone": "Arrastre y suelte el archivo aquí para cargarlo.",
            "title": "Subir"
        },
        "users": {
            "email": {
                "edit": "Editar correo electrónico",
                "placeholder": "Introduzca la dirección de correo electrónico"
            },
            "menu": "Usuarios",
            "name": {
                "title": "Nombre"
            },
            "title": "Administrar usuarios"
        },
        "validation": {
            "email": "No es una dirección de correo electrónico válida",
            "invalid_attr": "El :attribute no es válido."
        }
    },
    "fr": {
        "alerts": {
            "actions": {
                "create_activate": "Créer et activer",
                "edit_alert": {
                    "tooltip": "Modifier l'alerte"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuration",
                    "mail_senders": "Expéditeurs de courrier",
                    "none": "Aucun",
                    "reports": "Rapports",
                    "status": "Statut"
                },
                "description": "Sélectionnez l'activité que vous souhaitez surveiller.",
                "heading": "Activité",
                "options": {
                    "customer-created": {
                        "description": "M'avertir lorsqu'un compte est créé.",
                        "heading": "Client créé"
                    },
                    "customer-deleted": {
                        "description": "M'avertir lorsqu'un compte est créé.",
                        "heading": "Client supprimé"
                    },
                    "customer-exposure-report": {
                        "description": "Planifiez le moment où vous souhaitez recevoir le rapport d’exposition.",
                        "heading": "Rapport d'exposition"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Planifiez le certificat de conformité DMARC lorsque la stratégie DMARC est définie sur Rejeter.",
                        "heading": "Certificat de conformité DMARC en cas de rejet"
                    },
                    "dns-delegation-unverified": {
                        "description": "Avertissez-moi lorsque la re-vérification de la délégation DNS échoue.",
                        "heading": "Échec de la revérification de l'installation"
                    },
                    "dns-delegation-verified": {
                        "description": "Notifier où le statut de vérification a changé",
                        "heading": "Statut de vérification modifié"
                    },
                    "domain-compliance-drop": {
                        "description": "Avertissez-moi lorsque la conformité descend en dessous d'un seuil.",
                        "heading": "Conformité abandonnée"
                    },
                    "domain-created": {
                        "description": "Avertissez-moi lorsqu'un nouveau domaine est ajouté.",
                        "heading": "Domaine créé"
                    },
                    "domain-deleted": {
                        "description": "M'avertir lorsqu'un domaine est supprimé.",
                        "heading": "Domaine supprimé"
                    },
                    "domain-score-change": {
                        "description": "Avertissez-moi lorsque le score de conformité DMARC a changé.",
                        "heading": "Score de conformité DMARC modifié"
                    },
                    "domain-settings-change": {
                        "description": "Avertissez-moi lorsque les paramètres sont modifiés.",
                        "heading": "Modifications des paramètres"
                    },
                    "sender-threat-level-change": {
                        "description": "Avertissez-moi lorsque le niveau de menace augmente pour les expéditeurs nouveaux ou existants.",
                        "heading": "Niveau de menace augmenté"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Avertissez-moi lorsqu'un nouvel expéditeur généralement approuvé est détecté.",
                        "heading": "Expéditeur généralement autorisé trouvé"
                    }
                }
            },
            "create": {
                "title": "Créer une alerte"
            },
            "edit": {
                "title": "Modifier l'alerte"
            },
            "email": {
                "description": "Spécifiez le destinataire de l'e-mail que vous souhaitez informer et personnalisez l'e-mail.",
                "heading": "E-mail"
            },
            "email_settings": {
                "heading": "Personnaliser l'e-mail"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Client",
                        "domain": "Domaine"
                    }
                },
                "frequency": {
                    "input_label": "Fréquence"
                },
                "recipients": {
                    "input_label": "Destinataires"
                },
                "scope": {
                    "input_label": "Niveau de surveillance",
                    "listing-title": ":scope Activité",
                    "options": {
                        "customer": "Client",
                        "partner": "Partenaire",
                        "system": "Système"
                    }
                },
                "select_below": {
                    "description": "Vérification de la configuration",
                    "input_label": "Sélectionnez ci-dessous"
                },
                "start_date": {
                    "input_label": "Date de début"
                },
                "subject_tags": {
                    "example": "par ex. [alerte-gravité][compte][package][domaine]",
                    "input_label": "Activer les préfixes de catégorisation de la ligne d'objet"
                }
            },
            "frequency": {
                "description": "Choisissez à quelle fréquence vous souhaitez recevoir cette notification.",
                "heading": "Fréquence"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alerte",
                        "frequency": "Fréquence",
                        "recipients": "Destinataires",
                        "status": "Statut"
                    },
                    "record": {
                        "alert_activity": ":scope Activité"
                    }
                },
                "title": "Alertes"
            },
            "monitor": {
                "description": "Sélectionnez l'activité que vous souhaitez surveiller.",
                "heading": "Moniteur"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Tous les jours",
                        "fortnightly": "Bimensuel",
                        "hourly": "Horaire",
                        "immediate": "Immédiat",
                        "monthly": "Mensuel",
                        "quarterly": "Trimestriel",
                        "six_monthly": "Six mois",
                        "two_monthly": "Deux mois",
                        "weekly": "Hebdomadaire",
                        "yearly": "Annuel"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "L'alerte est inactive",
                        "value": "désactivé"
                    },
                    "on": {
                        "tooltip": "L'alerte est active",
                        "value": "sur"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Désactiver l'hébergement d'enregistrements BIMI",
                "heading": "L'hébergement d'enregistrements BIMI est actuellement activé",
                "message": "Il est recommandé que BIMI Hosting soit activé pour gérer facilement votre logo et votre certificat.",
                "modal": {
                    "header": "Désactiver l'hébergement d'enregistrements BIMI pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer l'hébergement d'enregistrements BIMI",
                "heading": "L'hébergement BIMI est désactivé",
                "message": "Il est recommandé que l'hébergement BIMI soit activé pour gérer votre logo et votre certificat.",
                "modal": {
                    "header": "Activer l'hébergement d'enregistrements BIMI :domain",
                    "message": "En activant BIMI Hosting, vous pouvez facilement gérer votre logo et votre certificat."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificat CN",
                    "expiry": "Expiration du certificat",
                    "input_label": "Certificat de marque vérifié BIMI (Type de fichier : pem)",
                    "url": "Autorité\/URL du certificat"
                },
                "image": {
                    "input_label": "Logo BIMI (Type de fichier : svg)",
                    "preview": "Aperçu du logo",
                    "size": "Taille du logo",
                    "url": "URL du logo"
                }
            },
            "info": {
                "heading": "Qu’est-ce que BIMI ?",
                "message": "BIMI (Brand Indicators for Message Identification) est une norme qui facilite l'affichage de votre logo à côté de vos messages dans la boîte de réception, empêche les e-mails frauduleux et facilite la délivrabilité. BIMI travaille aux côtés de DMARC, DKIM et SPF pour vérifier l'authenticité d'un e-mail et contribuer à la visibilité de la marque."
            },
            "settings": {
                "heading": "Paramètres"
            },
            "title": "Modifier les paramètres - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Retour aux paramètres",
                "share": "Instructions de configuration du partage",
                "verify": "Vérifier",
                "view": "Afficher les instructions de configuration"
            },
            "bimi": {
                "description": "Mettez à jour votre DNS pour permettre la configuration des enregistrements BIMI de votre domaine."
            },
            "description": "Pour activer la surveillance et la gestion via DMARC Manager, veuillez mettre en œuvre les modifications suivantes dans les paramètres DNS de votre domaine.",
            "dkim": {
                "description": "Mettez à jour votre DNS pour permettre la configuration des clés publiques DKIM de votre domaine."
            },
            "dmarc": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de la politique DMARC de votre domaine et pour capturer des rapports sur l'activité d'authentification des e-mails.",
                "warning": "Prenez note des traits de soulignement (_) requis dans l'hôte et des points dans la valeur d'enregistrement."
            },
            "existing_dns_record_found": "Enregistrement :record existant trouvé",
            "heading": "Instructions de configuration :title",
            "instructions": {
                "add": "Ajouter un enregistrement :rdType",
                "heading": "Instructions de configuration :rdType",
                "new": "Étape 2 : Ajouter un nouvel enregistrement :rdType",
                "remove": "Étape 1 : Supprimer l'enregistrement :rdType existant"
            },
            "mta_policy": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de la politique MTA de votre domaine.",
                "heading": "Politique MTA-STS"
            },
            "mta_record": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement MTA de votre domaine.",
                "heading": "Enregistrement MTA-STS"
            },
            "record": {
                "host": "Hôte",
                "type": "Type d'enregistrement",
                "value": "Valeur"
            },
            "spf": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement SPF de votre domaine."
            },
            "status": {
                "last_date": "Dernière vérification le",
                "pending": "En attente de vérification",
                "verified": "Vérifié"
            },
            "tls_rpt": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement TLS-RPT de votre domaine et capturer des rapports.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Gérer les clients"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Volume d'e-mails",
                    "legends": {
                        "approved": "Approuvé",
                        "unapproved": "Non approuvé et autre"
                    }
                },
                "domain_totals": {
                    "action": "Afficher les domaines",
                    "enforced": "Appliqué",
                    "heading": "Domaines",
                    "reporting": "Rapports",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "Afficher les expéditeurs",
                    "heading": "Expéditeurs",
                    "legends": {
                        "approved": "Approuvé",
                        "unapproved": "Non approuvé"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Afficher tous les domaines"
                },
                "legends": {
                    "failing": "Échec du DMARC",
                    "forwards": "Attaquants",
                    "passing": "Passer le DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "Afficher le rapport"
                    },
                    "header": {
                        "approved": "Approuvé",
                        "domain": "Domaines",
                        "senders": "Expéditeurs",
                        "unapproved": "Non approuvé",
                        "volume": "Volume"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Afficher le rapport de domaine"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Conformité",
                        "domain": "Domaine",
                        "sender": "Expéditeurs approuvés par volume",
                        "volume": "Volume"
                    }
                }
            },
            "title": "Tableau de bord"
        },
        "delegation-verifications": {
            "verify": {
                "error": "Échec de la vérification",
                "success": "Vérification terminée avec succès"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Ajouter une nouvelle clé publique",
                "edit_public_key": {
                    "tooltip": "Modifier la clé publique"
                }
            },
            "create_drawer": {
                "header": "Ajouter une nouvelle clé publique"
            },
            "disable": {
                "action": "Gestion DKIM désactivée",
                "heading": "L'hébergement par clé publique DKIM est actuellement activé",
                "message": "Il est recommandé d'activer la gestion DKIM pour vous aider à gérer vos clés publiques.",
                "modal": {
                    "header": "Désactiver la gestion DKIM pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "edit_drawer": {
                "header": "Modifier la clé publique"
            },
            "enable": {
                "action": "Activer la gestion DKIM",
                "heading": "L'hébergement de clé publique DKIM est désactivé",
                "message": "Il est recommandé d'activer la gestion DKIM pour vous aider à gérer vos clés publiques.",
                "modal": {
                    "header": "Activer la gestion DKIM pour :domain",
                    "message": "En activant la gestion DKIM, vous pouvez gérer facilement vos clés publiques."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "record_type": {
                    "input_label": "Type d'enregistrement"
                },
                "record_value": {
                    "example": "par ex. dkim.exemple.com",
                    "input_label": "Enregistrer la valeur"
                },
                "selector": {
                    "input_label": "Sélecteur"
                }
            },
            "import_modal": {
                "action": "Supprimer et importer",
                "complete": {
                    "message": "Importation de la clé publique DKIM terminée"
                },
                "disabled_tooltip": "Les clés publiques DKIM ne peuvent être importées que pour les domaines disposant de rapports.",
                "header": "Réimporter les clés publiques DKIM pour :domain",
                "message": "Toutes les clés publiques existantes seront supprimées et remplacées par des directives importées."
            },
            "info": {
                "heading": "Qu’est-ce que le DKIM ?",
                "message": "Domain Keys Identified Mail est une méthode d'authentification de courrier électronique qui permet à un expéditeur de Domain Keys Identified Mail est une méthode d'authentification de courrier électronique qui permet à un expéditeur de signer numériquement un courrier électronique à l'aide d'une clé privée associée à son nom de domaine, qui peut être vérifiée par le courrier du destinataire. serveur utilisant une clé publique publiée dans les enregistrements DNS de l'expéditeur. Cela permet de vérifier l'authenticité et l'intégrité de l'e-mail, réduisant ainsi le risque d'attaques de spam et de phishing."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Politique de signature sortante",
                        "options": {
                            "all": "Tous les e-mails",
                            "some": "Quelques e-mails"
                        }
                    },
                    "testing": {
                        "input_label": "Mode test"
                    }
                },
                "heading": "Paramètres de stratégie"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Type d'enregistrement",
                        "selector": "Sélecteur",
                        "value": "Valeur"
                    }
                }
            },
            "title": "Modifier les paramètres – DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "Mode d'alignement DKIM"
                    },
                    "options": {
                        "r": "Détendu",
                        "s": "Strict"
                    },
                    "spf_mode": {
                        "input_label": "Mode d'alignement SPF"
                    }
                },
                "heading": "Paramètres d'alignement"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Appliquer une politique de sous-domaine différente"
                    },
                    "percentage": {
                        "input_label": "Pourcentage"
                    },
                    "policy": {
                        "input_label": "Politique DMARC"
                    },
                    "subdomain_policy": {
                        "input_label": "Politique de sous-domaine"
                    }
                },
                "heading": "Paramètres de stratégie"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Les rapports seront envoyés à"
                    },
                    "interval": {
                        "input_label": "Intervalle de rapport",
                        "options": {
                            "1_day": "Une fois par jour",
                            "1_hour": "Chaque heure",
                            "2_hours": "Toutes les 2 heures",
                            "4_hours": "Toutes les 4 heures",
                            "6_hours": "Toutes les 6 heures"
                        }
                    }
                },
                "heading": "Rapports agrégés"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Activer",
                    "header": "Activer le rapport d'échec",
                    "message": "En activant le rapport d'échec, certaines informations personnelles, telles que les adresses e-mail ou le contenu des messages, peuvent être exposées à des fins de diagnostic.",
                    "reject": "Désactiver"
                },
                "fields": {
                    "options": {
                        "input_label": "Options de rapport",
                        "options": {
                            "d": "DKIM échoue quel que soit l'alignement",
                            "s": "Le SPF échoue quel que soit l’alignement",
                            "0": "SPF ou DKIM échouent et ne s'alignent pas",
                            "1": "SPF et DKIM échouent et ne s'alignent pas"
                        }
                    },
                    "status": {
                        "input_label": "Traitement des rapports",
                        "options": {
                            "disabled": "Désactivé",
                            "enabled": "Activé",
                            "specified": "Destinataires spécifiques"
                        }
                    }
                },
                "heading": "Rapports d'échec"
            },
            "title": "Modifier les paramètres - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "Certificat de conformité DMARC",
                "domain_report": "Rapport de domaine",
                "edit_settings": {
                    "tooltip": "Modifier les paramètres"
                },
                "remove_primary": "Supprimer des favoris",
                "set_as_active": "Définir comme actif",
                "set_as_parked": "Définir comme garé",
                "set_primary": "Marquer comme favori",
                "view_logs": "Afficher les journaux"
            },
            "create_drawer": {
                "header": "Ajouter un nouveau domaine"
            },
            "fields": {
                "name": {
                    "example": "par ex. « exemple.com » ou « sous-domaine.exemple.com »",
                    "input_label": "Nom de domaine"
                },
                "type": {
                    "input_label": "Type de domaine",
                    "options": {
                        "active": "Actif",
                        "parked": "Garé"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domaine",
                        "policy": "Politique",
                        "score": "Score",
                        "status": "Statut",
                        "type": "Taper"
                    }
                },
                "title": "Domaines"
            },
            "logs_drawer": {
                "header": "Domaine"
            },
            "report_modal": {
                "action": "Générer un lien",
                "fields": {
                    "date_range": {
                        "input_label": "Plage de dates"
                    },
                    "expire_link": {
                        "input_label": "Expirer le lien dans",
                        "options": {
                            "1_day": "Un jour",
                            "1_month": "Un mois",
                            "1_week": "Une semaine",
                            "6_hours": "6 heures",
                            "12_hours": "12 heures",
                            "never": "Jamais"
                        }
                    }
                },
                "header": "Rapport de domaine"
            },
            "settings": {
                "title": "Modifier les paramètres"
            },
            "setup_modal": {
                "action": "Générer un lien",
                "fields": {
                    "copy_link": {
                        "input_label": "Lien vers les instructions de configuration"
                    },
                    "expire_link": {
                        "input_label": "Expirer le lien dans",
                        "options": {
                            "1_day": "Un jour",
                            "1_month": "Un mois",
                            "1_week": "Une semaine",
                            "6_hours": "6 heures",
                            "12_hours": "12 heures",
                            "never": "Jamais"
                        }
                    }
                },
                "header": "Instructions de configuration du partage"
            },
            "tags": {
                "policy": {
                    "tooltip": "Politique appliquée"
                },
                "primary": {
                    "tooltip": "Préféré",
                    "value": "Préféré"
                },
                "score": {
                    "high": {
                        "tooltip": "Risque élevé"
                    },
                    "low": {
                        "tooltip": "Faible risque"
                    },
                    "moderate": {
                        "tooltip": "Risque modéré"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Vérifié, pas de rapport",
                        "tooltip_partial": "Partiellement vérifié, pas de rapport",
                        "value": "Ne pas signaler"
                    },
                    "not_verified": {
                        "tooltip": "Non vérifié, pas de rapport",
                        "value": "Non vérifié"
                    },
                    "reporting": {
                        "tooltip": "Vérifié, Rapport",
                        "tooltip_partial": "Partiellement vérifié, rapport",
                        "value": "Rapports"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Domaine actif",
                        "value": "Actif"
                    },
                    "parked": {
                        "tooltip": "Domaine garé",
                        "value": "Garé"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "par ex. destinataire@exemple.com",
                    "input_label": "Modifier l'e-mail",
                    "placeholder": "Entrez l'adresse e-mail",
                    "subscribed_label": "Abonné",
                    "unsubscribed_label": "Désabonné",
                    "validation_message": "Entrez une adresse e-mail valide"
                }
            }
        },
        "errors": {
            "menu": "Erreur",
            "401": {
                "description": "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
                "title": "401 : Non autorisé"
            },
            "403": {
                "description": "Désolé, il vous est interdit d'accéder à cette page.",
                "title": "403 : Interdit"
            },
            "404": {
                "description": "Désolé, la page que vous recherchez est introuvable.",
                "title": "404 : page introuvable"
            },
            "500": {
                "description": "Oups, quelque chose s'est mal passé sur nos serveurs.",
                "title": "500 : erreur du serveur"
            },
            "502": {
                "description": "Désolé, nos serveurs sont en panne.",
                "title": "502 Mauvaise passerelle"
            },
            "503": {
                "description": "Désolé, nous effectuons quelques travaux de maintenance. Veuillez revenir bientôt.",
                "title": "503 : Service indisponible"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Volume d'e-mails",
                "legends": {
                    "failing": "Échec du DMARC",
                    "forward": "Attaquants",
                    "passing": "Passer le DMARC"
                }
            },
            "heading": "Rapport de domaine",
            "recommendations": {
                "heading": "Recommandations"
            },
            "risk_analysis": {
                "heading": "Analyse des risques"
            },
            "scoring": {
                "note": "Remarque : Le score du domaine est calculé au moment de la génération du rapport et peut ne pas être à jour au moment de la consultation."
            },
            "sender_readiness": {
                "heading": "Préparation DMARC de l'expéditeur",
                "legends": {
                    "configured": {
                        "description": "La préparation DMARC est une mesure de la configuration requise pour mettre en œuvre une conformité totale.",
                        "heading": "Configuré"
                    },
                    "partially": {
                        "description": "Les sources partiellement configurées ont au moins un mécanisme d'autorisation actif, mais une configuration supplémentaire peut être nécessaire.",
                        "heading": "Partiellement configuré"
                    },
                    "unconfigured": {
                        "description": "Les sources illégitimes ou non configurées sont des sources qui envoient des e-mails à partir de votre domaine qui peuvent usurper l'identité de votre marque.",
                        "heading": "Illégitime ou non configuré"
                    }
                }
            },
            "title": "Rapport de domaine pour :domain",
            "top_countries": {
                "heading": "Principaux pays d'origine – Volume d'e-mails"
            },
            "top_senders": {
                "heading": "Principaux expéditeurs – Conformité"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Télécharger le rapport",
                "header": "Rapport d'échec",
                "menu": {
                    "key_properties": "Propriétés clés",
                    "more_info": "Plus d'informations"
                }
            },
            "properties": {
                "authentication_failure": "Échec d'authentification",
                "date": "Date",
                "delivery_results": "Résultats de livraison",
                "dkim_domain": "Domaine DKIM",
                "dkim_header": "En-tête canonique DKIM",
                "dkim_identity": "Identité DKIM",
                "dkim_selector": "Sélecteur DKIM",
                "dmarc_version": "Version DMARC",
                "feedback_type": "Type de commentaires",
                "incidents": "Nombre d'incidents",
                "original_recipients": "Destinataires originaux",
                "reporting_domains": "Domaines signalés",
                "reporting_mta": "Rapport MTA",
                "reporting_user_agent": "Agent utilisateur de reporting",
                "return_path": "Chemin de retour",
                "source_ip": "Adresse IP source",
                "spf_dns": "DNS SPF"
            },
            "table": {
                "header": {
                    "authentication": "Authentification",
                    "date": "Date",
                    "ip_address": "Adresse IP",
                    "result": "Résultat",
                    "return_path": "Chemin de retour"
                }
            },
            "title": "Rapports d'échec pour :domain"
        },
        "global": {
            "buttons": {
                "add": "Ajouter",
                "add_new": "Ajouter un nouveau",
                "cancel": "Annuler",
                "delete": "Supprimer",
                "import": "Importer",
                "next": "Suivant",
                "no": "Non",
                "remove": "Retirer",
                "save": "Sauvegarder",
                "save_exit": "Enregistrer et revenir",
                "select": "Sélectionner",
                "yes": "Oui"
            },
            "confirm_delete": "Voulez-vous continuer ?",
            "copied": "Copié",
            "created": "Créé",
            "deleted": "Supprimé",
            "fail": "Échouer",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Désactivé",
                        "enabled": "Activé"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Désactivé",
                        "on": "Sur"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "Non",
                        "yes": "Oui"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Erreur : Veuillez remplir correctement tous les champs obligatoires.",
                    "title": "Il y a eu des erreurs avec votre formulaire."
                },
                "success": {
                    "title": "Vos modifications ont été enregistrées."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volume"
                }
            },
            "logout": "Déconnexion",
            "not_found": "Pas trouvé",
            "pass": "Passer",
            "remove": "Retirer",
            "show_less": "Afficher moins",
            "show_more": "Afficher plus",
            "system": "Système",
            "theme": {
                "dark": "Sombre",
                "light": "Lumière"
            },
            "to": "À"
        },
        "ip-address-modal": {
            "menu": {
                "overrides": "Remplacements",
                "overview": "Aperçu",
                "reporters": "Journalistes",
                "results": "Résultats",
                "spf_result": "Résultat SPF"
            },
            "overrides": {
                "breakdown": {
                    "policy_applied_none": "Politique appliquée « Aucune »",
                    "policy_applied_quarantine": "Politique appliquée « Quarantaine »",
                    "policy_applied_reject": "Politique appliquée « Rejeter »"
                },
                "overridden_policy_breakdown": "Répartition de la politique remplacée",
                "table": {
                    "override_comment": "Commentaire",
                    "override_type": "Type de remplacement",
                    "reporter": "Journaliste"
                },
                "volume_overridden_policy": "Volume avec des politiques remplacées"
            },
            "overview": {
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "detail": {
                    "country": "Pays",
                    "host": "HÔTE",
                    "isp": "FAI",
                    "organisation": "Organisation",
                    "sender": "Expéditeur"
                },
                "volume": "Volume"
            },
            "reporters": {
                "table": {
                    "compliance": "Conformité",
                    "envelope_from": "Enveloppe De",
                    "envelope_to": "Enveloppe à",
                    "header_from": "En-tête de",
                    "percent_of_total": "% du total",
                    "reporter": "Journaliste",
                    "volume": "Volume"
                }
            },
            "results": {
                "legend": {
                    "align": "Alignement",
                    "error": "Erreur",
                    "failed": "Échec\/non aligné",
                    "none": "Aucun",
                    "passed": "Réussi\/Aligne",
                    "pass_fail": "Réussite\/Échec"
                },
                "table": {
                    "action_applied": "Action appliquée",
                    "action_taken": "Mesure prise par le destinataire",
                    "alignment": "Alignement",
                    "compliance": "Conformité",
                    "delivered": "Livré",
                    "dkim_aligned": "Aligné sur DKIM",
                    "dkim_details": "Détails DKIM",
                    "dkim_failed": "Échec du DKIM",
                    "dkim_none": "DKIM Aucun",
                    "dkim_not_aligned": "DKIM non aligné",
                    "dkim_no_info": "Aucune information DKIM",
                    "dkim_passed": "DKIM réussi",
                    "dkim_results": "Résultats DKIM",
                    "dmarc_failed": "Échec du DMARC",
                    "dmarc_passed": "DMARC réussi",
                    "dmarc_results": "Résultats DMARC",
                    "dmarc_via_dkim": "DMARC via DKIM",
                    "dmarc_via_spf": "DMARC via SPF",
                    "envelope_from": "Enveloppe De",
                    "envelope_to": "Enveloppe à",
                    "from_domain": "Du domaine",
                    "header_from": "En-tête de",
                    "none": "Aucun",
                    "no_dkim_details": "Aucun détail de signature DKIM",
                    "no_spf_details": "Aucun détail SPF",
                    "other_details": "Autres détails",
                    "percent_of_total": "% du total",
                    "policy_applied": "Politique appliquée",
                    "policy_of_applied": "La politique de « :policy » a été appliquée par le destinataire",
                    "published_policy": "Politique publiée",
                    "quarantine": "Quarantaine",
                    "reject": "Rejeter",
                    "reporter": "Journaliste",
                    "result": "Résultat",
                    "return_path_domain": "Domaine du chemin de retour",
                    "selector": "Sélecteur",
                    "signing_domain": "Domaine de signature",
                    "spf_aligned": "Aligné sur le SPF",
                    "spf_details": "Détails du SPF",
                    "spf_fail": "Échec du SPF",
                    "spf_none": "FPS Aucun",
                    "spf_not_aligned": "SPF non aligné",
                    "spf_no_info": "Aucune information SPF",
                    "spf_pass": "Pass SPF",
                    "spf_results": "Résultats SPF",
                    "spf_tmp_error": "Erreur temporaire SPF",
                    "validated_dmarc_policy": "Politique Dmarc au moment de la validation",
                    "volume": "Volume"
                }
            }
        },
        "logs": {
            "events": {
                "created": "créé",
                "deleted": "supprimé",
                "restored": "restauré",
                "updated": "mis à jour"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificat",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Délégation activée",
                        "nameservers": "Serveurs de noms",
                        "selector": "Sélecteur"
                    },
                    "title": "Paramètres BIMI :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Description",
                        "record_type": "Type d'enregistrement",
                        "selector": "Sélecteur",
                        "status": "Statut",
                        "ttl": "Durée de vie",
                        "value": "Valeur"
                    },
                    "title": "Clé publique DKIM :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Délégation activée",
                        "is_policy_enabled": "Stratégie activée",
                        "nameservers": "Serveurs de noms",
                        "policy": "Politique"
                    },
                    "title": "Paramètres DKIM :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "Alignement DKIM",
                        "policy": "Politique",
                        "policy_percentage": "Pourcentage de politique",
                        "rua_emails": "Destinataires du rapport global",
                        "rua_interval": "Intervalle de rapport global",
                        "ruf_emails": "Destinataires du rapport d'échec",
                        "ruf_format": "Format du rapport d'échec",
                        "ruf_options": "Options de rapport d'échec",
                        "ruf_status": "État du rapport d'échec",
                        "spf_alignment": "Alignement SPF",
                        "subdomain_policy": "Politique de sous-domaine"
                    },
                    "title": "Paramètres DMARC :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Messages",
                        "result": "Résultat",
                        "started_at": "Commencé à",
                        "status": "Statut",
                        "type": "Taper"
                    },
                    "title": "Vérification de la délégation DNS :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domaine",
                        "is_favorite": "Préféré",
                        "logo": "Logo",
                        "score": "Score de domaine",
                        "type": "Taper"
                    },
                    "title": "Domaine :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_policy_hosting_enabled": "Hébergement de stratégie activé",
                        "is_record_enabled": "Hébergement d'enregistrement activé",
                        "mx_records": "Enregistrements MX",
                        "policy": "Mode politique"
                    },
                    "title": "Paramètres MTA-STS :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Description",
                        "mechanism": "Type d'enregistrement",
                        "order": "Commande",
                        "qualifier": "Qualificateur d'enregistrement",
                        "status": "Statut",
                        "value": "Valeur de l'hôte"
                    },
                    "title": "Directives SPF :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Qualificateur de record de haut niveau",
                        "enable_flattening": "Aplatissement activé"
                    },
                    "title": "Paramètres SPF :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT activé",
                        "rua_emails": "Signaler des e-mails"
                    },
                    "title": "Paramètres TLS-RPT :event"
                }
            },
            "header": ":type Journaux"
        },
        "menu": {
            "domains": "Domaines",
            "home": "Tableau de bord",
            "reports": {
                "email_senders": "Expéditeurs d'e-mails",
                "failure_reports": "Rapports d'échec",
                "reports": "Rapports",
                "tls_rpt_reports": "Rapports TLS-RPT"
            },
            "settings": {
                "alerts": "Alertes",
                "logout": "Déconnexion",
                "settings": "Paramètres"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Hébergement MTA-STS désactivé",
                "heading": "L'hébergement MTA-STS est activé",
                "message": "Il est recommandé d'activer la création de rapports TLS-RPT et l'hébergement de politiques MTA-STS.",
                "modal": {
                    "header": "Désactiver l'hébergement MTA-STS pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer l'hébergement MTA-STS",
                "heading": "L'hébergement MTA-STS est désactivé",
                "message": "Il est recommandé que l’hébergement des enregistrements et des politiques MTA-STS soit activé pour gérer efficacement vos enregistrements.",
                "modal": {
                    "header": "Activer l'hébergement MTA-STS pour :domain",
                    "message": "En activant l'hébergement MTA-STS, vous pouvez gérer votre dossier et l'hébergement de votre police."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "Serveurs MX"
                },
                "policy": {
                    "input_label": "Niveau d'application des politiques",
                    "options": {
                        "enforce": "Appliqué",
                        "none": "Aucun",
                        "testing": "En test"
                    }
                }
            },
            "heading": "Hébergement des enregistrements et des politiques MTA-STS",
            "info": {
                "heading": "Qu'est-ce que TLS-RPT et MTA-STS ?",
                "message": "TLS-RPT ou SMTP TLS Reporting (Transport Layer Security Reporting) permet aux systèmes d'envoi de partager des statistiques et des informations sur les échecs avec les domaines destinataires. MTA-STS (Mail Transfer Agents - Strict Transport Security) permet aux fournisseurs de services de messagerie de déclarer leur capacité à recevoir des connexions SMTP sécurisées TLS."
            },
            "status": {
                "policy": {
                    "pending": "Politique en attente de vérification",
                    "verified": "Politique vérifiée"
                },
                "record": {
                    "pending": "Enregistrement en attente de vérification",
                    "verified": "Enregistrement vérifié"
                }
            }
        },
        "partners": {
            "title": "Gérer les partenaires"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configurer les paramètres DMARC",
                        "manage_domains": "Gérer les domaines"
                    },
                    "description": "Nous n'avons pas encore reçu de données de rapport pour votre domaine. Cela peut prendre jusqu'à 72 heures pour que les données apparaissent. Les rapports ne peuvent être récupérés que pour les domaines actifs et vérifiés. Gérez le reste de vos domaines en accédant à l'interface Domaines.",
                    "header": "Aucune donnée de rapport disponible"
                },
                "no_reporting_data": {
                    "description": "Aucune donnée de rapport disponible pour la plage de dates sélectionnée. Veuillez ajuster votre sélection et réessayer.",
                    "header": "Aucune donnée de rapport disponible"
                }
            }
        },
        "scoring": {
            "blurb": {
                "high": "Vous ne disposez pas de contrôles efficaces pour protéger votre domaine contre\n              usurpation d’identité et interception de communications par courrier électronique. Cela met votre\n              les destinataires de la marque et des e-mails risquent d'être attaqués, réduit la confiance et peut\n              nuire à la délivrabilité des e-mails.",
                "low": "Les e-mails de votre domaine sont hautement sécurisés contre l'usurpation d'identité et\n              interception et les destinataires verront votre marque dans la communication. Ces\n              les mesures renforcent la confiance dans votre marque, protègent les destinataires contre les attaques et\n              aider à garantir que les e-mails sont livrés efficacement.",
                "moderate": "Vous avez mis en place certaines mesures pour protéger les destinataires contre les e-mails malveillants\n              de votre domaine. Il convient de prêter attention aux domaines dans lesquels des contrôles peuvent être\n              amélioré. Ces mesures renforcent la confiance dans votre marque, protègent les destinataires\n              contre les attaques et contribuer à garantir la transmission efficace des e-mails."
            },
            "header": "Score de domaine",
            "impersonation": "Imitation",
            "marketing": "Commercialisation",
            "note": "REMARQUE : Il s'agit du score actuel de votre domaine et peut ne pas refléter les modifications que vous avez apportées aujourd'hui.",
            "privacy": "Confidentialité",
            "rating": {
                "high": "Risque élevé",
                "low": "Faible risque",
                "moderate": "Risque modéré"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Volume bloqué",
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "deliverable_volume": "Volume livrable",
                "ip_addresses": "Adresses IP",
                "senders": "Expéditeurs",
                "volume": "Volume d'e-mails"
            },
            "categories": {
                "all": "Tous les expéditeurs",
                "authorised_compliant": "Approuvé",
                "forwarder": "Transitaire",
                "suspicious": "Suspect",
                "unauthorised": "Non approuvé",
                "unknown": "Aucun"
            },
            "menu": {
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "reputation": "Réputation",
                "threats": "Menaces"
            },
            "messages": {
                "category_updated": {
                    "error": "Échec de la mise à jour de la catégorie d'expéditeur",
                    "success": "Catégorie d'expéditeur mise à jour"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Sur liste noire",
                    "clean": "Faire le ménage",
                    "compliant": "Conforme",
                    "delivered": "Livré",
                    "failing_dmarc": "Échec du DMARC",
                    "forward": "Attaquants",
                    "not_delivered": "Non livré",
                    "overridden": "Remplacé",
                    "overridden_as_delivered": "Remplacé tel que livré",
                    "overridden_as_not_delivered": "Remplacé car non livré",
                    "passing": "Passage",
                    "passing_dkim": "Passer DKIM uniquement",
                    "passing_dkim_spf": "Passer DKIM et SPF",
                    "passing_dmarc": "Passer le DMARC",
                    "passing_spf": "Passer le SPF uniquement",
                    "senders": "Expéditeurs"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Adresses IP d'expéditeur approuvées",
                        "approved_sender_volume": "Volume de l'expéditeur approuvé",
                        "blacklisted": "Sur liste noire",
                        "blacklists": "Listes noires",
                        "blocked_volume": "Volume bloqué",
                        "category": "Catégorie",
                        "clean": "Faire le ménage",
                        "compliance": "Conformité",
                        "country": "Pays",
                        "deliverability": "Délivrabilité",
                        "delivered": "Livré",
                        "failing": "Défaut",
                        "forwards": "Attaquants",
                        "host": "Hôte",
                        "ip": "IP",
                        "ip_addresses": "Adresses IP",
                        "not_delivered": "Non livré",
                        "passing": "Passage",
                        "sender": "Expéditeur",
                        "top_blacklisted_senders": "Principaux expéditeurs sur liste noire",
                        "volume": "Volume"
                    },
                    "info": {
                        "blacklists": "Nombre de listes noires dans lesquelles l'adresse IP a été répertoriée"
                    }
                }
            },
            "title": "Expéditeurs d'e-mails pour :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Ajouter une nouvelle directive SPF",
                "edit_record": {
                    "tooltip": "Modifier l'enregistrement"
                }
            },
            "create_drawer": {
                "header": "Ajouter une nouvelle directive SPF"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directives",
                        "order": "Commande"
                    }
                }
            },
            "edit_drawer": {
                "header": "Modifier la directive SPF"
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "host": {
                    "example": "par ex. _spf.exemple.com",
                    "input_label": "Valeur :type"
                },
                "mechanism": {
                    "input_label": "Type d'enregistrement"
                },
                "qualifier": {
                    "input_label": "Qualificateur d'enregistrement",
                    "options": {
                        "allow": "Pass \"+\" (non recommandé)",
                        "fail": "\"-\" Échouer",
                        "neutral": "\"?\" Neutre (non recommandé)",
                        "soft_fail": "\"~\" Échec logiciel"
                    }
                },
                "update": "Mettre à jour une directive Spf",
                "use_own_domain": {
                    "input_label": "Domaine du mécanisme"
                }
            },
            "import_modal": {
                "action": "Supprimer et importer",
                "complete": {
                    "message": "Importation SPF terminée"
                },
                "header": "Importer l'enregistrement et les directives SPF existants pour :domain",
                "message": "Toutes les directives existantes seront supprimées et remplacées par des directives importées."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Qualificateur de record de haut niveau",
                        "options": {
                            "+": "Pass \"+\" (non recommandé)",
                            "-": "\"-\" Échouer",
                            "?": "\"?\" Neutre (non recommandé)",
                            "~": "\"~\" Échec logiciel"
                        },
                        "options_tooltips": {
                            "+": "\"+all\" Définir pour réussir l'authentification des e-mails provenant d'expéditeurs non autorisés.",
                            "-": "Définir pour échouer l’authentification des e-mails provenant d’expéditeurs non autorisés.",
                            "?": "Défini sur neutre, qui ne réussit ni n'échoue l'authentification des e-mails provenant d'expéditeurs non autorisés.",
                            "~": "Configurez l'authentification par échec logiciel des e-mails provenant d'expéditeurs non autorisés et traitez-les comme suspects."
                        }
                    },
                    "flattening": {
                        "input_label": "Activer l'aplatissement"
                    }
                },
                "heading": "Paramètres"
            },
            "title": "Modifier les paramètres - SPF"
        },
        "tables": {
            "clear": "Clair",
            "not_found": "Aucun enregistrement correspondant trouvé",
            "search": "Recherche"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configurer les paramètres TLS-RPT",
                    "description": "Nous n'avons pas encore reçu de données de rapport pour votre domaine. Cela peut prendre jusqu'à 72 heures pour que les données apparaissent. Les rapports TLS-RPT ne peuvent être récupérés que pour les domaines pour lesquels les rapports TLS-RPT sont activés.",
                    "header": "Aucune donnée de rapport disponible"
                }
            },
            "heading": "Rapports TLS-RPT pour :domain",
            "reporters": {
                "heading": "Rapports",
                "legend": {
                    "reports": "Rapports"
                },
                "table": {
                    "header": {
                        "policy_mode": "Mode politique",
                        "policy_type": "Type de politique",
                        "reporter": "Journaliste",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Chronologie",
                "legend": {
                    "failure": "Échoué",
                    "success": "Réussi"
                }
            },
            "title": "Rapports TLS-RPT"
        },
        "tls_settings": {
            "disable": {
                "action": "Désactiver les rapports TLS-RPT",
                "message": "Il est recommandé d'activer les rapports TLS-RPT pour surveiller les rapports.",
                "modal": {
                    "header": "Rapports TLS-RPT désactivés pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer les rapports TLS-RPT",
                "heading": "La création de rapports TLS-RPT est désactivée",
                "message": "Il est recommandé d'activer les rapports TLS-RPT pour surveiller les rapports.",
                "modal": {
                    "header": "Activer la création de rapports TLS-RPT pour :domain",
                    "message": "En activant les rapports TLS-RPT, vous pouvez surveiller les statistiques et les informations sur les échecs avec les domaines destinataires."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Les rapports seront envoyés à"
                }
            },
            "heading": "Rapports TLS-RPT",
            "title": "Modifier les paramètres - TLS-RPT"
        },
        "upload": {
            "add": "Ajouter des fichiers",
            "drop_zone": "Faites glisser et déposez le fichier ici pour le télécharger.",
            "title": "Télécharger"
        },
        "users": {
            "email": {
                "edit": "Modifier l'e-mail",
                "placeholder": "Entrez l'adresse e-mail"
            },
            "menu": "Utilisateurs",
            "name": {
                "title": "Nom"
            },
            "title": "Gérer les utilisateurs"
        },
        "validation": {
            "email": "Pas une adresse e-mail valide",
            "invalid_attr": "Le :attribute n'est pas valide."
        }
    }
} as const;