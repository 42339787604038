import './bootstrap';
import '../css/app.css';

import { createApp, h, type DefineComponent, onMounted } from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';
import ConfirmationService from 'primevue/confirmationservice';
import { ZiggyVue, type Config } from 'ziggy-js';
import { Ziggy } from '@/ziggy';
import { TranslatorPlugin } from '@/Utils/Translations/TranslationPlugin';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import getTheme from '@/Themes/getTheme';
import { FormattingPlugin } from '@/Utils/Formatting/FormattingPlugin';

const appName = import.meta.env.VITE_APP_NAME || 'Sendmarc';

void createInertiaApp({
  title: title => `${title} - ${appName}`,
  resolve: (name) => {
    const pages = import.meta.glob<DefineComponent>('./Pages/**/*.vue', { eager: true });
    return pages[`./Pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    const { oemConfig } = props.initialPage.props;
    Ziggy.url = oemConfig.url;
    window.Ziggy = Ziggy;

    createApp({
      render: () => h(App, props),
      setup() {
        // The parent, proxy app may define callbacks to be executed on mounted
        if (window && window.onMountedCallbacks?.length) {
          window.onMountedCallbacks.forEach(callback => onMounted(() => callback({
            router,
            page: props.initialPage,
          })));
          window.onMountedCallbacks = [];
        }
        window.axios.interceptors.request.use((config) => {
          config.withCredentials = true;
          config.fetchOptions = {
            ...config.fetchOptions,
            mode: 'cors',
          };
          config.withXSRFToken = true;
          config.xsrfCookieName = 'XSRF-TOKEN';

          return config;
        });
      },
    })
      .directive('tooltip', Tooltip)
      .directive('ripple', Ripple)
      .use(ConfirmationService)
      .use(ToastService)
      .use(plugin)
      .use(ZiggyVue, { ...Ziggy, url: oemConfig.url } as Config)
      .use(PrimeVue, {
        theme: getTheme(oemConfig.theme),
      })
      .use(TranslatorPlugin, props.initialPage.props)
      .use(FormattingPlugin, props.initialPage.props)
      .mount(el);
  },
  progress: {
    color: 'var(--p-primary-color)',
    showSpinner: true,
  },
});
