<script setup lang="ts">
import { type AlertsResource, type RecipientResource } from '@/types/types.gen';
import { inject } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import FormGroup from '@/Components/FormGroup.vue';
import EmailsListBox from '@/Pages/Alerts/Partials/EmailsListBox.vue';

const form: InertiaForm<AlertsResource> = inject('form')!;
const setRecipient = function (recipients: RecipientResource[]) {
  form.channels.mail.recipients = [...recipients];
};

</script>
<template>
  <div class="flex flex-col gap-8 px-5 py-4">
    <div>{{ $t('alerts.email.description') }}</div>
      <FormGroup
        :label="$t('alerts.fields.recipients.input_label')"
      >
        <template #element>
          <EmailsListBox @change="setRecipient" class="w-1/3" :recipients="form.channels.mail.recipients" />
        </template>
      </FormGroup>
  </div>
</template>
