import type { AggregateReportResource } from '@/types/types.gen';
import { DmarcAlignment, SenderCategory } from '@/types/types.gen';
import { each } from 'lodash';
import type {
  DmarcStatus,
  HasPolicyEvaluations,
  RuaRecord,
  RuaReport,
  SpfDkimResults,
} from '@/Pages/Statistics/IpAddress/types';

const groupAggregateReports = (data: AggregateReportResource[]): Record<string, RuaReport> => {
  const reports: Record<string, RuaReport> = {};

  each(data, (row: AggregateReportResource) => {
    const report: RuaReport = getReport(reports, row),
      record = getRecord(report, row);

    if (row.type) {
      record.results[row.type] ||= [];

      record.results[row.type].push({
        type: row.type,
        domain: row.domain,
        result: row.result,
        dkim_selector: row.dkim_selector,
        dkim_human_result: row.dkim_human_result,
        spf_scope: row.spf_scope,
      });
    }
  });

  return reports;
};

const getReport = (reports: Record<string, RuaReport>, row: AggregateReportResource): RuaReport => {
  reports[row.rid] ||= {
    organisation: row.organisation,
    published_policy_p: row.published_policy_p,
    published_policy_sp: row.published_policy_sp,
    published_policy_pct: row.published_policy_pct,
    published_policy_fo: row.published_policy_fo,
    published_policy_adkim: row.published_policy_adkim,
    published_policy_aspf: row.published_policy_aspf,
    records: {},
  };

  return reports[row.rid];
};

const getRecord = (report: RuaReport, row: AggregateReportResource) => {
  report.records[row.rrid] ||= {
    count: row.count,
    policy_evaluated_disposition: row.policy_evaluated_disposition,
    policy_evaluated_dkim: row.policy_evaluated_dkim,
    policy_evaluated_spf: row.policy_evaluated_spf,
    policy_evaluated_override_reason_type: row.policy_evaluated_override_reason_type,
    policy_evaluated_override_reason_comment: row.policy_evaluated_override_reason_comment,
    identifiers_envelope_to: row.identifiers_envelope_to,
    identifiers_envelope_from: row.identifiers_envelope_from,
    identifiers_header_from: row.identifiers_header_from,
    results: {},
  };

  return report.records[row.rrid];
};

const isPassing = (record: HasPolicyEvaluations, stat: { category: SenderCategory }) =>
  isDkimPass(record, stat) || isSpfPass(record);

const isDkimPass = (record: HasPolicyEvaluations, stat: { category: SenderCategory }) =>
  record.policy_evaluated_spf !== 'pass' && record.policy_evaluated_dkim === 'pass' && stat.category !== 'forwarder';

const isSpfPass = (record: HasPolicyEvaluations) =>
  record.policy_evaluated_spf === 'pass' && record.policy_evaluated_dkim !== 'pass';

const isForward = (record: HasPolicyEvaluations, stat: { category: SenderCategory }) =>
  record.policy_evaluated_spf !== 'pass' && record.policy_evaluated_dkim === 'pass' && stat.category === 'forwarder';

const isFailing = (record: HasPolicyEvaluations) =>
  record.policy_evaluated_spf !== 'pass' && record.policy_evaluated_dkim !== 'pass';

const isSpfAligned = (selectedDomain: string, domain: string | null, spfAlignment: DmarcAlignment | null) => {
  return spfAlignment === DmarcAlignment.S
    ? domainsMatches(selectedDomain, domain)
    : domainsMatches(selectedDomain, domain) || isSubdomain(selectedDomain, domain);
};

const isDkimAligned = (selectedDomain: string, domain: string | null, dkimAlignment: DmarcAlignment) => {
  return dkimAlignment === DmarcAlignment.S
    ? domainsMatches(selectedDomain, domain)
    : domainsMatches(selectedDomain, domain) || isSubdomain(selectedDomain, domain);
};

const domainsMatches = (domain1: string, domain2: string | null) => {
  return domain1.toLowerCase() === (domain2 ? domain2.toLowerCase() : '');
};

const isSubdomain = (domain1: string, domain2: string | null) => {
  return domain2 ? domain2.toLowerCase().endsWith(`.${domain1.toLowerCase()}`) : false;
};

const getDmarcResult = (record: AggregateReportResource | RuaRecord): DmarcStatus => {
  return record.policy_evaluated_dkim === 'pass' || record.policy_evaluated_spf === 'pass'
    ? 'pass'
    : 'fail';
};

const getResults = (selectedDomainName: string, record: RuaRecord, spfAlignment: DmarcAlignment, dkimAlignment: DmarcAlignment) => {
  const results: SpfDkimResults = {
    spf_domain: null,
    spf_result: null,
    is_dkim_aligned: null,
    is_spf_aligned: null,
    dkim_selector: null,
    dkim_domain: null,
    dkim_result: null,
  };

  if (Object.prototype.hasOwnProperty.call(record.results, 'spf')) {
    results.spf_domain = record.results.spf[0].domain;
    results.spf_result = record.results.spf[0].result;
    results.is_spf_aligned = isSpfAligned(selectedDomainName, record.results.spf[0].domain, spfAlignment);
  }

  if (Object.prototype.hasOwnProperty.call(record.results, 'dkim')) {
    for (let i = 0, length = record.results.dkim.length; i < length; i++) {
      if (results.dkim_result !== null && !isDkimAligned(selectedDomainName, record.results.dkim[i].domain, dkimAlignment)) {
        continue;
      }

      results.is_dkim_aligned = isDkimAligned(selectedDomainName, record.results.dkim[i].domain, dkimAlignment);
      results.dkim_domain = record.results.dkim[i].domain;
      results.dkim_result = record.results.dkim[i].result;
      results.dkim_selector = record.results.dkim[i].dkim_selector;
    }
  }

  return results;
};

export {
  groupAggregateReports,
  getDmarcResult,
  getResults,
  isFailing,
  isForward,
  isPassing,
};
