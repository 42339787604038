<script setup lang="ts">
import {
  type DnsDelegationVerificationResource,
  type DomainResource,
  type MtaStsSetupInstructionsResource,
} from '@/types/types.gen';
import Card from 'primevue/card';
import { reactive } from 'vue';
import MtaPolicySetupInstructions from '@/Pages/Domains/TlsRptSettings/Partials/Setup/MtaPolicySetupInstructions.vue';
import Button from 'primevue/button';
import VerificationStatus from '@/Components/VerificationStatus.vue';

defineProps<{
  domain: DomainResource;
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
  verification: DnsDelegationVerificationResource;
}>();

const state = reactive({
  showInstructions: window.location.hash === '#mta-policy',
});

const toggleInstructions = () => {
  state.showInstructions = !state.showInstructions;
  window.location.hash = state.showInstructions ? '#mta-policy' : '';
}
</script>

<template>
  <div
    class="text-xl font-bold"
    v-text="$t('mta_settings.heading')"
  />
  <Card class="border !border-surface">
    <template #content>
      <MtaPolicySetupInstructions
        v-if="state.showInstructions"
        @close="toggleInstructions"
        :domain="domain"
        :mta-sts-setup-instruction="mtaStsSetupInstruction"
        :verification="verification"
      />
      <div v-else class="flex items-center justify-between">
        <VerificationStatus :verification="verification" :pending-verification-text="$t('mta_settings.status.policy.pending')" :verified-text="$t('mta_settings.status.policy.verified')"/>
        <Button
          @click="toggleInstructions"
          text
          :label="$t('configurations.actions.view')"
          icon="pi pi-angle-right"
          iconPos="right"
          severity="secondary"
        />
      </div>
    </template>
  </Card>
</template>
