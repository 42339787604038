<script setup lang="ts">
import { router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

const { path } = defineProps<{
  path: string;
}>();

const { translate } = useTranslate();
const toast = useToast();
const verificationInProgress = ref(false);

const showError = () => {
  toast.add({
    severity: 'error',
    summary: translate('delegation-verifications.verify.error'),
    life: 5000,
  });
  verificationInProgress.value = false;
}

const verify = () => {
  verificationInProgress.value = true;
  router.get(path, {}, {
    onSuccess: (data) => {
      if (['pass', 'partial'].includes(data.props.verification?.result ?? '')) {
        toast.add({
          severity: 'success',
          summary: translate('delegation-verifications.verify.success'),
          life: 5000,
        });
        return;
      }
      showError();
      verificationInProgress.value = false;
    },
    onError: showError,
    preserveScroll: true,
  });
}
</script>

<template>
  <Button
    @click="verify"
    :disabled="verificationInProgress"
    label='Verify Now'
    severity="secondary"
  />
</template>

<style scoped>

</style>
