<script setup lang="ts">
import { type FileUploadUploadEvent } from 'primevue/fileupload';
import { router } from '@inertiajs/vue3';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import type { BimiSettingResource, DnsDelegationVerificationResource, DomainResource } from '@/types/types.gen';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import DomainsSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import EnableBimiSettings from '@/Pages/Domains/BimiSettings/Partials/EnableBimiSettings.vue';
import DisableBimiSettings from '@/Pages/Domains/BimiSettings/Partials/DisableBimiSettings.vue';
import LinkSetupInstructions from '@/Pages/Domains/Partials/LinkSetupInstructions.vue';
import FormGroup from '@/Components/FormGroup.vue';
import { useRoute } from 'ziggy-js';
import FileUploader from '@/Components/FileUploader.vue';
import BimiLogoDetails from '@/Pages/Domains/BimiSettings/Partials/BimiLogoDetails.vue';
import BimiCertificateDetails from '@/Pages/Domains/BimiSettings/Partials/BimiCertificateDetails.vue';
import type { DomainScore } from '@/types/cyclops.types.gen';
import VerificationStatus from '@/Components/VerificationStatus.vue';

const { domain } = defineProps<{
  bimiSettings: BimiSettingResource;
  domain: DomainResource;
  cyclopsScore: DomainScore | null;
  verification: DnsDelegationVerificationResource;
}>();

const routeParams = {
  customer: domain.customer_uuid,
  domain: domain.uuid,
};

const uploadBimiImage = ({ files }: FileUploadUploadEvent) => {
  router.post(
    useRoute()('ui.bimi-settings.update', routeParams),
    {
      _method: 'put',
      image: Array.isArray(files) ? files[0] : files,
    },
    responseCallbacks,
  );
};

const uploadCertificate = ({ files }: FileUploadUploadEvent) => {
  router.post(
    useRoute()('ui.bimi-settings.update', routeParams),
    {
      _method: 'put',
      certificate: Array.isArray(files) ? files[0] : files,
    },
    responseCallbacks,
  );
};

</script>

<template>
  <DomainsSettingsLayout
    :title="$t('dmarc_settings.title')"
    :domain="domain"
    :tab="DomainSettingsTab.BIMI"
    :cyclops-score="cyclopsScore"
  >
    <div v-if="bimiSettings.is_delegation_enabled" class="my-5 flex flex-row justify-between">
      <VerificationStatus :verification="verification"/>
      <LinkSetupInstructions :domain="domain" href="ui.bimi-settings.setup" />
    </div>

    <EnableBimiSettings
      v-if="!bimiSettings.is_delegation_enabled"
      class="mt-8"
      :domain="domain"
    />

    <div
      v-if="bimiSettings.is_delegation_enabled"
      class="flex flex-col gap-8">

      <div
        class="text-xl font-bold"
        v-text="$t('bimi_settings.settings.heading')"
      />

      <FormGroup :label="$t('bimi_settings.fields.image.input_label')">
        <template #element>
          <FileUploader @upload='uploadBimiImage' accept="image/svg+xml">
            <template #empty>
              <span v-if="!bimiSettings.image_path"
                  v-text="$t('upload.drop_zone')"
              />
               <BimiLogoDetails
                 v-else
                 :bimi-settings="bimiSettings"
               />
            </template>
          </FileUploader>
        </template>
      </FormGroup>

      <FormGroup :label="$t('bimi_settings.fields.certificate.input_label')">
        <template #element>
          <FileUploader @upload='uploadCertificate' accept=".pem,application/x-pem-file">
            <template #empty>
              <span v-if="!bimiSettings.certificate_path"
                v-text="$t('upload.drop_zone')"
              />
              <BimiCertificateDetails
                v-else
                :bimi-settings="bimiSettings"
              />
            </template>
          </FileUploader>
        </template>
      </FormGroup>

      <DisableBimiSettings
        v-if="bimiSettings.is_delegation_enabled"
        :domain="domain"
      />
    </div>
  </DomainsSettingsLayout>
</template>
