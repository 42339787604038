<script setup lang="ts">
import { computed } from 'vue';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useFormatters } from '@/Utils/Formatting';

const { items, volumeLabel } = defineProps<{
  items: PercentageBarItem[];
  volumeLabel?: string;
}>();

const total = computed(() => items.reduce((sum, item) => sum + item.value, 0));

const fields = computed(() =>
  items.map(item => ({
    label: item.label,
    value: item.value,
    percentage: Math.ceil((item.value / total.value) * 100),
    colour: item.colour,
  })),
);

const maxPercentage = computed(() => Math.max(...fields.value.map(field => field.percentage)));

const { formatPercentage, formatNumber } = useFormatters();
const translate = useTranslate();

const label = volumeLabel ?? translate.translate('global.graphs.legends.volume');

const tooltip = fields.value.reduce((acc, field) => {
  acc += `
    <span class="inline-flex items-center whitespace-nowrap mt-0.5">
      <span class="h-3 w-3 rounded-full mr-3.5" style="background: ${field.colour};"></span>
      ${field.label}: ${formatPercentage((field.value / total.value) * 100)}, ${label}: ${formatNumber(field.value)}
    </span>`;
  return acc;
}, `${label}: ${formatNumber(total.value)}`);

</script>

<template>
  <div
    v-tooltip.top="{value: tooltip, escape: false, pt: {text:'w-fit relative left-1/2 -translate-x-1/2'}}"
    class="flex w-full text-xs text-center text-white rounded overflow-hidden">
    <div
      v-for="(item, index) in fields"
      :key="index"
      :style="{ 'background': item.colour, 'width': `${item.percentage}%`}"
    >
      <div v-if="maxPercentage === item.percentage" class="mt-0.5 py-0.5">
        {{ formatPercentage(item.percentage) }}
      </div>
    </div>
  </div>
</template>
