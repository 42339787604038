<script setup lang="ts">
import AppContainer from '@/Layouts/Partials/AppContainer.vue';
import { Head } from '@inertiajs/vue3';
import Toast from 'primevue/toast';
import AppMenu from '@/Layouts/Partials/AppMenu.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';
import { UiRestriction } from '@/types/types.gen';

const { withMenu = true } = defineProps<{
  title: string;
  withMenu?: boolean;
}>();

const isFixedWidth = useFeatureRestriction(UiRestriction.FIXED_WIDTH);
const isTopMenuHidden = useFeatureRestriction(UiRestriction.NO_TOP_MENU);

const needsMenu = withMenu && !isTopMenuHidden;
</script>

<template>
  <Head :title="title" />
  <Toast position="top-center" />
  <ConfirmDialog />
  <AppContainer>

    <div
      :class="{'max-w-screen-2xl': isFixedWidth}"
      class="flex w-full flex-col mx-auto">
      <AppMenu v-if="needsMenu" />
      <slot />
    </div>
  </AppContainer>
</template>
