<script lang="ts" setup>
import { type AggregateReportResource, type SenderIpAddressStatisticsResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from '@primevue/core/api';
import { useFormatters } from '@/Utils/Formatting';
import { reactive } from 'vue';
import {
  getDmarcResult,
  groupAggregateReports,
} from '@/Pages/Statistics/Sender/Helpers/AggregateReportHelpers';
import { each, kebabCase, orderBy } from 'lodash';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';
import ActionAppliedTag from '@/Pages/Statistics/IpAddress/Partials/ActionAppliedTag.vue';
import OverridesPercentageBar from '@/Pages/Statistics/IpAddress/Partials/OverridesPercentageBar.vue';
import type { OverrideTotals, RuaRecord, RuaReport, Summary } from '@/Pages/Statistics/IpAddress/types';
import Card from 'primevue/card';

const { aggregateReports } = defineProps<{
  senderIpAddress: SenderIpAddressStatisticsResource;
  aggregateReports: AggregateReportResource[];
}>();

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const { formatNumber } = useFormatters();
const updateTotals = (report: RuaReport, record: RuaRecord) => {
  overrideTotals.overriddenTotal += record.count;

  if (['quarantine', 'reject'].indexOf(record.policy_evaluated_disposition) === -1) {
    overrideTotals.noneTotal += record.count;
  } else if (record.policy_evaluated_disposition === 'quarantine') {
    overrideTotals.quarantineTotal += record.count;
  } else if (record.policy_evaluated_disposition === 'reject') {
    overrideTotals.rejectTotal += record.count;
  }
};

let overrideTotals: OverrideTotals = { overriddenTotal: 0, noneTotal: 0, quarantineTotal: 0, rejectTotal: 0 };

const groupReports = Object.values(groupAggregateReports(aggregateReports));
const resultSummary: Record<string, Summary> = groupReports.reduce((acc, item) => {
  each(item.records, (record: RuaRecord) => {
    if (!record.policy_evaluated_override_reason_type && !record.policy_evaluated_override_reason_comment) {
      return;
    }

    const index = kebabCase([
      item.organisation,
      item.published_policy_p,
      record.policy_evaluated_disposition,
      record.policy_evaluated_dkim,
      record.policy_evaluated_spf,
      record.policy_evaluated_override_reason_type,
      record.policy_evaluated_override_reason_comment,
    ].join('-'));

    updateTotals(item, record);

    acc[index] ||= {
      count: 0,
      organisation: item.organisation,
      dmarc_result: getDmarcResult(record),
      published_policy_p: item.published_policy_p,
      policy_evaluated_disposition: record.policy_evaluated_disposition,
      override_type: record.policy_evaluated_override_reason_type,
      comment: record.policy_evaluated_override_reason_comment,
    };

    acc[index].count += record.count;
  });

  return acc;
}, {} as Record<string, Summary>);

const summary = orderBy(resultSummary, 'count', 'desc');

</script>
<template>
  <div class="flex justify-between gap-8 mb-6">
    <Card class="w-full !bg-section-default">
      <template #title>
        {{ $t('ip_address_modal.overrides.volume_overridden_policy') }}
      </template>
      <template #content>
        <div class="text-lg">
          {{ formatNumber(overrideTotals.overriddenTotal) }} / {{ formatNumber(senderIpAddress?.total_incidents ?? 0) }}
        </div>
      </template>
    </Card>
    <Card class="w-full !bg-section-default">
      <template #title>
        {{ $t('ip_address_modal.overrides.overridden_policy_breakdown') }}
      </template>
      <template #content>
        <div class="text-lg">
          <OverridesPercentageBar
            :data="{ none_total: overrideTotals.noneTotal, quarantine_total: overrideTotals.quarantineTotal, reject_total: overrideTotals.rejectTotal }"
          />
        </div>
      </template>
    </Card>
  </div>
  <DataTable
    v-model:filters="state.filters"
    :value="Object.values(summary)"
    data-key="uuid"
    paginator
    :expanded-rows="summary"
    @row-expand="(summary_row) => summary_row.expanded = true"
    @row-collapse="(summary_row) => summary_row.expanded = false"
    :global-filter-fields="['spf_domain', 'dkim_domain', 'dmarc_result']"
    :sort-order="1"
    sort-field="spf_domain"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
  >
    <template #empty>
      {{ $t('tables.not_found') }}
    </template>
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex flex-grow">
          <IconField>
            <InputIcon>
              <i class="pi pi-search" />
            </InputIcon>
            <InputText
              :placeholder="$t('tables.search')"
              v-model="state.filters.global.value"
            />
          </IconField>
        </div>
      </div>

    </template>

    <Column
      :sortable="true"
      field="count"
      :header="$t('ip_address_modal.table.volume.header')"
    >
      <template #body="{ data }">
        {{ formatNumber(data.count) }}
      </template>
    </Column>

    <Column
      field="spf_domain"
      :header="$t('ip_address_modal.table.reporter.header')"
    >
      <template #body="{data}">
        {{ data.organisation }}
      </template>
    </Column>

    <Column
      field="dmarc_result"
      :header="$t('ip_address_modal.table.dmarc.results.header')"
    >
      <template #body="{data}">
        <i v-if="data.dmarc_result === 'pass'"
           class="pi pi-check-circle text-success-default"
           v-tooltip.top="$t('ip_address_modal.table.dmarc.results.pass_tooltips.yes')"></i>
        <i v-else
           class="pi pi-times-circle text-danger-default"
           v-tooltip.top="$t('ip_address_modal.table.dmarc.results.pass_tooltips.no')"></i>
      </template>
    </Column>

    <Column
      field="published_policy_p"
    >
      <template #header>
        <span class="font-bold">{{ $t('ip_address_modal.table.published_policy.header') }}</span>
        <i class="pi pi-info-circle pr-2"
           v-tooltip.top="$t('ip_address_modal.table.published_policy.info.tooltip')"></i>
      </template>
      <template #body="{data}">
        <DmarcPolicyTag :policy="data.published_policy_p" :show-tooltip="true" />
      </template>
    </Column>

    <Column
      field="policy_evaluated_disposition"
    >
      <template #header>
        <span class="font-bold">{{ $t('ip_address_modal.table.action_applied.header') }}</span>
        <i class="pi pi-info-circle pr-2"
           v-tooltip.top="$t('ip_address_modal.table.action_applied.info.tooltip')"></i>
      </template>
      <template #body="{data}">
        <ActionAppliedTag :policy="data.policy_evaluated_disposition" />
      </template>
    </Column>
    <Column
      field="policy_evaluated_override_reason_type"
      :header="$t('ip_address_modal.table.override_type.header')"
    >
      <template #body="{data}">
        {{ data.override_type }}
      </template>
    </Column>

    <Column
      field="policy_evaluated_override_reason_comment"
      :header="$t('ip_address_modal.table.override_comment.header')"
    >
      <template #body="{data}">
        {{ data.comment }}
      </template>
    </Column>

  </DataTable>
</template>
