<script setup lang="ts">
import type { DomainResource } from '@/types/types.gen';
import Tag from 'primevue/tag';
import DomainsSettingsMenu from '@/Pages/Domains/Partials/DomainsSettingsMenu.vue';
import { route } from 'ziggy-js';
import LogsDrawer from '@/Pages/Logs/LogsDrawer.vue';
import { reactive } from 'vue';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const state = reactive({
  logs: {
    visible: false,
    path: '',
  },
});

const openLogsDrawer = (domain: DomainResource) => {
  state.logs = {
    visible: true,
    path: route('api.logs.domain', [
      domain.customer_uuid,
      domain.uuid,
    ]),
  };
}
</script>

<template>
  <div class="flex items-center justify-between pb-8">
    <div class="flex items-center gap-3">
      <DomainAvatar :domain="domain" />
      <div class="text-xl font-bold" v-text="domain.domain_name" />
    </div>
    <div class="flex items-center">
      <Tag
        v-if="domain.is_favorite"
        :value="$t('domains.tags.primary.value')"
        severity="info"
        class="mr-4"
        icon="pi pi-star"
      />
      <Tag
        class="mr-1 capitalize"
        :value="domain.type === 'parked' ? $t('domains.tags.type.parked.value') : $t('domains.tags.type.active.value')"
        :severity="domain.type === 'parked' ? 'secondary' : 'success'"
      />
      <DomainsSettingsMenu
        @show-logs="openLogsDrawer"
        severity="secondary"
        :domain="domain"
        :is-text="true"
      />
    </div>
    <LogsDrawer
      @hide="state.logs.visible = false"
      v-if="state.logs.visible"
      :path="state.logs.path"
      :header="$t('logs.header', {
        type: $t('domains.logs_drawer.header')
      })"
    />
  </div>
</template>
