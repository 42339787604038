<script setup lang="ts">
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import Layout from '@/Pages/Dns/Setup/Layout.vue';
import {
  type DnsDelegationVerificationResource, DnsDelegationVerificationType,
  type MtaStsSetupInstructionsResource,
  type TlsRptSetupInstructionsResource,
} from '@/types/types.gen';
import type { SignedRoutes } from '@/Pages/Dns/types/types';
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import { computed, ref } from 'vue';
import VerificationStatus from '@/Components/VerificationStatus.vue';
import PublicVerifyButton from '@/Components/PublicVerifyButton.vue';
import SelectButton from 'primevue/selectbutton';

const { verifications, verifyRoutes } = defineProps<{
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
  tlsRptSetupInstruction: TlsRptSetupInstructionsResource;
  signedRoutes: SignedRoutes;
  verifications: Partial<Record<DnsDelegationVerificationType, DnsDelegationVerificationResource>>;
  verifyRoutes: Partial<Record<DnsDelegationVerificationType, string>>;
}>();

const verificationsCollection = computed((): {
  tlsRpt: DnsDelegationVerificationResource;
  mtaStsRecord: DnsDelegationVerificationResource;
  mtaStsPolicy: DnsDelegationVerificationResource;
} => ({
  tlsRpt: Object.values(verifications).find(v => v.type === DnsDelegationVerificationType.TLSRPT)!,
  mtaStsRecord: Object.values(verifications).find(v => v.type === DnsDelegationVerificationType.MTA_STS)!,
  mtaStsPolicy: Object.values(verifications).find(v => v.type === DnsDelegationVerificationType.MTA_STS_POLICY)!,
}));

const verifyRoutesCollection = computed(() => ({
  tlsRpt: verifyRoutes[DnsDelegationVerificationType.TLSRPT]!,
  mtaStsRecord: verifyRoutes[DnsDelegationVerificationType.MTA_STS]!,
  mtaStsPolicy: verifyRoutes[DnsDelegationVerificationType.MTA_STS_POLICY]!,
}));

const selectedTab = ref('tls-rpt')

</script>

<template>
  <Layout
    :layout="mtaStsSetupInstruction"
    :signed-routes="signedRoutes"
    :tab="DomainSettingsTab.TLS"
  >
    <SelectButton
      class="mb-8"
      v-model="selectedTab"
      :allow-empty="false"
      :options="[
        { label: $t('configurations.tls_rpt.heading'), value: 'tls-rpt' },
        { label: $t('configurations.mta_policy.heading'), value: 'mta-policy' },
        { label: $t('configurations.mta_record.heading'), value: 'mta-record' }
      ]"
      optionLabel="label"
      optionValue="value"
    />
    <div class="flex flex-col gap-10">
      <SetupInstructionsCard
        :title="$t('configurations.tls_rpt.heading')"
        :description="$t('configurations.tls_rpt.description')"
        v-if="selectedTab === 'tls-rpt'"
      >
        <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
          <SetupInstructions
            host="_smtp._tls"
            rd-type="CNAME"
            :value="tlsRptSetupInstruction.tls_rpt_record"
            show-copy-button
          />
        </div>
        <template #footer>
          <div class="flex justify-between mt-2">
            <VerificationStatus :verification="verificationsCollection.tlsRpt"/>
            <PublicVerifyButton :path="verifyRoutesCollection.tlsRpt"/>
          </div>
        </template>
      </SetupInstructionsCard>

      <SetupInstructionsCard
        :title="$t('configurations.mta_policy.heading')"
        :description="$t('configurations.mta_record.description')"
        v-if="selectedTab === 'mta-record'"
      >
        <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
          <SetupInstructions
            host="_mta-sts"
            rd-type="CNAME"
            :value="mtaStsSetupInstruction.mta_policy"
            show-copy-button
          />
        </div>
        <template #footer>
          <div class="flex justify-between mt-2">
            <VerificationStatus :verification="verificationsCollection.mtaStsRecord"/>
            <PublicVerifyButton :path="verifyRoutesCollection.mtaStsRecord"/>
          </div>
        </template>
      </SetupInstructionsCard>

      <SetupInstructionsCard
        :title="$t('configurations.mta_policy.heading')"
        :description="$t('configurations.mta_policy.description')"
        v-if="selectedTab === 'mta-policy'"
      >
        <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
          <SetupInstructions
            host="mta-sts"
            rd-type="CNAME"
            :value="mtaStsSetupInstruction.mta_host"
            show-copy-button
          />
        </div>
        <template #footer>
          <div class="flex justify-between mt-2">
            <VerificationStatus :verification="verificationsCollection.mtaStsPolicy"/>
            <PublicVerifyButton :path="verifyRoutesCollection.mtaStsPolicy"/>
          </div>
        </template>
      </SetupInstructionsCard>
    </div>
  </Layout>
</template>
