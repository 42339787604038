import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes';

export default definePreset(Lara, {
  components: {
    datatable: {
      header: {
        borderWidth: '0',
        padding: '0 0 1rem 0',
      },
      colorScheme: {
        light: {
          header: {
            background: 'transparent',
          },
          headerCell: {
            background: '{content.background}',
            borderColor: '{content.borderColor}',
            hoverBackground: 'color-mix(in srgb, {surface.500}, transparent 95%)',
            selectedBackground: 'color-mix(in srgb, {surface.500}, transparent 90%)',
          },
          bodyCell: {
            borderColor: '{content.borderColor}',
          },
        },
        dark: {
          header: {
            background: 'transparent',
          },
          headerCell: {
            background: '{content.background}',
            borderColor: '{content.borderColor}',
            hoverBackground: 'color-mix(in srgb, {surface.500}, transparent 95%)',
            selectedBackground: 'color-mix(in srgb, {surface.500}, transparent 80%)',
          },
          bodyCell: {
            borderColor: '{content.borderColor}',
          },
        },
      },
    },
    breadcrumb: {
      background: 'transparent',
      padding: '0 1.25rem 1.5rem 1.25rem',
      item: {
        hoverColor: '{navigation.item.color}',
      },
    },
    tabs: {
      tab: {
        borderWidth: '0 0 2px 0',
      },
      tablist: {
        borderWidth: '0 0 1px 0',
        background: '{content.background}',
      },
      colorScheme: {
        light: {
          tab: {
            background: '{content.background}',
            hoverBackground: 'color-mix(in srgb, {surface.500}, transparent 90%)',
            activeBackground: '{content.background}',
            activeBorderColor: '{primary.500}',
            activeColor: '{primary.500}',
            color: '{text.tertiary}',
          },
        },
        dark: {
          tab: {
            background: '{content.background}',
            hoverBackground: 'color-mix(in srgb, {surface.500}, transparent 90%)',
            activeBackground: '{content.background}',
            activeBorderColor: '{primary.500}',
            activeColor: '{primary.500}',
            color: '{text.tertiary}',
          },
        },
      },
    },
    tag: {
      root: {
        fontSize: '0.875rem',
        fontWeight: '500',
        padding: '0.25rem 0.5rem',
        gap: '0.25rem',
        borderRadius: '{content.border.radius}',
        roundedBorderRadius: '{border.radius.xl}',
      },
      icon: {
        size: '0.75rem',
      },
      colorScheme: {
        light: {
          primary: {
            background: '{primary.100}',
            color: '{primary.700}',
          },
          secondary: {
            background: '{surface.700}',
            color: '{surface.100}',
          },
          success: {
            background: '{success.background}',
            color: '{success.foreground}',
          },
          info: {
            background: '{info.background}',
            color: '{info.foreground}',
          },
          warn: {
            background: '{warn.background}',
            color: '{warn.foreground}',
          },
          danger: {
            background: '{danger.background}',
            color: '{danger.foreground}',
          },
          contrast: {
            background: '{contrast.background}',
            color: '{contrast.foreground}',
          },
        },
        dark: {
          primary: {
            background: 'color-mix(in srgb, {primary.500}, transparent 50%)',
            color: '{primary.200}',
          },
          secondary: {
            background: 'color-mix(in srgb, {surface.500}, transparent 50%)',
            color: '{surface.200}',
          },
          success: {
            background: 'color-mix(in srgb, {success.default}, transparent 50%)',
            color: '{success.foreground}',
          },
          info: {
            background: 'color-mix(in srgb, {info.default}, transparent 50%)',
            color: '{info.foreground}',
          },
          warn: {
            background: 'color-mix(in srgb, {warn.default}, transparent 50%)',
            color: '{warn.foreground}',
          },
          danger: {
            background: 'color-mix(in srgb, {danger.default}, transparent 50%)',
            color: '{danger.foreground}',
          },
          contrast: {
            background: 'color-mix(in srgb, {contrast.default}, transparent 50%)',
            color: '{contrast.foreground}',
          },
        },
      },
    },
    card: {
      borderRadius: '{content.borderRadius}',
      shadow: 'none',
      body: {
        padding: '2.25rem',
      },
    },
    button: {
      borderRadius: '{border.radius.xl}',
      colorScheme: {
        light: {
          primary: {
            background: '{primary.500}',
            color: '{surface.0}',
            borderColor: '{primary.500}',
            hoverBackground: '{primary.400}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{primary.400}',
          },
          secondary: {
            background: '{surface.700}',
            color: '{surface.0}',
            borderColor: '{surface.700}',
            hoverBackground: '{surface.600}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{surface.600}',
          },
          link: {
            color: '{surface.0}',
            hoverColor: '{surface.0}',
          },
          outlined: {
            primary: {
              hoverBackground: '{primary.50}',
              color: '{primary.500}',
              hoverColor: '{primary.500}',
              borderColor: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.50}',
              color: '{surface.700}',
              hoverColor: '{surface.600}',
              borderColor: '{surface.700}',
            },
          },
          text: {
            primary: {
              hoverBackground: 'color-mix(in srgb, {primary.500}, transparent 80%)',
              color: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.50}',
              color: '{surface.700}',
            },
          },
        },
        dark: {
          primary: {
            background: '{primary.500}',
            color: '{surface.0}',
            borderColor: '{primary.500}',
            hoverBackground: '{primary.400}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{primary.400}',
          },
          secondary: {
            background: '{surface.600}',
            color: '{surface.100}',
            borderColor: '{surface.600}',
            hoverBackground: '{surface.500}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{surface.500}',
          },
          outlined: {
            primary: {
              hoverBackground: 'color-mix(in srgb, {primary.500}, transparent 80%)',
              color: '{primary.500}',
              hoverColor: '{primary.600}',
              borderColor: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.800}',
              color: '{surface.300}',
              hoverColor: '{surface.400}',
              borderColor: '{surface.300}',
            },
          },
          text: {
            primary: {
              hoverBackground: 'color-mix(in srgb, {primary.500}, transparent 80%)',
              color: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.800}',
              color: '{surface.300}',
            },
          },
        },
      },
    },
    menu: {
      background: '{content.background}',
      borderRadius: '{content.borderRadius}',
      item: {
        borderRadius: '{border.radius.md}',
      },
      colorScheme: {
        light: {
          background: '{content.background}',
          item: {
            activeBackground: '{surface.0}',
            focusBackground: '{surface.0}',
          },
        },
        dark: {
          background: '{content.background}',
          item: {
            activeBackground: '{surface.900}',
            focusBackground: '{surface.900}',
          },
        },
      },
    },
    menubar: {
      background: '{content.background}',
      borderRadius: '{content.borderRadius}',
      item: {
        borderRadius: '{border.radius.md}',
      },
      colorScheme: {
        light: {
          background: '{content.background}',
          item: {
            activeBackground: '{surface.0}',
            focusBackground: '{surface.0}',
          },
        },
        dark: {
          background: '{content.background}',
          item: {
            activeBackground: '{surface.900}',
            focusBackground: '{surface.900}',
          },
        },
      },
    },
    panel: {
      borderRadius: '{content.borderRadius}',
      header: {
        borderRadius: '{content.borderRadius} {content.borderRadius} 0 0',
      },
    },
    accordion: {
      colorScheme: {
        light: {
          header: {
            background: '{surface.50}',
            activeBackground: '{content.background}',
            hoverBackground: '{content.hoverBackground}',
            color: '{text.tertiary}',
          },
        },
        dark: {
          header: {
            background: '{surface.900}',
            activeBackground: '{content.background}',
            hoverBackground: '{content.hoverBackground}',
            color: '{text.tertiary}',
          },
        },
      },
    },
    fileupload: {
      header: {
        borderRadius: '{content.borderRadius} {content.borderRadius} 0 0',
      },
    },
    inputtext: {
      background: '{surface.0}',
      color: '{surface.950}',
    },
    textarea: {
      background: '{surface.0}',
      color: '{surface.950}',
    },
    select: {
      background: '{surface.0}',
      color: '{surface.950}',
    },
    radiobutton: {
      colorScheme: {
        light: {
          background: '{surface.0}',
          hoverBorderColor: '{surface.500}',
          checkedBorderColor: '{primary.500}',
          checkedBackground: '{surface.0}',
          checkedHoverBackground: '{surface.500}',
          checkedHoverBorderColor: '{surface.500}',
          icon: {
            checkedColor: '{primary.500}',
          },
        },
        dark: {
          background: '{surface.0}',
          hoverBorderColor: '{surface.500}',
          checkedBorderColor: '{surface.0}',
          checkedBackground: '{surface.0}',
          checkedHoverBackground: '{surface.500}',
          checkedHoverBorderColor: '{surface.500}',
          icon: {
            checkedColor: '{primary.500}',
          },
        },
      },
    },
    togglebutton: {
      checked: {
        background: '{primary.500}',
        color: '{surface.0}',
      },
      icon: {
        checkedColor: '{surface.0}',
      },
      colorScheme: {
        light: {
          background: '{surface.0}',
          color: '{surface.950}',
          hoverBackground: 'color-mix(in srgb, {surface.500}, transparent 95%)',
        },
        dark: {
          background: '{surface.0}',
          color: '{surface.950}',
          hoverBackground: '{surface.200}',
          hoverColor: '{surface.950}',
        },
      },
    },
  },
  primitive: {
    lime: {
      100: '#F7FADF',
      200: '#EAF1AE',
      300: '#E4EC95',
      400: '#D6E263',
      500: '#BCCF00',
      600: '#96A600',
      700: '#717C00',
      800: '#4B5300',
      900: '#383E00',
    },
    green: {
      50: '#EFFBDD',
      100: '#EFFBDD',
      200: '#D3E9B4',
      300: '#B7D78B',
      400: '#9CC463',
      500: '#80B23A',
      600: '#64A011',
      700: '#50800E',
      800: '#3C600A',
      900: '#284007',
    },
    red: {
      50: '#F9EBE9',
      100: '#F9EBE9',
      200: '#E5AFA7',
      300: '#D9887C',
      400: '#CC6050',
      500: '#BF3824',
      600: '#992D1D',
      700: '#732216',
      800: '#4C160E',
      900: '#260B07',
    },
    orange: {
      50: '#FEF5E5',
      100: '#FEF5E5',
      200: '#FCE1B2',
      300: '#F9C366',
      400: '#F7AF33',
      500: '#F59B00',
      600: '#C47C00',
      700: '#935D00',
      800: '#623E00',
      900: '#311F00',
    },
    blue: {
      50: '#E5F5FC',
      100: '#E5F5FC',
      200: '#B2E2F7',
      300: '#66C5EE',
      400: '#33B2E9',
      500: '#00A0E3',
      600: '#007FB6',
      700: '#005F88',
      800: '#00405B',
      900: '#00202D',
      950: '#00202D',
    },
    gray: {
      50: '#FCFCFC',
      100: '#F2F2F2',
      200: '#DEDEDE',
      300: '#C7C7C7',
      400: '#B3B3B3',
      500: '#9E9E9E',
      600: '#858585',
      700: '#6B6B6B',
      800: '#545454',
      900: '#3B3B3B',
      950: '#2F2F2F',
    },
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: '5px',
      md: '6px',
      lg: '8px',
      xl: '24px',
    },
  },
  semantic: {
    disabledOpacity: '0.6',
    primary: {
      50: '{lime.500}',
      100: '{lime.500}',
      200: '{lime.500}',
      300: '{lime.500}',
      400: '{lime.500}',
      500: '{lime.500}',
      600: '{lime.500}',
      700: '{lime.500}',
      800: '{lime.500}',
      900: '{lime.500}',
    },
    content: {
      borderRadius: '{border.radius.sm}',
    },
    formField: {
      borderRadius: '{border.radius.sm}',
    },
    colorScheme: {
      light: {
        surface: {
          0: '#FFFFFF',
          50: '{gray.50}',
          100: '{gray.100}',
          200: '{gray.200}',
          300: '{gray.300}',
          400: '{gray.400}',
          500: '{gray.500}',
          600: '{gray.600}',
          700: '{gray.700}',
          800: '{gray.800}',
          900: '{gray.900}',
          950: '{gray.950}',
        },
        primary: {
          color: '{primary.600}',
          contrastColor: '{surface.0}',
          hoverColor: '{primary.800}',
          activeColor: '{primary.700}',
        },
        text: {
          color: '{surface.900}',
          secondary: '{surface.700}',
          tertiary: '{surface.600}',
          hoverColor: '{primary.500}',
          focusColor: '{primary.500}',
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.500}, transparent 90%)',
          focusBackground: '{primary.700}',
          color: '{primary.500}',
          focusColor: '{primary.500}',
        },
        ground: {
          background: '{surface.0}',
        },
        content: {
          background: '{surface.100}',
          hoverBackground: '{surface.200}',
          borderColor: '{surface.200}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        section: {
          background: '{surface.50}',
          foreground: '{surface.0}',
        },
        graphs: {
          positive: '{green.600}',
          negative: '{red.500}',
          warning: '{orange.500}',
          fill: '{surface.500}',
          background: '{surface.200}',
          color1: '{blue.500}',
        },
        success: {
          default: '{green.500}',
          background: '{green.500}',
          foreground: '{green.100}',
        },
        warn: {
          default: '{orange.500}',
          background: '{orange.500}',
          foreground: '{orange.100}',
        },
        danger: {
          default: '{red.500}',
          background: '{red.500}',
          foreground: '{red.100}',
          darker: '{red.800}',
        },
        info: {
          default: '{blue.500}',
          background: '{blue.500}',
          foreground: '{blue.100}',
        },
        contrast: {
          default: '{surface.500}',
          background: '{surface.700}',
          foreground: '{surface.100}',
        },
        formField: {
          borderColor: '{surface.300}',
          background: '{surface.50}',
          disabledBackground: '{surface.200}',
          disabledColor: '{surface.400}',
          disabledBorderColor: '{surface.200}',
        },
        navigation: {
          item: {
            focusColor: '{primary.500}',
          },
        },
        list: {
          option: {
            selectedFocusColor: '{surface.0}',
          },
        },
      },
      dark: {
        surface: {
          0: '#FFFFFF',
          50: '{gray.50}',
          100: '{gray.100}',
          200: '{gray.200}',
          300: '{gray.300}',
          400: '{gray.400}',
          500: '{gray.500}',
          600: '{gray.600}',
          700: '{gray.700}',
          800: '{gray.800}',
          900: '{gray.900}',
          950: '{gray.950}',
        },
        primary: {
          color: '{primary.50}',
          contrastColor: '{primary.950}',
          hoverColor: '{primary.200}',
          activeColor: '{primary.300}',
          focusColor: '{primary.300}',
        },
        text: {
          color: '{surface.0}',
          secondary: '{surface.100}',
          tertiary: '{surface.200}',
          hoverColor: '{primary.500}',
          focusColor: '{primary.500}',
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.500}, transparent 80%)',
          focusBackground: '{primary.850}',
          color: '{primary.100}',
          focusColor: '{primary.100}',
        },
        ground: {
          background: '{surface.900}',
        },
        content: {
          background: '{surface.950}',
          hoverBackground: '{surface.700}',
          borderColor: '{surface.800}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        section: {
          background: '{surface.900}',
          foreground: '{surface.950}',
        },
        graphs: {
          positive: '{green.600}',
          negative: '{red.500}',
          warning: '{orange.500}',
          fill: '{surface.500}',
          background: '{surface.800}',
          color1: '{blue.600}',
        },
        success: {
          default: '{green.600}',
          background: '{green.700}',
          foreground: '{green.100}',
        },
        warn: {
          default: '{orange.500}',
          background: '{orange.500}',
          foreground: '{orange.100}',
        },
        danger: {
          default: '{red.500}',
          background: '{red.500}',
          foreground: '{red.100}',
          darker: '{red.800}',
        },
        info: {
          default: '{blue.500}',
          background: '{blue.900}',
          foreground: '{blue.100}',
        },
        contrast: {
          default: '{surface.500}',
          background: '{surface.900}',
          foreground: '{surface.100}',
        },
        formField: {
          borderColor: '{surface.700}',
          background: '{surface.950}',
          disabledBackground: '{surface.800}',
          disabledColor: '{surface.600}',
          disabledBorderColor: '{surface.800}',
        },
        navigation: {
          item: {
            focusColor: '{primary.500}',
          },
        },
        list: {
          option: {
            selectedFocusBackground: '{highlight.background}',
          },
        },
      },
    },
  },
});
