<script setup lang="ts">
import Button from 'primevue/button';
import Card from 'primevue/card';
import type { CustomerResource, DomainResource, TimelineStatisticsResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable, { type DataTableSortEvent } from 'primevue/datatable';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';
import { useFormatters } from '@/Utils/Formatting';
import { Link } from '@inertiajs/vue3';
import Legend from '@/Components/Legend.vue';
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import {
  createStatisticsTotals,
  getCompliancePercentage,
} from '@/Pages/Statistics/Sender/Helpers/TimelineStatisticsHelpers';
import ComplianceChart from '@/Pages/Statistics/Sender/Charts/ComplianceChart.vue';

const { domains, authorisedDomainStatistics, unAuthorisedDomainStatistics } = defineProps<{
  customer: CustomerResource;
  domains: DomainResource[];
  authorisedDomainStatistics: TimelineStatisticsResource[];
  unAuthorisedDomainStatistics: TimelineStatisticsResource[];
}>();

const formatter = useFormatters();

const emptyStat = {
  total_senders: 0,
  total_incidents: 0,
}

const domainStats = domains.map((domain: DomainResource) => {
  const authorised = authorisedDomainStatistics.find(({ domain_uuid }) => domain_uuid === domain.uuid) || emptyStat;
  const unAuthorised = unAuthorisedDomainStatistics.find(({ domain_uuid }) => domain_uuid === domain.uuid) || emptyStat;

  return {
    total_incidents: authorised.total_incidents + unAuthorised.total_incidents,
    authorised: authorised,
    unAuthorised: unAuthorised,
    ...domain,
  }
}).filter(({ authorised, unAuthorised }) => authorised !== emptyStat || unAuthorised !== emptyStat);

const sortField = ref<DataTableSortEvent['sortField']>('total_incidents');
const sortOrder = ref<DataTableSortEvent['sortOrder']>(
  -1);

const sortedData = computed(() => {
  const stats = orderBy(domainStats, [sortField.value], [sortOrder.value === -1 ? 'asc' : 'desc']);
  return stats.slice(0, 3);
});

const onSort = (event: DataTableSortEvent) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};
</script>

<template>
  <Card>
    <template #content>
      <DataTable
        :value="sortedData"
        :sortField="sortField"
        :sortOrder="sortOrder"
        data-key="uuid"
        @sort="onSort"
      >
        <template #empty>
          {{ $t('tables.not_found') }}
        </template>

        <Column
          header-class="w-3/12"
        >
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.header.domain')"
            />
          </template>
          <template #body="{ data } ">
            <div class="flex items-center">
              <DomainAvatar size="xlarge" :domain="data" />
              <div class="flex h-full flex-col justify-between ml-4">
                <div class=" text-lg text-color mb-0.5">
                  {{ data.domain_name }}
                </div>
                <div>
                  <DmarcPolicyTag
                    :policy="data.policy"
                  />
                </div>
              </div>
            </div>
          </template>
        </Column>

        <Column
          :sortable="true"
          field="total_incidents"
          header-class="w-1/12"
        >
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.header.volume')"
            />
          </template>
          <template #body="{ data }">
            <div class="text-lg">
              {{ formatter.formatNumber(data.authorised.total_incidents + data.unAuthorised.total_incidents) }}
            </div>
          </template>
        </Column>

        <Column header-class="w-3/12">
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.header.approved')"
            />
          </template>
          <template #body="{ data }">
            <div class="bg-section-default rounded w-full h-full py-3 px-4">

              <div class="flex items-center justify-between">
                <div>
                  <div class="text-xl" v-text="data.authorised.total_senders" />
                  <div class="text-md" v-text="$t('dashboard.domains.table.header.senders')" />
                </div>
                <div>
                  <div
                    class="text-xl"
                    v-text="formatter.formatNumber(data.authorised.total_incidents, '0.0a')"
                  />
                  <div class="text-md" v-text="$t('dashboard.domains.table.header.volume')" />

                </div>
                <ComplianceChart
                  v-if="data.authorised.total_incidents > 0"
                  size="small"
                  :data="[createStatisticsTotals(data.authorised)]"
                >
                  {{ formatter.formatPercentage(getCompliancePercentage(data.authorised), '0') }}
                </ComplianceChart>
                <ComplianceChart v-else size="small" :data="[]">0%</ComplianceChart>
              </div>
            </div>
          </template>
        </Column>

        <Column header-class="w-3/12">
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.header.unapproved')"
            />
          </template>
          <template #body="{ data }">
            <div class="bg-section-default rounded w-full h-full py-3 px-4">
              <div class="flex items-center min-h-[65px] justify-between">
                <div class="">
                  <div class="text-xl" v-text="data.unAuthorised.total_senders ?? 0" />
                  <div class="text-md" v-text="$t('dashboard.domains.table.header.senders')" />
                </div>
                <div>
                  <div
                    class="text-xl"
                    v-text="formatter.formatNumber(data.unAuthorised.total_incidents ?? 0, '0.0a')"
                  />
                  <div class="text-md" v-text="$t('dashboard.domains.table.header.volume')" />
                </div>
                <ComplianceChart
                  v-if="data.unAuthorised.total_incidents > 0"
                  size="small"
                  :data="[createStatisticsTotals(data.unAuthorised)]"
                >
                  {{ formatter.formatPercentage(getCompliancePercentage(data.unAuthorised), '0') }}
                </ComplianceChart>
                <ComplianceChart v-else size="small" :data="[]">0%</ComplianceChart>
              </div>
            </div>
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <div class="flex flex-row-reverse">
              <Link :href="route('ui.senders-statistics.index', [data.customer_uuid, data.uuid])">
                <Button
                  text
                  icon="pi pi-chart-bar"
                  :label="$t('dashboard.domains.table.action.view_report')"
                />
              </Link>
            </div>
          </template>
        </Column>

      </DataTable>

      <div class="flex mt-6 items-center justify-between">
        <div class="flex text-sm gap-6 py-3 px-4 bg-section-default w-3/4 rounded">
          <Legend :icon-size="2" :text="$t('dashboard.domains.legends.passing')" :icon-class="['bg-graphs-positive']" />
          <Legend :icon-size="2" :text="$t('dashboard.domains.legends.failing')" :icon-class="['bg-graphs-negative']" />
        </div>

        <Link :href="route('ui.domains.index', [customer.uuid])">
          <Button
            text
            icon="pi pi-angle-right"
            iconPos="right"
            size="large"
            :label="$t('dashboard.domains.action.view_all_domains')"
          />
        </Link>

      </div>
    </template>
  </Card>
</template>
