<script setup lang="ts">
import { type AlertsResource } from '@/types/types.gen';
import { inject } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import RadioButton from 'primevue/radiobutton';
import DatePicker from 'primevue/datepicker';
import FormGroup from '@/Components/FormGroup.vue';
import Button from 'primevue/button';
import { useFormatters } from '@/Utils/Formatting';

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;
const formatter = useFormatters();

const dateOptions = [
  'monthly',
  'two_monthly',
  'quarterly',
  'six_monthly',
  'Yearly',
]

const nextStep = () => {
  emits('completeStep', 'frequency');
};

</script>
<template>
  <div class="flex flex-col gap-8 px-5 py-4">

    <div>{{ $t('alerts.frequency.description') }}</div>
    <FormGroup :label="$t('alerts.fields.start_date.input_label')" class="w-80">
      <template #element>
        <DatePicker v-model="form.start_at" showIcon iconDisplay="input" pt:inputIconContainer:class="!-mt-3" :fluid=true />
      </template>
    </FormGroup>

    <FormGroup :label="$t('alerts.fields.frequency.input_label')">
      <template #element>
        <div class="flex flex-col gap-2">
          <div v-for="option in dateOptions" :key="option" class="flex items-center">
            <RadioButton v-model="form.frequency" :inputId="'id_' + option" name="dynamic" :value="option" />
            <label :for="'id_' + option" class="ml-2">{{ formatter.titleCase(option) }}</label>
          </div>
        </div>
      </template>
    </FormGroup>

    <div class="flex justify-end mr-5">
      <Button @click="nextStep()" severity="secondary" :label="$t('global.buttons.next')" size="small" />
    </div>

  </div>
</template>
