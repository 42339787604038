import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { App } from 'vue';
import { type PageProps } from '@inertiajs/core';
import { watch } from 'vue';

export const TranslatorPlugin = {
  install(app: App, props: PageProps) {
    const { auth } = props;

    const installPlugin = (newAuth: typeof auth) => {
      if (newAuth?.user) {
        const { language: { code } } = newAuth.user;
        const { translate } = useTranslate(code);
        app.config.globalProperties.$t = translate;
      }
    };

    installPlugin(auth);

    watch(() => auth, installPlugin);
  },
};
