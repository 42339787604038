<script lang="ts" setup>
import type { AggregateReportResource, SenderIpAddressStatisticsResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import { FilterMatchMode } from '@primevue/core/api';
import { useFormatters } from '@/Utils/Formatting';
import ShowMore from '@/Components/ShowMore.vue';
import ComplianceLegend from '@/Pages/Statistics/Sender/Partials/ComplianceLegend.vue';
import { computed, reactive } from 'vue';
import { isFailing, isForward, isPassing } from '@/Pages/Statistics/Sender/Helpers/AggregateReportHelpers';
import type { Reporter } from '@/Pages/Statistics/IpAddress/types';

const { aggregateReports, senderIpAddress } = defineProps<{
  aggregateReports: AggregateReportResource[];
  senderIpAddress: SenderIpAddressStatisticsResource;
}>();

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const { formatNumber, formatPercentage } = useFormatters();

let total = 0;

const reporters = computed(() => aggregateReports.reduce((acc, item) => {
  const index = item.organisation;
  total += item.count;
  acc[index] ||= {
    rid: item.rid,
    expanded: false,
    organisation: item.organisation,
    total_incidents: 0,
    total_passing: 0,
    total_forwards: 0,
    total_failing: 0,
    envelope_to: new Set(),
    envelope_from: new Set(),
    header_from: new Set(),
  };

  const record = acc[index];
  record.total_incidents += item.count;
  record.total_passing += isPassing(item, { category: senderIpAddress.category! }) ? item.count : 0;
  record.total_forwards += isForward(item, { category: senderIpAddress.category! }) ? item.count : 0;
  record.total_failing += isFailing(item) ? item.count : 0;
  if (item.identifiers_envelope_to)
    record.envelope_to.add(item.identifiers_envelope_to);
  if (item.identifiers_envelope_from)
    record.envelope_from.add(item.identifiers_envelope_from);
  if (item.identifiers_header_from)
    record.header_from.add(item.identifiers_header_from);

  return acc;
}, {} as Record<string, Reporter>));
</script>

<template>
  <DataTable
    v-model:filters="state.filters"
    :value="Object.values(reporters)"
    data-key="rid"
    paginator
    :expanded-rows="reporters"
    @row-expand="(reporter) => reporter.expanded = true"
    @row-collapse="(reporter) => reporter.expanded = false"
    :global-filter-fields="['organisation']"
    :sort-order="-1"
    sort-field="total_incidents"
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
  >
    <template #empty>
      {{ $t('tables.not_found') }}
    </template>
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex flex-grow">
          <IconField>
            <InputIcon>
              <i class="pi pi-search" />
            </InputIcon>
            <InputText
              :placeholder="$t('tables.search')"
              v-model="state.filters.global.value"
            />
          </IconField>
        </div>
        <div class="flex gap-6 text-sm bg-section-default px-4 py-3 rounded">
          <ComplianceLegend />
        </div>
      </div>

    </template>
    <Column expander class="w-12" />
    <Column
      :sortable="true"
      field="organisation"
      :header="$t('ip_address_modal.reporters.table.header.reporter')"
    />
    <Column
      :sortable="true"
      field="total_incidents"
      :header="$t('ip_address_modal.reporters.table.header.volume')"
    >
      <template #body="{ data }">
        {{ formatNumber(data.total_incidents) }}
      </template>
    </Column>

    <Column
      :sortable="true"
      field="percentage"
      :header="$t('ip_address_modal.reporters.table.header.percent_of_total')"
    >
      <template #body="{data}">
        {{ total ? formatPercentage(data.total_incidents / total) : null }}
      </template>
    </Column>

    <Column
      :header="$t('ip_address_modal.reporters.table.header.compliance')"
    >
      <template #body="{ data }">
        <CompliancePercentageBar
          :data="data"
        />
      </template>
    </Column>

    <template #expansion="{data}">
      <div class="bg-section-default mx-16 my-6 flex flex-col gap-3">
        <div v-if='data.envelope_to.size > 0' class="flex items-center gap-4">
          <div class=" flex shrink-0 font-semibold w-1/12"
               v-text="$t('ip_address_modal.reporters.table.header.envelope_to')"
          />
          <ShowMore :set="data.envelope_to" :initial-count="10" />
        </div>
        <div v-if='data.envelope_from.size > 0' class="flex items-center gap-4">
          <div class="flex shrink-0 font-semibold w-1/12"
               v-text="$t('ip_address_modal.reporters.table.header.envelope_from')"
          />
          <ShowMore :set="data.envelope_from" :initial-count="10" />
        </div>
        <div v-if='data.header_from.size > 0' class="flex items-center gap-4">
          <div
            class="flex shrink-0 font-semibold w-1/12"
            v-text="$t('ip_address_modal.reporters.table.header.header_from')"
          />
          <ShowMore :set="data.header_from" :initial-count="10" />
        </div>
      </div>
    </template>

  </DataTable>
</template>
