<script setup lang="ts">
import { AlertFrequency, AlertScope, AlertType, type CustomerResource, UiRestriction } from '@/types/types.gen';
import AlertForm from '@/Pages/Alerts/Partials/AlertForm.vue';

import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/vue3';
import type { AlertCreateForm } from '@/Pages/Alerts/form';
import { provide, ref } from 'vue';
import { useRoute } from 'ziggy-js';
import { useFormatters } from '@/Utils/Formatting';
import { fromUnixTime } from 'date-fns';
import Breadcrumb from 'primevue/breadcrumb';
import { useToast } from 'primevue/usetoast';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';

const toast = useToast();
const { translate } = useTranslate();

const { customer } = defineProps<{
  customer: CustomerResource;
}>();

const formatter = useFormatters();
const form = useForm<AlertCreateForm>({
  type: AlertType.DOMAIN_CREATED,
  typeConfig: null,
  scope: AlertScope.CUSTOMER,
  channels: {
    mail: {
      enabled: true,
      recipients: [],
      settings: {
        from_name: '',
        enable_subject_tags: false,
      },
    },
  },
  frequency: AlertFrequency.IMMEDIATE,
  conditions: null,
  options: {
    link_expiry: {
      expire: 2592000, // This is hard coded for now
      period: '1 month', // This is hard coded for now
    },
  },
  is_enabled: true,
  start_at: formatter.formatDate(fromUnixTime(Date.now() / 1000)),
});
provide('form', form);

const submit = () => {
  const route = useRoute();
  form.post(
    route('ui.alerts.store', {
      customer: customer.uuid,
    }),
    {
      onError: (data) => {
        if (data.type) {
          toast.add({
            severity: 'error',
            summary: translate('global.form.failed.title'),
            detail: data.type,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: translate('global.form.failed.title'),
            detail: translate('global.form.failed.description'),
          });
        }
      },
    },
  );
};

const items = ref([
  { label: translate('alerts.listing.title') },
  { label: translate('alerts.create.title') },
]);

const isBreadcrumbsHidden = useFeatureRestriction(UiRestriction.NO_BREADCRUMBS);

</script>

<template>

  <AppLayout :title="$t('alerts.create.title')">
  <Breadcrumb
      v-if="!isBreadcrumbsHidden"
      :home="{
        icon: 'pi pi-home'
      }"
      :model="items"
    />
    <AlertForm @submit="submit" :customer="customer" />

  </AppLayout>

</template>
