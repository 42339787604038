import oem from '@/Themes/oem';
import hornet from '@/Themes/hornet';

export default function getTheme(theme: string) {
  return {
    preset: theme === 'oem' ? oem : hornet,
    options: {
      darkModeSelector: '.g-theme-dark',
    },
  };
}
