import { useFormatters } from '@/Utils/Formatting/index';
import { type App, watch } from 'vue';
import type { PageProps } from '@inertiajs/core';

export const FormattingPlugin = {
  install(_app: App, props: PageProps) {
    const { auth } = props;

    const installPlugin = (newAuth: typeof auth) => {
      if (newAuth?.user) {
        const { language: { locale } } = newAuth.user;
        // Setup localization
        useFormatters(locale)
      }
    };

    installPlugin(auth);

    watch(() => auth, installPlugin);
  },
};
