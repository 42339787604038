<script setup lang="ts">
import {
  type DomainResource,
  SenderCategory,
  type SenderIpAddressStatisticsResource,
  SenderReportCategory,
  type SenderResource,
  type TimelineStatisticsResource,
} from '@/types/types.gen';

import { computed, reactive } from 'vue';
import ComplianceReport from '@/Pages/Statistics/Sender/Partials/Reports/ComplianceReport.vue';
import DeliverabilityReport from '@/Pages/Statistics/Sender/Partials/Reports/DeliverabilityReport.vue';
import ReputationReport from '@/Pages/Statistics/Sender/Partials/Reports/ReputationReport.vue';
import type { BasicSender, GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import { SenderDataAggregator, SenderIpAddressDataAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';

const props = defineProps<{
  senderCategory: SenderCategory;
  reportCategory: SenderReportCategory;
  senders: SenderResource[];
  timelineStatistics: TimelineStatisticsResource[];
  aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[];
  domain: DomainResource;
  customerUuid: string;
}>();

const report = ({
  [SenderReportCategory.COMPLIANCE]: ComplianceReport,
  [SenderReportCategory.DELIVERABILITY]: DeliverabilityReport,
  [SenderReportCategory.REPUTATION]: ReputationReport,
  [SenderReportCategory.THREATS]: null,
})[props.reportCategory];

const senders = computed(() => {
  const senders: BasicSender[] = props.senders;

  if (props.aggregatedTimelineStatistics.some(stat => !stat.sender_uuid)) {
    senders.push({
      uuid: null,
      organization: 'Other',
      service: 'other',
      type: 'other',
    });
  }

  return senders;
});

const groups = computed(() => {
  return senders.value.flatMap(
    sender => new SenderDataAggregator(sender, props.aggregatedTimelineStatistics)
      .groupedData,
  )
    .filter((group: GroupedStatistics) => props.senderCategory === SenderCategory.ALL || group.category === props.senderCategory)
    .filter((group: GroupedStatistics) => group.total_incidents > 0);
});

const state = reactive({
  groups: groups,
  senders: senders,
});

const ipStatisticsPerSender = computed<Map<string | null, GroupedStatistics[]>>(() => {
  const groupedData = new SenderIpAddressDataAggregator(props.aggregatedTimelineStatistics)
    .bySenders(state.senders)

  groupedData.forEach(
    (ipStatistics, senderUuid) => {
      groupedData.set(
        senderUuid,
        ipStatistics.filter(
          ipRow => props.senderCategory === SenderCategory.ALL || ipRow.category === props.senderCategory,
        ),
      );
    });

  return groupedData;
});

</script>

<template>
  <Component
    :is="report"
    :groups="state.groups"
    :timeline-statistics="timelineStatistics"
    :ip-statistics-per-sender="ipStatisticsPerSender"
    :aggregated-timeline-statistics="aggregatedTimelineStatistics"
    :domain="domain"
    :customer-uuid="customerUuid"
  />
</template>
