import {
  SenderCategory,
  type SenderIpAddressStatisticsResource,
  ThreatLevel,
  type TimelineStatisticsResource,
} from '@/types/types.gen';
import type { BasicSender, GroupedStatistics, HasBlacklistings, HasTotals, StatisticsTotals } from '../types';
import { groupBy, sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import {
  totalCompliant,
  totalBlocked,
  totalDelivered,
  totalNotDelivered,
  totalFailing,
  totalPassing,
  totalCleanIncidents,
} from '@/Pages/Statistics/Sender/Helpers/TimelineStatisticsHelpers';

export class SenderDataAggregator {
  constructor(
    private readonly sender: BasicSender,
    private readonly aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[],
  ) {}

  get groupedData(): GroupedStatistics[] {
    const groups: Record<string, GroupedStatistics> = {};
    const senderStatistics = this.senderStatistics;

    for (const ipAddressStatistics of senderStatistics) {
      const index = this.makeGroupIndex(ipAddressStatistics);

      groups[index] = !Object.prototype.hasOwnProperty.call(groups, index)
        ? this.makeGroupRow(ipAddressStatistics)
        : this.addToGroupRow(ipAddressStatistics, groups[index]);
    }

    return Object.values(groups);
  }

  private get senderStatistics(): Array<SenderIpAddressStatisticsResource> {
    return this.aggregatedTimelineStatistics.filter(
      ({ sender_uuid }) => sender_uuid === this.sender.uuid,
    );
  }

  private makeGroupIndex(statistics: SenderIpAddressStatisticsResource) {
    const fallback = 'Unknown';
    const organization = statistics.organization !== 'Private Network'
      ? statistics.organization
      : fallback;

    return statistics.sender_uuid
      ?? statistics.asn
      ?? organization
      ?? fallback;
  }

  private makeGroupRow(
    statistics: SenderIpAddressStatisticsResource,
  ): GroupedStatistics {
    return {
      ...makeSummedIpAddressTotals([statistics]),
      uuid: statistics.uuid,
      sender_uuid: statistics.sender_uuid,
      sender_logo: statistics.sender_logo,
      category: statistics.category ?? SenderCategory.UNKNOWN,
      asn: Number(statistics.asn),
      organisation: this.sender.organization,
      service: this.sender.service,
      isp: statistics.organization,
      service_type: this.sender.type,
      has_threat: this.isThreat(statistics),
      total_sender_ip_addresses: 1,
      total_clean_sender_ip_addresses: statistics.is_blacklisted ? 0 : 1,
      country: statistics.country,
      country_name: statistics.country_name,
      host: statistics.host,
      ip_address: statistics.ip_address,
      blacklistings: { ...(
        Array.isArray(statistics.blacklistings)
          ? {}
          : statistics.blacklistings
      ) },
      total_blacklistings: Object.keys(statistics.blacklistings).length,
    };
  }

  private addToGroupRow(
    statistics: SenderIpAddressStatisticsResource,
    group: GroupedStatistics,
  ) {
    const groupedData = makeSummedIpAddressTotals([statistics]);

    for (const key in groupedData) {
      const keyCast = key as keyof StatisticsTotals;
      group[keyCast] += groupedData[keyCast];
    }

    group.blacklistings = mergeBlacklistings(group, statistics);
    group.total_blacklistings = Object.keys(group.blacklistings).length;
    group.total_sender_ip_addresses += 1;
    group.total_clean_sender_ip_addresses += statistics.is_blacklisted ? 0 : 1;
    group.has_threat = group.has_threat || this.isThreat(statistics);

    return group;
  }

  private isThreat(statistics: SenderIpAddressStatisticsResource) {
    return Boolean(statistics.threat_level && statistics.threat_level !== ThreatLevel.LOW);
  }
}

export class DailyStatisticsAggregator {
  constructor(
    private readonly statistics: TimelineStatisticsResource[],
  ) {}

  get groupedData(): Record<string, StatisticsTotals> {
    const { formatDate } = useFormatters();

    return Object.entries(groupBy(this.statistics, 'date'))
      .reduce((prev, [date, stats]) => {
        prev[formatDate(date as unknown as Date)] = makeSummedIpAddressTotals(stats);

        return prev;
      }, {} as Record<string, StatisticsTotals>);
  }
}

export class SenderIpAddressDataAggregator {
  constructor(
    private readonly senderIpAddressStatistics: SenderIpAddressStatisticsResource[],
  ) {
  }

  bySenders(senders: BasicSender[]): Map<string | null, GroupedStatistics[]> {
    return new Map(
      senders.map((sender) => {
        const senderStatistics: SenderIpAddressStatisticsResource[] = this.senderIpAddressStatistics.filter(({ sender_uuid }) => sender_uuid === sender.uuid);
        const groupedData = new SenderDataAggregator(sender, senderStatistics).groupedData;

        return [sender.uuid, groupedData] as const;
      }),
    );
  }

  byIpAddress(): SenderIpAddressStatisticsResource[] {
    return Object.values(groupBy(this.senderIpAddressStatistics, 'ip_address'))
      .map((stats) => {
        const blackListings = stats.reduce((carry: HasBlacklistings, statistics: SenderIpAddressStatisticsResource) => {
          return {
            blacklistings: mergeBlacklistings(carry, stats[0]),
            total_blacklistings: carry.total_blacklistings + Object.keys(statistics.blacklistings).length,
            total_clean_sender_ip_addresses: carry.total_clean_sender_ip_addresses + (statistics.is_blacklisted ? 0 : 1),
          };
        }, { blacklistings: {} } as HasBlacklistings);

        // These could be null across some in the collection, so lets gather the non-nulls
        const nullPluckedFields: Partial<SenderIpAddressStatisticsResource> = stats.reduce(
          (carry: Partial<SenderIpAddressStatisticsResource>, statistics: SenderIpAddressStatisticsResource) => ({
            domain_uuid: carry.domain_uuid ?? statistics.domain_uuid,
            sender_uuid: carry.sender_uuid ?? statistics.sender_uuid,
            sender_logo: carry.sender_logo ?? statistics.sender_logo,
            organization: carry.organization ?? statistics.organization,
            type: carry.type ?? statistics.type,
            category: carry.category ?? statistics.category,
            asn: carry.asn ?? statistics.asn,
            host: carry.host ?? statistics.host,
            country: carry.country ?? statistics.country,
          }), {} as Partial<SenderIpAddressStatisticsResource>);

        return {
          ...stats[0], // grab repeated fields from the first element
          ...makeSummedIpAddressTotals(stats),
          ...nullPluckedFields,
          blacklistings: blackListings.blacklistings,
        };
      });
  }
}

function makeSummedIpAddressTotals(stats: (HasTotals | undefined)[]): StatisticsTotals {
  const hasStatsGuard = (stats: Array<HasTotals | undefined>): stats is Array<HasTotals> => {
    return stats.some(stat => !!stat);
  };

  const hasStats = hasStatsGuard(stats);

  return {
    total_incidents: sumBy(stats, 'total_incidents') ?? 0,
    total_compliant: hasStats
      ? sumBy(stats, totalCompliant)
      : 0,
    total_passing: hasStats
      ? sumBy(stats, totalPassing)
      : 0,
    total_complete_passes: sumBy(stats, 'total_complete_passes') ?? 0,
    total_spf_fails_dkim_passes: sumBy(stats, 'total_spf_fails_dkim_passes') ?? 0,
    total_dkim_fails_spf_passes: sumBy(stats, 'total_dkim_fails_spf_passes') ?? 0,
    total_forwards: sumBy(stats, 'total_forwards') ?? 0,
    total_complete_failures: sumBy(stats, 'total_complete_failures') ?? 0,
    total_failing: hasStats
      ? sumBy(stats, totalFailing)
      : 0,
    total_permitted: sumBy(stats, 'total_overrides_none') ?? 0,
    total_blocked: hasStats
      ? sumBy(stats, totalBlocked)
      : 0,
    total_delivered: hasStats
      ? sumBy(stats, totalDelivered)
      : 0,
    total_not_delivered: hasStats
      ? sumBy(stats, totalNotDelivered)
      : 0,
    total_blacklisted_incidents: sumBy(stats, 'total_blacklisted_incidents') ?? 0,
    total_clean_incidents: hasStats
      ? sumBy(stats, totalCleanIncidents)
      : 0,
    total_isps: hasStats
      ? sumBy(stats, 'total_isps')
      : 0,
    total_sources: hasStats
      ? sumBy(stats, 'total_sources')
      : 0,
    total_threat_sources: hasStats
      ? sumBy(stats, 'total_threat_sources')
      : 0,
    total_blacklisted_sources: hasStats
      ? sumBy(stats, 'total_blacklisted_sources')
      : 0,
  };
}

const mergeBlacklistings = (
  group: HasBlacklistings,
  statistics: SenderIpAddressStatisticsResource,
): HasBlacklistings['blacklistings'] => {
  // Merge together the blacklistings from each host of the group's blacklistings
  // and the current statistics' blacklistings
  return Object.entries(statistics.blacklistings)
    .reduce(
      (blacklistings, [listHost, listEntries]) => {
        if (!Object.prototype.hasOwnProperty.call(blacklistings, listHost)) {
          blacklistings[listHost] = [];
        }

        blacklistings[listHost] = [...new Set([...blacklistings[listHost], ...listEntries])];

        return blacklistings;
      }, group.blacklistings);
};
