<script setup lang="ts">
import { router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { ref } from 'vue';
import type { DnsDelegationVerificationResource } from '@/types/types.gen';
import { useRoute } from 'ziggy-js';
// @ts-expect-error Genuinely cannot get TS to recognise the declaration in global.d.ts
import toasteventbus from 'primevue/toasteventbus';

const { verification, customer_uuid, domain_uuid } = defineProps<{
  verification: DnsDelegationVerificationResource;
  domain_uuid: string;
  customer_uuid: string;
}>();

const verificationInProgress = ref(false);
const { translate } = useTranslate();

const showError = () => {
  toasteventbus.emit('add', {
    severity: 'error',
    summary: translate('delegation-verifications.verify.error'),
    life: 5000,
  });
  verificationInProgress.value = false;
}

const verify = () => {
  verificationInProgress.value = true;
  router.get(
    useRoute()('ui.delegation-verifications.verify', [customer_uuid, domain_uuid, verification.uuid]),
    {},
    {
      onSuccess: (data) => {
        if (['pass', 'partial'].includes(data.props.verification?.result ?? '')) {
          toasteventbus.emit('add', {
            severity: 'success',
            summary: translate('delegation-verifications.verify.success'),
            life: 5000,
          });
          return;
        }

        showError();

        verificationInProgress.value = false;
      },
      onError: showError,
      preserveScroll: true,
    },
  );
}
</script>

<template>
  <Button
    @click="verify"
    :disabled="verificationInProgress"
    :label="$t('configurations.actions.verify')"
    severity="secondary"
  />
</template>

<style scoped>

</style>
