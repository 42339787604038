<script setup lang="ts">
import { DnsDelegationVerificationResult, type DomainResource, type FailureReportResource } from '@/types/types.gen';
import ReportsTable from '@/Pages/Statistics/FailureReports/Partials/ReportsTable.vue';
import Drawer from '@/Pages/Statistics/FailureReports/Partials/Drawer.vue';
import WarningCard from '@/Components/WarningCard.vue';
import Button from 'primevue/button';
import { Link, router } from '@inertiajs/vue3';
import Select from 'primevue/select';
import { useRoute } from 'ziggy-js';
import { ref } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';

const { domain } = defineProps<{
  reports: FailureReportResource[];
  report?: FailureReportResource;
  domains: DomainResource[];
  domain: DomainResource;
}>();

const changeDomain = (domainUuid: string) => {
  router.get(useRoute()('ui.failure-reports.index', [
    domain.customer_uuid,
    domainUuid,
  ]));
}

const activeDomain = ref(domain.uuid);

const isVerified = [
  DnsDelegationVerificationResult.PASS,
  DnsDelegationVerificationResult.PARTIAL,
].includes(domain.dmarc_verification_result as DnsDelegationVerificationResult);

</script>

<template>
  <AppLayout :title="$t('failure_reports.title')">

    <div class="text-2xl font-semibold mb-8"
      v-text="$t('failure_reports.title', { domain: domain.domain_name })"
    />
    <div v-if="!isVerified">
      <Select
        filter
        v-model="activeDomain"
        optionValue="uuid"
        optionLabel="domain_name"
        :options="domains ?? []"
        @update:modelValue="changeDomain($event)"
      />

      <WarningCard
        :title="$t('reports.cards.domain_not_verified.header')"
        :description="$t('reports.cards.domain_not_verified.description')"
        class="w-full mt-8">
        <Link class="mt-2" :href="route('ui.dmarc-settings.edit', [domain.customer_uuid, domain.uuid])">
          <Button
            :label="$t('reports.cards.domain_not_verified.actions.configure')"
            size="small"
          />
        </Link>
      </WarningCard>
    </div>

    <ReportsTable
      v-else
      :reports="reports"
      :domains="domains"
      :domain="domain"
    />

    <Drawer
      v-if="report"
      :domain="domain"
      :report="report"
    />
  </AppLayout>
</template>
